import {
  FETCH_PATIENTS,
  FETCH_PATIENTS_RES,
  LIMIT,
  PAGE,
  SEARCH_PARAMS,
  SORT_FIELD,
  ORDER,
  ACTIVE_STATUS,
  STATUS_RESPONSE,
  NOTES,
  NOTES_RESPONSE,
  ACCOUNTS_EXPORT,
  ACCOUNTS_EXPORT_RES
} from './accountsTable.types'

export const fetchPatients = (params) => ({
  type: FETCH_PATIENTS,
  payload: params,
})

export const fetchPatientsResponse = (res) => ({
  type: FETCH_PATIENTS_RES,
  payload: res,
})
export const setLimit = (number) => ({
  type: LIMIT,
  payload: number,
})
export const setPage = (number) => ({
  type: PAGE,
  payload: number,
})
export const setSearchParams = (params) => ({
  type: SEARCH_PARAMS,
  payload: params,
})
export const setSortField = (field) => ({
  type: SORT_FIELD,
  payload: field,
})
export const setOrder = (order) => ({
  type: ORDER,
  payload: order,
})
export const changeStatus = (status) => ({
  type: ACTIVE_STATUS,
  payload: status,
})

export const statusResponse = (res) => ({
  type: STATUS_RESPONSE,
  payload: res,
})
export const getNotes = (id) => ({
  type: NOTES,
  payload: id,
})
export const getNotesResponse = (res) => ({
  type: NOTES_RESPONSE,
  payload: res,
})
export const accountsExport = (data) => ({
  type: ACCOUNTS_EXPORT,
  payload: data
})
export const accountsExportRes = (res) => ({
  type: ACCOUNTS_EXPORT_RES,
  payload: res
})
