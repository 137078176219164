import { GET_ALL_REVIEWS_RES, GET_REVIEW_BY_ID_RES } from './reviews.types'

const INITIAL_STATE = {
  allReviews: {},
  reviewById: {},
}

const reviewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWS_RES: {
      return { ...state, ...{ allReviews: action.payload } }
    }
    case GET_REVIEW_BY_ID_RES: {
      return { ...state, ...{ reviewById: action.payload } }
    }
    default:
      return state
  }
}
export default reviewsReducer
