import {
  FETCH_PATIENTS_RES,
  LIMIT,
  PAGE,
  SEARCH_PARAMS,
  SORT_FIELD,
  ORDER,
  NOTES_RESPONSE,
  ACCOUNTS_EXPORT_RES
} from './accountsTable.types'

const INITIAL_STATE = {
  PatientList: [],
  LIMIT: 18,
  PAGE: 0,
  ORDER: '',
  SORTFIELD: '',
  SearchData: {
    firstName: '',
    lastName: '',
    mrn: '',
    dob: '',
    phone: '',
    facilityId: '',
    payorId: '',
    providerId: '',
    status: ''
  },
  notesList: null,
  accountsExport: null
}

const accountsTableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PATIENTS_RES: {
      return { ...state, ...{ PatientList: action.payload } }
    }
    case PAGE: {
      return { ...state, ...{ PAGE: action.payload } }
    }
    case LIMIT: {
      return { ...state, ...{ LIMIT: action.payload } }
    }
    case SORT_FIELD: {
      return { ...state, ...{ SORTFIELD: action.payload } }
    }
    case ORDER: {
      return { ...state, ...{ ORDER: action.payload } }
    }
    case SEARCH_PARAMS: {
      return { ...state, ...{ SearchData: action.payload } }
    }
    case NOTES_RESPONSE: {
      return { ...state, ...{ notesList: action.payload } }
    }
    case ACCOUNTS_EXPORT_RES: {
      return { ...state, ...{ accountsExport: action.payload } }
    }
    default:
      return state
  }
}
export default accountsTableReducer
