/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const ADD_ICD = 'ADD_ICD'
export const ADD_ICD_RESPONSE = 'ADD_ICD_RESPONSE'
export const ICD_SEARCH_BY_CODE = 'ICD_SEARCH_BY_CODE'
export const ICD_SEARCH_BY_CODE_RESPONSE = 'ICD_SEARCH_BY_CODE_RESPONSE'
export const SEARCH_CPT_CODE = 'SEARCH_CPT_CODE'
export const SEARCH_CPT_CODE_RESPONSE = 'SEARCH_CPT_CODE_RESPONSE'
export const SAVE_CPT_CODE = 'SAVE_CPT_CODE'
export const SAVE_CPT_CODE_RESPONSE = 'SAVE_CPT_CODE_RESPONSE'
export const CHECKOUT_API = 'CHECKOUT_API'
export const CHECKOUT_API_RESPONSE = 'CHECKOUT_API_RESPONSE'
export const GET_CPT_DETAILS = 'GET_CPT_DETAILS'
export const GET_CPT_DETAILS_RESPONSE = 'GET_CPT_DETAILS_RESPONSE'
export const GET_CPT_DETAILS_POPUP = 'GET_CPT_DETAILS_POPUP'
export const GET_CPT_DETAILS_POPUP_RESPONSE = 'GET_CPT_DETAILS_POPUP_RESPONSE'
export const ICD_SEARCH_BY_KEYWORD = 'ICD_SEARCHBY_KEYWORD'
export const ICD_SEARCH_BY_KEYWORD_RESPONSE = 'ICD_SEARCH_BY_KEYWORD_RESPONSE'
export const ICD_SEARCH_DATA = 'ICD_SEARCH_DATA'
export const ICD_SEARCH_CLEAR = 'ICD_SEARCH_CLEAR'
export const GET_CHECKOUT='GET_CHECKOUT'
export const GET_CHECKOUT_RES='GET_CHECKOUT_RES'
