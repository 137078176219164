import {
  LOGIN,
  LOGIN_RESPONSE,
  API_STATUS,
  USER_DATA,
  USER_DATA_RES,
  LOGOUT,
  LOGOUT_RES,
} from './login.types'

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clickLogin-Action w.r.t LOGIN(type) in the redux
 * @description - Action used to call the login API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const clickLogin = (logData) => ({
  type: LOGIN,
  payload: logData,
})
/**
 * loginResponse - Action w.r.t LOGIN_RESPONSE(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const loginResponse = (user) => ({
  type: LOGIN_RESPONSE,
  payload: user,
})

/**
 * apiStatus - Action w.r.t API_STATUS(type) in the redux
 * @description Action used to changes the status of redux variable status based on login response
 * @param value
 * @returns {{payload: *, type: string}}
 */

export const apiStatus = (value) => ({
  type: API_STATUS,
  payload: value,
})

/**
 * clickLogin-Action w.r.t USER_DATA(type) in the redux
 * @description - Action used to call the video patient queue
 * @param data
 * @returns {{payload: *, type: string}}
 */

export const getuserData = (data) => ({
  type: USER_DATA,
  payload: data,
})
/**
 * loginResponse - Action w.r.t LOGIN_RESPONSE(type) in the redux
 * @description - Action used to get the response of getuserData API
 * @param res
 * @returns {{payload: *, type: string}}
 */
export const userDataRes = (res) => ({
  type: USER_DATA_RES,
  payload: res,
})
/**
 * clickLogin-Action w.r.t LOGOUT(type) in the redux
 * @description - Action used to call the logout API
 * @param data
 * @returns {{payload: *, type: string}}
 */
export const logoutApi = (token) => ({
  type: LOGOUT,
  payload: token,
})
/**
 * loginResponse - Action w.r.t LOGIN_RESPONSE(type) in the redux
 * @description - Action used to get the response of logout API
 * @param res
 * @returns {{payload: *, type: string}}
 */
export const logoutApiResponse = (token) => ({
  type: LOGOUT_RES,
  payload: token,
})
