import {
  CLINIC_DETAILS,
  CLINIC_DETAILS_RES,
  PROVIDERS_LIST,
  PROVIDERS_LIST_RES,
  FACILITIES_LIST,
  FACILITIES_LIST_RES,
  SERVICES_LIST,
  SERVICES_LIST_RES,
  PRODUCTION_TREND,
  PRODUCTION_TREND_RES,
  COLLECTION_TREND,
  COLLECTION_TREND_RES,
  FORECAST,
  FORECAST_RES,
  NOT_FILED_CLAIMS,
  NOT_FILED_CLAIMS_RES,
  RECEIVABLE_NET,
  RECEIVABLE_NET_RES,
  RECEIVABLE_GROSS,
  RECEIVABLE_GROSS_RES,
} from "./Dashboard.types";

export const getClinicDetails = (data) => ({
  type: CLINIC_DETAILS,
  payload: data,
});

export const getClinicDetailsRes = (data) => ({
  type: CLINIC_DETAILS_RES,
  payload: data,
});

export const getProvidersList = (data) => ({
  type: PROVIDERS_LIST,
  payload: data,
});

export const getProvidersListRes = (data) => ({
  type: PROVIDERS_LIST_RES,
  payload: data,
});

export const getFacilitiesList = (data) => ({
  type: FACILITIES_LIST,
  payload: data,
});

export const getFacilitiesListRes = (data) => ({
  type: FACILITIES_LIST_RES,
  payload: data,
});

export const getServicesList = (data) => ({
  type: SERVICES_LIST,
  payload: data,
});

export const getServicesListRes = (data) => ({
  type: SERVICES_LIST_RES,
  payload: data,
});

export const getProductionTrend = (data) => ({
  type: PRODUCTION_TREND,
  payload: data,
});

export const getProductionTrendRes = (data) => ({
  type: PRODUCTION_TREND_RES,
  payload: data,
});

export const getCollectionTrend = (data) => ({
  type: COLLECTION_TREND,
  payload: data,
});

export const getCollectionTrendRes = (data) => ({
  type: COLLECTION_TREND_RES,
  payload: data,
});

export const getForecast = (data) => ({
  type: FORECAST,
  payload: data,
});

export const getForecastRes = (data) => ({
  type: FORECAST_RES,
  payload: data,
});

export const getNotFiledClaims = (data) => ({
  type: NOT_FILED_CLAIMS,
  payload: data,
});

export const getNotFiledClaimsRes = (data) => ({
  type: NOT_FILED_CLAIMS_RES,
  payload: data,
});

export const getReceivableNet = (data) => ({
  type: RECEIVABLE_NET,
  payload: data,
});

export const getReceivableNetRes = (data) => ({
  type: RECEIVABLE_NET_RES,
  payload: data,
});

export const getReceivableGross = (data) => ({
  type: RECEIVABLE_GROSS,
  payload: data,
});

export const getReceivableGrossRes = (data) => ({
  type: RECEIVABLE_GROSS_RES,
  payload: data,
});



