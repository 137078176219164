import { ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as utlAjax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import { LOGIN, USER_DATA, LOGOUT } from './login.types'
import { loginResponse, userDataRes, logoutApiResponse } from './login.actions'
const { baseUrl } = environment
const loginEpic = {}

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 params.pipe(
 ofType(TYPE_NAME),
 switchMap((action) =>
 ajax({
          url: 'API url',
          method: 'POST/GET',
          body: passing payload from action.js,
        }).pipe(
 map(
 (response) => Response action(response),
 takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
 ),
 ),
 ),
 )
 })
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
loginEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/${Apis.Login_login}`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => loginResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * Login
 * @description calling this api to fetch patient queue
 * @param action$
 */
loginEpic.userData = (action$) =>
  action$.pipe(
    ofType(USER_DATA),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Login_userData}/${action.payload.id}`,
        method: 'GET',
        body: {},
      }).pipe(
        map(
          (response) => userDataRes(response),
          //  takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.logoutApi = (action$) =>
  action$.pipe(
    ofType(LOGOUT),
    switchMap((action) =>
      utlAjax({
        headers: {
          'application-id': 2,
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/${Apis.Login_logoutApi}?refreshToken=${action.payload}`,
        method: 'POST',
        body: {},
      }).pipe(map((response) => logoutApiResponse(response))),
    ),
  )
export default loginEpic
