import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 params.pipe(
 ofType(TYPE_NAME),
 switchMap((action) =>
 ajax({
          url: 'API url',
          method: 'POST/GET',
          body: passing payload from action.js,
        }).pipe(
 map(
 (response) => Response action(response),
 takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
 ),
 ),
 ),
 )
 })
 */
import {
  SAVE_PAYER_POLICY,
  REQUEST_CANCEL,
  EDIT_PATIENT,
  CREATE_INSURANCE_ADDRESS,
  SAVE_PAYER_DETAILS,
  SAVE_POLICY_HOLDER_DETAILS,
  GET_POLICY_HOLDER_DETAILS,
  SEARCH_PAYER,
  SEARCH_PAYER_ADDRESS,
  CHECKIN,
  GET_INSURANCE_BY_VISITTYPE,
  GET_INSURANCE_ACTIVE,
  GET_APPOINTMENT_LIST,
  CANCEL_APPOINTMENT_LIST,
} from './insurance.types'

import {
  savePayerPolicyRes,
  editCheckinPatientResponse,
  createInsuranceAddressRes,
  savePayerDetailsResponse,
  savePolicyHolderDetailsResponse,
  getPolicyHolderDetailsResponse,
  searchPayerResponse,
  searchPayerAddressResponse,
  checkinResponse,
  getInsuranceByVisitTypeResponse,
  getInsuranceActiveResponse,
  getAppointmentListRes,
  cancelAppointmentListRes,
} from './insurance.actions'
const { baseUrl } = environment
const insuranceEpic = {}

/**
 * @description For calling the save insurance api
 * @param action$ - params of SAVE_PAYER_POLICY type from action.js, {action$} - params from action.js
 */
insuranceEpic.savePayerPolicy = (action$) =>
  action$.pipe(
    ofType(SAVE_PAYER_POLICY),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_savePayerPolicy}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePayerPolicyRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * @description For calling the create insurance address APi
 * @param action$ - params of CREATE_INSURANCE_ADDRESS type from action.js, {action$} - params from action.js
 */
insuranceEpic.createInsuranceAddress = (action$) =>
  action$.pipe(
    ofType(CREATE_INSURANCE_ADDRESS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_createInsuranceAddress}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => createInsuranceAddressRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * @description For calling the edit patient APi
 * @param action$ - params of EDIT_PATIENT type from action.js, {action$} - params from action.js
 */
insuranceEpic.editPatient = (action$) =>
  action$.pipe(
    ofType(EDIT_PATIENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_editPatient}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => editCheckinPatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * @description For calling the save policy holder  APi
 * @param action$ - params of SAVE_PAYER_DETAILS type from action.js, {action$} - params from action.js
 */
insuranceEpic.savePayerDetails = (action$) =>
  action$.pipe(
    ofType(SAVE_PAYER_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_savePayerDetails}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePayerDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling the save policy holders APi
 * @param action$ - params of SAVE_POLICY_HOLDER_DETAILS type from action.js, {action$} - params from action.js
 */
insuranceEpic.savePolicyHolderDetails = (action$) =>
  action$.pipe(
    ofType(SAVE_POLICY_HOLDER_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_savePolicyHolderDetails}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePolicyHolderDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling the get policy holder details APi
 * @param action$ - params of CREATE_INSURANCE_ADDRESS type from action.js, {action$} - params from action.js
 */
insuranceEpic.getPolicyHolderDetails = (action$) =>
  action$.pipe(
    ofType(GET_POLICY_HOLDER_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_getPolicyHolderDetails}/${action.payload}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getPolicyHolderDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling the search payer APi
 * @param action$ - params of CREATE_INSURANCE_ADDRESS type from action.js, {action$} - params from action.js
 */
insuranceEpic.searchPayer = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYER),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_searchPayer}?name=${action.payload.s}&clinicId=${action.payload.cid}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => searchPayerResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling tsearch payer address APi
 * @param action$ - params of CREATE_INSURANCE_ADDRESS type from action.js, {action$} - params from action.js
 */
insuranceEpic.searchPayerAddress = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYER_ADDRESS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_searchPayerAddress}?clinicId=${action.payload.clinicId}&payerId=${action.payload.payerId}`,
        method: 'GET',
        //  body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => searchPayerAddressResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling the check in APi
 * @param action$ - params of CHECKIN type from action.js, {action$} - params from action.js
 */
insuranceEpic.checkinapi = (action$) =>
  action$.pipe(
    ofType(CHECKIN),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_checkinapi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => checkinResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling the get insurance by visit type APi
 * @param action$ - params of GET_INSURANCE_BY_VISITTYPE type from action.js, {action$} - params from action.js
 */
insuranceEpic.getInsuranceByVisitType = (action$) =>
  action$.pipe(
    ofType(GET_INSURANCE_BY_VISITTYPE),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_getInsuranceByVisitType}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getInsuranceByVisitTypeResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * @description For calling the deactivating APi for insurance
 * @param action$ - params of GET_INSURANCE_ACTIVE type from action.js, {action$} - params from action.js
 */
insuranceEpic.getInsuranceActive = (action$) =>
  action$.pipe(
    ofType(GET_INSURANCE_ACTIVE),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_getInsuranceActive}/${action.payload.id}?active=${action.payload.status}&id=${action.payload.id}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => getInsuranceActiveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

insuranceEpic.getAppoinmentList = (action$) =>
  action$.pipe(
    ofType(GET_APPOINTMENT_LIST),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_getAppoinmentList}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getAppointmentListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
insuranceEpic.cancelAppoinmentList = (action$) =>
  action$.pipe(
    ofType(CANCEL_APPOINTMENT_LIST),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Insurance_cancelAppoinmentList}/${action.payload.id}?appointmentReminderStatus=${action.payload.appointmentReminderStatus}&appointmentStatus=${action.payload.appointmentStatus}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => cancelAppointmentListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default insuranceEpic
