import { combineEpics } from 'redux-observable'
import loginEpic from './Login/login.epic'
import CalenderEpic from './Calender/calender.epic'
import insuranceEpic from './Insurance/insurance.epic'
import VideoEpic from './Video/video.epic'
import checkoutEpic from './Checkout/checkout.epic'
import paymentEpic from './Payment/payment.epic'
import AccountsEpic from './Accounts/accounts.epic'
import AccountsTableEpic from './AccountsTable/accountsTable.epic'
import ReportsEpic from './Reports/reports.epic'
import SettingsEpic from './Settings/settings.epic'
import chatEpic from './Chat/chat.epic'
import ReviewsEpic from './Reviews/reviews.epic'
import AppointmentsEpic from './Appointments/appointments.epic'
import adminEpic from './Admin/admin.epic'
import PriorAuthEpic from './PriorAuth/PriorAuthApis/prior.epic'
import AppointmentEpic from './PriorAuth/Appointment/apt.epic'
import DashboardEpic from './ProductionDetailed/Dashboard/Dashboard.epic'
import ProductionEpic from './ProductionDetailed/Production/Production.epic'
import CollectionEpic from './ProductionDetailed/Collection/Collection.epic'
import BankReconEpic from './ProductionDetailed/BankRecon/BankRecon.epic'
import NewchatEpic from './NewChat/chat.epic'
/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */

const rootEpic = combineEpics(
  loginEpic.login,
  loginEpic.userData,
  loginEpic.logoutApi,
  CalenderEpic.createAppointment,
  CalenderEpic.getCalenderList,
  CalenderEpic.searchPatient,
  CalenderEpic.getPatientUsingIdDialog,
  CalenderEpic.getPatientUsingId,
  CalenderEpic.getPatientUsingAptId,
  CalenderEpic.getApointmentinfoInsurance,
  CalenderEpic.updateAptDetails,
  CalenderEpic.getSearchPatientUsingId,
  CalenderEpic.getAddressFromZipCode,
  CalenderEpic.getAddressFromPayerAddZipCode,
  CalenderEpic.getAddressFromAccordianAddZipCode,
  insuranceEpic.savePayerPolicy,
  CalenderEpic.savePolicyHolderZipCode,
  CalenderEpic.dragAppointment,
  CalenderEpic.cancelAppoinmentApi,
  CalenderEpic.triggerApptReminderReview,
  insuranceEpic.editPatient,
  insuranceEpic.createInsuranceAddress,
  insuranceEpic.savePayerDetails,
  insuranceEpic.savePolicyHolderDetails,
  insuranceEpic.searchPayer,
  insuranceEpic.searchPayerAddress,
  insuranceEpic.getInsuranceByVisitType,
  insuranceEpic.getInsuranceActive,
  insuranceEpic.checkinapi,
  insuranceEpic.getPolicyHolderDetails,
  CalenderEpic.dragAppointmentData,
  CalenderEpic.deleteAppointment,
  checkoutEpic.createIcd,
  checkoutEpic.searchIcdbyCode,
  checkoutEpic.searchCptCode,
  checkoutEpic.saveCptCode,
  checkoutEpic.checkoutApi,
  checkoutEpic.getCptDetails,
  checkoutEpic.searchIcdByKeywordApi,
  checkoutEpic.getCheckoutApi,
  paymentEpic.savePaymentApi,
  paymentEpic.editPaymentApi,
  paymentEpic.getPaymentByApptIdApi,
  paymentEpic.getPaymentByIdApi,
  paymentEpic.deletePaymentApi,
  AccountsTableEpic.getPatienTablelist,
  AccountsTableEpic.changestatus,
  AccountsTableEpic.getNotesApi,
  AccountsTableEpic.accountsExport,
  AccountsEpic.getAddressFromZipCode,
  AccountsEpic.getPatientByIdForTab,
  AccountsEpic.editAndSavePatient,
  AccountsEpic.getAccountsData,
  AccountsEpic.getClaimlistApi,
  AccountsEpic.claimDownloadApi,
  AccountsEpic.getPaymentListApi,
  AccountsEpic.savePaymentApi,
  AccountsEpic.writeOffApi,
  AccountsEpic.balanceListApi,
  AccountsEpic.statementListApi,
  AccountsEpic.getStatementsDetailsApi,
  AccountsEpic.authorizationApi,
  AccountsEpic.paymentDeleteApi,
  AccountsEpic.searchPayer,
  AccountsEpic.searchPayerAddress,
  AccountsEpic.getPolicyByPatientId,
  AccountsEpic.getPolicyHolderInsurnaceTab,
  AccountsEpic.editAndSavePatientInsurnace,
  AccountsEpic.changeStatusOfInsurnace,
  AccountsEpic.stripePaymentApi,
  AccountsEpic.getPatientPinApi,
  AccountsEpic.getClaimsNotesApi,
  ReportsEpic.getEndOfDayReport,
  ReportsEpic.getAppointmentListReport,
  ReportsEpic.getCollectionReport,
  ReportsEpic.getAllUsers,
  ReportsEpic.priorReport,
  AccountsEpic.getPatientNotesAccounts,
  AccountsEpic.savePatientNotesAccounts,
  AccountsEpic.saveNotesAppointment,
  AccountsEpic.getNotesAppointment,
  SettingsEpic.getSettingsApi,
  SettingsEpic.saveSettingsApi,
  insuranceEpic.getAppoinmentList,
  insuranceEpic.cancelAppoinmentList,
  AccountsEpic.getAppoinmentList,
  AccountsEpic.cancelAppoinmentList,

  VideoEpic.patientlistApi,
  VideoEpic.getClinicVideoTokenApi,
  VideoEpic.deletePatientQueue,
  VideoEpic.videoMailApi,
  VideoEpic.patientTileApi,

  chatEpic.fetchInboxHistoryApi,
  chatEpic.fetchMessagesApi,
  chatEpic.sendFileInchat,
  chatEpic.downloadFileInchat,

  ReviewsEpic.getAllReviews,
  ReviewsEpic.getReviewById,

  AppointmentsEpic.getAppointments,
  AppointmentsEpic.getAppointmentIns,
  AppointmentsEpic.updateAppointment,
  AppointmentsEpic.uploadAppointment,
  AppointmentsEpic.getUploadStatus,
  AppointmentsEpic.exportAppointment,
  AppointmentsEpic.getBalanceDetailsApi,

  adminEpic.getSpecialityApi,
  adminEpic.getClinicSettingsApi,
  adminEpic.saveClinicApi,
  adminEpic.saveAdminApi,
  adminEpic.getAllUsersApi,
  adminEpic.updateUserStausApi,
  adminEpic.userNameValidApi,
  adminEpic.saveUserApi,
  adminEpic.clinicZipApi,
  adminEpic.saveProviderApi,
  adminEpic.updateProviderStausApi,
  adminEpic.getAllProvidersApi,
  adminEpic.providerUserNameValidateApi,
  adminEpic.getProviderByIdApi,
  adminEpic.getAllLocation,
  adminEpic.saveLocation,
  adminEpic.updateLocationStatus,
  adminEpic.zipCodeForLocation,
  adminEpic.saveAppointmentType,
  adminEpic.GetAppointmentType,
  adminEpic.updateAppointmentStaus,
  adminEpic.getMessageConfigsByClinicId,
  adminEpic.getMessageConfigById,
  adminEpic.getMessageConfigDefaultsByType,
  adminEpic.saveMessageConfig,
  adminEpic.updateMessageConfig,
  adminEpic.getMessageResponse,
  adminEpic.getMessageResponseById,
  adminEpic.getMessageResponseDefaults,
  adminEpic.saveMessageResponse,
  adminEpic.updateMessageResponse,
  adminEpic.getMessageSchedule,
  adminEpic.getMessageScheduleByClinicId,
  adminEpic.getMessageScheduleDefaults,
  adminEpic.saveMessageSchedule,
  adminEpic.updateMessageSchedule,
  adminEpic.updateMessageConfigStatus,
  adminEpic.updateMessageConfigChannelEnabled,
  adminEpic.updateUserApi,
  adminEpic.getMessageTypes,
  adminEpic.getRescheduleSettings,
  adminEpic.saveRescheduleSettings,
  adminEpic.getProviderLocation,
  adminEpic.saveProviderLocation,
  adminEpic.getProviderLocationById,
  adminEpic.getPlansDetails,
  adminEpic.getBillingDetails,
  adminEpic.makePayment,
  adminEpic.updateCard,
  adminEpic.getPlaceId,
  adminEpic.savePlaceId,
  adminEpic.getCheckinConfig,
  adminEpic.saveCheckinConfig,
  adminEpic.getPayorsList,
  adminEpic.eftUpdatedStatus,
  adminEpic.saveEligibilityConfig,
  adminEpic.getEligibilityConfig,
  PriorAuthEpic.priorAuthListApi,
  PriorAuthEpic.getPriorAuthApi,
  PriorAuthEpic.icdSearchApi,
  PriorAuthEpic.cptSearchApi,
  PriorAuthEpic.logInfoSaveApi,
  PriorAuthEpic.icdSaveApi,
  PriorAuthEpic.cptSaveApi,
  PriorAuthEpic.priorAuthSaveApi,
  PriorAuthEpic.loginInfoApi,
  PriorAuthEpic.fileUploadApi,
  PriorAuthEpic.downloadFileApi,
  PriorAuthEpic.logListApi,
  PriorAuthEpic.bulkUpdateApi,
  PriorAuthEpic.rowUpdateApi,
  PriorAuthEpic.icdKeywordSearchApi,
  PriorAuthEpic.cptDeleteApi,
  PriorAuthEpic.icdChildApi,
  PriorAuthEpic.paHistoryApi,
  PriorAuthEpic.searchPayorApi,
  PriorAuthEpic.patientSaveApi,
  PriorAuthEpic.policyHolderSaveApi,
  PriorAuthEpic.policySaveApi,
  PriorAuthEpic.mappingListApi,
  PriorAuthEpic.saveMappingApi,
  PriorAuthEpic.getPatientApi,
  PriorAuthEpic.FilesListApi,
  PriorAuthEpic.getNotesListApi,
  PriorAuthEpic.providerInfoApi,
  PriorAuthEpic.getCommonListApi,
  AppointmentEpic.priorAuthSearchPatientApi,
  AppointmentEpic.AppointmentSaveApi,
  AppointmentEpic.payerSaveApi,
  AppointmentEpic.providerSaveApi,
  AppointmentEpic.providerListApi,
  AppointmentEpic.exportDownloadApi,

  DashboardEpic.getClinicDetails,
  DashboardEpic.getProvidersList,
  DashboardEpic.getFacilitiesList,
  DashboardEpic.getServicesList,
  DashboardEpic.getProductionTrend,
  DashboardEpic.getCollectionTrend,
  DashboardEpic.getForecast,
  DashboardEpic.getNotFiledClaims,
  DashboardEpic.getReceivableNet,
  DashboardEpic.getReceivableGross,

  ProductionEpic.getProductionDetailed,
  ProductionEpic.getClaimLevel,
  ProductionEpic.getProdNotes,
  ProductionEpic.exportDetails,
  ProductionEpic.downloadDetails,
  ProductionEpic.getExportStatus,
  ProductionEpic.updateApmtType,
  ProductionEpic.getProdEob,
  ProductionEpic.prodClaimDownloadApi,
  ProductionEpic.getProdGroupByFacility,
  ProductionEpic.getProdGroupByService,
  ProductionEpic.getProdGroupByProvider,
  ProductionEpic.payorsList,
  ProductionEpic.getDenialCategory,
  ProductionEpic.searchIcd,

  CollectionEpic.getColGroupByProvider,
  CollectionEpic.getColGroupByService,
  CollectionEpic.getColGroupByFacility,
  CollectionEpic.getCollectionDetailed,

  BankReconEpic.getPayments,
  BankReconEpic.updateStatus,
  BankReconEpic.updatePostedDate,
  BankReconEpic.exportPayments,
  BankReconEpic.downloadPayments,
  BankReconEpic.getExportStatus,
  BankReconEpic.updateDepositDate,

  NewchatEpic.login,
)

export default rootEpic
