export const SAVE_PAYER_POLICY = 'SAVE_PAYER_POLICY' // save the payer and policy details
export const SAVE_PAYER_POLICY_RES = 'SAVE_PAYER_POLICY_RES' // response for payer and policy details saving
export const REQUEST_CANCEL = 'REQUEST_CANCEL' // cancelling the api request
export const GET_INSURANCE_RES = 'GET_INSURANCE_RES' // response for get insurance
export const CREATE_INSURANCE_ADDRESS = 'CREATE_INSURANCE_ADDRESS' // creating the address for insurance company
export const CREATE_INSURANCE_ADDRESS_RES = 'CREATE_INSURANCE_ADDRESS_RES' // response for creating the address for insurance company
export const EDIT_PATIENT = 'EDIT_PATIENT' // editing patient
export const EDIT_PATIENT_RES = 'EDIT_PATIENT_RES' // response for edit patient
export const VISIBLE = 'VISIBLE' // used for the insurance table hide and show
export const PATIENT_UPDATE_STATUS = 'PATIENT_UPDATE_STATUS' // status for patient update
export const SAVE_PAYER_DETAILS = 'SAVE_PAYER_DETAILS' // saving payer details
export const SAVE_PAYER_DETAILS_RESPONSE = 'SAVE_PAYER_DETAILS_RESPONSE' // response for save payer details
export const SAVE_POLICY_HOLDER_DETAILS = 'SAVE_POLICY_HOLDER_DETAILS' // saving policy holder details
export const SAVE_POLICY_HOLDER_DETAILS_RESPONSE =
  'SAVE_POLICY_HOLDER_DETAILS_RESPONSE' // response for save policy holder
export const SEARCH_PAYER = 'SEARCH_PAYER' // searching payer
export const SEARCH_PAYER_RES = 'SEARCH_PAYER_RES' // response for search payer
export const SEARCH_PAYER_ADDRESS = 'SEARCH_PAYER_ADDRESS' // searching payer address
export const SEARCH_PAYER_ADDRESS_RES = 'SEARCH_PAYER_ADDRESS_RES' // response for searching payer address
export const CHECKIN = 'CHECKIN' // checking In
export const CHECKIN_RES = 'CHECKIN_RES' //
export const GET_INSURANCE_BY_VISITTYPE = 'GET_INSURANCE_BY_VISITTYPE' // getting insurance by visit type
export const GET_INSURANCE_BY_VISITTYPE_RES = 'GET_INSURANCE_BY_VISITTYPE_RES' // response for getting insurance by visit type
export const GET_INSURANCE_ACTIVE = 'GET_INSURANCE_ACTIVE' // changing the status of the insurance
export const GET_INSURANCE_ACTIVE_RES = 'GET_INSURANCE_ACTIVE_RES' // respons for changing the status of insurance
export const CANCEL_CLICK = 'CANCEL_CLICK' // for clicking the cancel button
export const GET_POLICY_HOLDER_DETAILS = 'GET_POLICY_HOLDER_DETAILS' // getting policy holder details
export const GET_POLICY_HOLDER_DETAILS_RESPONSE =
  'GET_POLICY_HOLDER_DETAILS_RESPONSE' // response for get policy holder details
export const SAVE_HOLDER_STATUS = 'SAVE_HOLDER_STATUS' // for saving policy holder status
export const POLICY_OBJECT = 'POLICY_OBJECT' // for passing policy object to policy array of table
export const COLLAPSE_INDEX = 'COLLAPSE_INDEX'
export const GET_POLICIES_FOR_CHECKIN = 'GET_POLICIES_FOR_CHECKIN'
export const GET_APPOINTMENT_LIST = 'GET_APPOINTMENT_LIST'
export const GET_APPOINTMENT_LIST_RES = 'GET_APPOINTMENT_LIST_RES'
export const CANCEL_APPOINTMENT_LIST = 'CANCEL_APPOINTMENT_LIST'
export const CANCEL_APPOINTMENT_LIST_RES = 'CANCEL_APPOINTMENT_LIST_RES'
