import {
  ADD_ICD,
  ADD_ICD_RESPONSE,
  ICD_SEARCH_BY_CODE,
  ICD_SEARCH_BY_CODE_RESPONSE,
  SEARCH_CPT_CODE,
  SEARCH_CPT_CODE_RESPONSE,
  SAVE_CPT_CODE,
  SAVE_CPT_CODE_RESPONSE,
  CHECKOUT_API,
  CHECKOUT_API_RESPONSE,
  GET_CPT_DETAILS_RESPONSE,
  GET_CPT_DETAILS,
  ICD_SEARCH_BY_KEYWORD,
  ICD_SEARCH_BY_KEYWORD_RESPONSE,
  ICD_SEARCH_DATA,
  ICD_SEARCH_CLEAR,
  GET_CHECKOUT,
  GET_CHECKOUT_RES
} from './checkout.types'

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * return(
    type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from calender.type.js,
  payload: object - contains params,
})
*/

export const AddIcdCode = (icdDetails) => ({
  type: ADD_ICD,
  payload: icdDetails,
})
export const AddIcdCodeResponse = (icdDetails) => ({
  type: ADD_ICD_RESPONSE,
  payload: icdDetails,
})
export const searchIcdByCode = (icdCode) => ({
  type: ICD_SEARCH_BY_CODE,
  payload: icdCode,
})
export const searchIcdByCodeResponse = (icdCodeData) => ({
  type: ICD_SEARCH_BY_CODE_RESPONSE,
  payload: icdCodeData,
})
export const SearchCptCode = (cptDetails) => ({
  type: SEARCH_CPT_CODE,
  payload: cptDetails,
})
export const SearchCptCodeResponse = (cptDetails) => ({
  type: SEARCH_CPT_CODE_RESPONSE,
  payload: cptDetails,
})
export const SaveCptCode = (cptDetails) => ({
  type: SAVE_CPT_CODE,
  payload: cptDetails,
})
export const SaveCptCodeResponse = (cptDetails) => ({
  type: SAVE_CPT_CODE_RESPONSE,
  payload: cptDetails,
})
export const saveCheckoutData = (checkoutDetails) => ({
  type: CHECKOUT_API,
  payload: checkoutDetails,
})
export const saveCheckoutDataResponse = (checkoutDetails) => ({
  type: CHECKOUT_API_RESPONSE,
  payload: checkoutDetails,
})
export const getCptData = (cptDetails) => ({
  type: GET_CPT_DETAILS,
  payload: cptDetails,
})
export const getCptDataResponse = (cptDetails) => ({
  type: GET_CPT_DETAILS_RESPONSE,
  payload: cptDetails,
})
export const searchIcdByKeyword = (searchData) => ({
  type: ICD_SEARCH_BY_KEYWORD,
  payload: searchData,
})
export const searchIcdByKeywordResponse = (searchData) => ({
  type: ICD_SEARCH_BY_KEYWORD_RESPONSE,
  payload: searchData,
})
export const icdSearchData = (searchData) => ({
  type: ICD_SEARCH_DATA,
  payload: searchData,
})
export const icdSearchClear = (searchData) => ({
  type: ICD_SEARCH_CLEAR,
  payload: searchData,
})

export const getCheckout = (param) => ({
  type: GET_CHECKOUT,
  payload: param,
})
export const getCheckoutResponse = (res) => ({
  type: GET_CHECKOUT_RES,
  payload: res,
})
