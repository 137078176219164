import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from 'Utils'
import { Apis } from 'Redux/APIs'
import environment from 'environments/environment'

import {
  REQUEST_CANCEL,
  PRODUCTION_DETAILED,
  CLAIM_LEVEL,
  PROD_NOTES,
  EXPORT_DETAILS,
  DOWNLOAD_DETAILS,
  GET_EXPORT_STATUS,
  UPDATE_APMT_TYPE,
  PROD_EOB,
  PROD_CLAIM_DOWNLOAD,
  PROD_GROUP_BY_PROVIDER,
  PROD_GROUP_BY_SERVICE,
  PROD_GROUP_BY_FACILITY,
  PAYORS_LIST,
  DENIAL_CATEGORY,
  SEARCH_ICD,
} from './Production.types'

import {
  getProductionDetailedRes,
  getClaimLevelRes,
  getProdNotesRes,
  exportDetailsRes,
  downloadDetailsRes,
  getExportStatusRes,
  updateApmtTypeRes,
  getProdEobRes,
  prodClaimDownloadRes,
  getProdGroupByProviderRes,
  getProdGroupByServiceRes,
  getProdGroupByFacilityRes,
  payorsListRes,
  getDenialDetailedRes,
  searchIcdResponse,
} from './Production.actions'

const { baseUrl, baseUrl2 } = environment
const ProductionEpic = {}

ProductionEpic.getProductionDetailed = (action$) =>
  action$.pipe(
    ofType(PRODUCTION_DETAILED),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/${Apis.Production_getProductionDetailed}/${action.payload.iclinicId}?offset=${action.payload.offset}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getProductionDetailedRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getClaimLevel = (action$) =>
  action$.pipe(
    ofType(CLAIM_LEVEL),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_getClaimLevel}/${action.payload.clinicId}?claimId=${action.payload.claimId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClaimLevelRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getProdNotes = (action$) =>
  action$.pipe(
    ofType(PROD_NOTES),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_getNotes}/${action.payload.clinicId}?claimId=${action.payload.claimId}&providerId=${action.payload.providerId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProdNotesRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.exportDetails = (action$) =>
  action$.pipe(
    ofType(EXPORT_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_exportDetails}/${action.payload.exportLevel}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => exportDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.downloadDetails = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_downloadDetails}?filePath=${action.payload}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => downloadDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getExportStatus = (action$) =>
  action$.pipe(
    ofType(GET_EXPORT_STATUS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_getExportStatus}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getExportStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.updateApmtType = (action$) =>
  action$.pipe(
    ofType(UPDATE_APMT_TYPE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_updateApmtType}/${action.payload.clinicId}?apptTypeId=${action.payload.apptTypeId}&claimId=${action.payload.claimId}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => updateApmtTypeRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
ProductionEpic.getProdEob = (action$) =>
  action$.pipe(
    ofType(PROD_EOB),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_getProdEob}/${action.payload.claimId}?patientId=${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProdEobRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
ProductionEpic.prodClaimDownloadApi = (action$) =>
  action$.pipe(
    ofType(PROD_CLAIM_DOWNLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        url: `${baseUrl}/${Apis.Production_prodClaimDownloadApi}?fileId=${action.payload.fileId}&fileName=${action.payload.fileName}&isInternal=false`,
        method: 'GET',
        responseType: "blob",
      }).pipe(
        map(
          (response) => prodClaimDownloadRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getProdGroupByFacility = (action$) =>
  action$.pipe(
    ofType(PROD_GROUP_BY_FACILITY),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/production/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProdGroupByFacilityRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getProdGroupByProvider = (action$) =>
  action$.pipe(
    ofType(PROD_GROUP_BY_PROVIDER),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/production/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProdGroupByProviderRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getProdGroupByService = (action$) =>
  action$.pipe(
    ofType(PROD_GROUP_BY_SERVICE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/production/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProdGroupByServiceRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.payorsList = (action$) =>
  action$.pipe(
    ofType(PAYORS_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getPayorsList}?clinicId=93422`,
        method: 'GET',
      }).pipe(
        map(
          (response) => payorsListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.getDenialCategory = (action$) =>
  action$.pipe(
    ofType(DENIAL_CATEGORY),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/${Apis.Production_getProductionDetailed}/${action.payload.iclinicId}?offset=${action.payload.offset}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getDenialDetailedRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ProductionEpic.searchIcd = (action$) =>
  action$.pipe(
    ofType(SEARCH_ICD),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/details/list/icdcpt/${action.payload.clinicId}?icdCode=${action.payload.icdCode}&cptCode=${action.payload.cptCode}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchIcdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default ProductionEpic
