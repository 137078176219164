import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import {
  ADD_ICD,
  REQUEST_CANCEL,
  SEARCH_CPT_CODE,
  SAVE_CPT_CODE,
  ICD_SEARCH_BY_CODE,
  CHECKOUT_API,
  GET_CPT_DETAILS,
  ICD_SEARCH_BY_KEYWORD,
  GET_CHECKOUT,
} from './checkout.types'
import {
  AddIcdCodeResponse,
  SearchCptCodeResponse,
  SaveCptCodeResponse,
  searchIcdByCodeResponse,
  saveCheckoutDataResponse,
  getCptDataResponse,
  searchIcdByKeywordResponse,
  getCheckoutResponse,
} from './checkout.actions'
const { baseUrl } = environment

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example Name of the epic = (params) =>
   params.pipe(
   ofType(TYPE_NAME),
   switchMap((action) =>
   ajax({
          url: 'API url',
          method: 'POST/GET',
          body: passing payload from action.js,
        }).pipe(
   map(
   (response) => Response action(response),
   takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
   ),
   ),
   ),
   )
  })
 */
const checkoutEpic = {}

/**
 * createIcd
 * @description - Calling create ICD API
 * @params {action} - params of  ADD_ICD type from action.js, {action$} - params from action.js
 */
checkoutEpic.createIcd = (action$) =>
  action$.pipe(
    ofType(ADD_ICD),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_createIcd}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => AddIcdCodeResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
checkoutEpic.searchIcdbyCode = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH_BY_CODE),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_searchIcdbyCode}/${action.payload.code}?clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchIcdByCodeResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
checkoutEpic.searchCptCode = (action$) =>
  action$.pipe(
    ofType(SEARCH_CPT_CODE),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_searchCptCode}/${action.payload.code}?clinicId=${action.payload.id}`,
        method: 'GET',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => SearchCptCodeResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
checkoutEpic.saveCptCode = (action$) =>
  action$.pipe(
    ofType(SAVE_CPT_CODE),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_saveCptCode}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => SaveCptCodeResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
checkoutEpic.checkoutApi = (action$) =>
  action$.pipe(
    ofType(CHECKOUT_API),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_checkoutApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveCheckoutDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
checkoutEpic.getCptDetails = (action$) =>
  action$.pipe(
    ofType(GET_CPT_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_getCptDetails}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCptDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
checkoutEpic.searchIcdByKeywordApi = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH_BY_KEYWORD),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_searchIcdByKeywordApi}?searchField=%20&searchParam=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchIcdByKeywordResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
  checkoutEpic.getCheckoutApi = (action$) =>
  action$.pipe(
    ofType(GET_CHECKOUT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Checkout_getCheckoutApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCheckoutResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default checkoutEpic
