/* eslint-disable max-lines */
import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { objectToUrl } from 'Utils/ajax-utils'
import { ajax as utlAjax } from 'Utils'
import {
  REQUEST_CANCEL,
  GET_CLINIC_DATA,
  SAVE_CLINIC_DATA,
  SAVE_ADMIN_DATA,
  GET_ALL_USER,
  UPDATE_USER_STATUS,
  CLINIC_ZIP_CALL,
  SAVE_USER,
  UPDATE_USER,
  USERNAME_VALIDATION,
  GET_ALL_PROVIDER,
  UPDATE_PROVIDER_STATUS,
  SAVE_PROVIDER,
  PROVIDER_USERNAME_VALIDATION,
  GET_PROVIDER_BY_ID,
  GET_ALL_LOCATION,
  SAVE_LOCATION,
  UPDATE_LOCATION_STATUS,
  LOCATION_ZIP_CALL,
  SAVE_APT_TYPE,
  GET_APT_TYPE,
  UPDATE_APPOINTKMENT_STATUS,
  GET_MESSAGE_CONFIGS,
  GET_MESSAGE_CONFIG_BY_ID,
  GET_MESSAGE_CONFIG_DEFAULTS,
  SAVE_MESSAGE_CONFIG,
  UPDATE_MESSAGE_CONFIG,
  GET_MESSAGE_RESPONSE,
  GET_MESSAGE_RESPONSE_BY_ID,
  GET_MESSAGE_RESPONSE_DEFAULTS,
  SAVE_MESSAGE_RESPONSE,
  UPDATE_MESSAGE_RESPONSE,
  GET_MESSAGE_SCHEDULE,
  GET_MESSAGE_SCHEDULE_DEFAULTS,
  SAVE_MESSAGE_SCHEDULE,
  UPDATE_MESSAGE_SCHEDULE,
  GET_MESSAGE_SCHEDULE_BY_CLINIC_ID,
  MESSAGE_CONFIG_STATUS,
  MESSAGE_CONFIG_CHANNEL_ENABLED,
  GET_MESSAGE_TYPES,
  GET_RESCHEDULE_SETTINGS,
  SAVE_RESCHEDULE_SETTINGS,
  GET_PROVIDER_LOCATION_LIST,
  SAVE_PROVIDER_LOCATION,
  GET_PROVIDER_LOCATION_BY_ID,
  SPECIALITY,
  CREATE_PAYMENT,
  GET_PLANS_DETAILS,
  GET_BILLING_DETAILS,
  UPDATE_CARD,
  GET_PLACE_ID,
  SAVE_PLACE_ID,
  GET_CHECKIN_CONFIG,
  SAVE_CHECKIN_CONFIG,
  GET_PAYORS_LIST,
  EFT_UPDATE_STATUS,
  GET_ELIGIBILITY_CONFIG,
  SAVE_ELIGILIBITY_CONFIG
} from './admin.types'
import {
  getClinicDataResponse,
  saveClinicDataResponse,
  saveAdminDataResponse,
  getAllUserRes,
  updateUserStatusRes,
  clinicZipCallRes,
  saveUserRes,
  userNameValidationRes,
  getAllProviderRes,
  updateProviderStatusRes,
  saveProviderRes,
  providerUserNameValidationRes,
  getProviderByIdRes,
  getAllLocationRes,
  saveLocationRes,
  updateLocationStatusRes,
  locationZipCallRes,
  SaveApointmentTypeRes,
  GetAppointmentTypeRes,
  updateAppointmentStatusRes,
  getMessageConfigsResponse,
  getMessageConfigByIdResponse,
  saveMessageConfigResponse,
  updateMessageConfigResponse,
  updateUserRes,
  getMessageRespResponse,
  getMessageRespByIdResponse,
  getMessageResponseDefaultsResponse,
  saveMessageRespResponse,
  updateMessageRespResponse,
  getMessageScheduleResponse,
  getMessageScheduleDefaultsResponse,
  saveMessageScheduleResponse,
  updateMessageScheduleResponse,
  getMessageScheduleByClinicIdResponse,
  updateMessageConfigStatusRes,
  updateMessageConfigChannelRes,
  getMessageTypesResponse,
  getRescheduleSettingsResponse,
  saveRescheduleSettingsResponse,
  getProviderLocationResponse,
  saveProviderLocationResponse,
  getProviderLocationByIdResponse,
  getMessageConfigDefaultsResponse,
  specialityResponse,
  makePaymentResponse,
  getPlansDetailsResponse,
  getBillingDetailsResponse,
  updateCardResponse,
  getPlaceIdResponse,
  savePlaceIdResponse,
  getCheckinConfigResponse,
  saveCheckinConfigResponse,
  getPayorsListRes,
  eftUpdatedStatusRes,
  getEligibilityConfigResponse,
  saveEligibilityConfigResponse
} from './admin.actions'

import { Apis } from 'Redux/APIs'
import environment from 'environments/environment'
const adminEpic = {}
const baseUrl = environment.baseUrl
const baseUrl2 = environment.baseUrl2;

adminEpic.getClinicSettingsApi = (action$) =>
  action$.pipe(
    ofType(GET_CLINIC_DATA),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_clinicInfoApi}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClinicDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.saveClinicApi = (action$) =>
  action$.pipe(
    ofType(SAVE_CLINIC_DATA),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveClinicApi}/${action.payload.clinicId}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveClinicDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveAdminApi = (action$) =>
  action$.pipe(
    ofType(SAVE_ADMIN_DATA),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveAdminApi}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveAdminDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.getAllUsersApi = (action$) =>
  action$.pipe(
    ofType(GET_ALL_USER),
    switchMap((action) =>
      utlAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Admin_getAllUsersApi}`,
          action.payload,
        ),
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAllUserRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.updateUserStausApi = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateUserStausApi}/${action.payload.userId}?isActive=${action.payload.status}`,
        method: 'DELETE',
      }).pipe(
        map(
          (response) => updateUserStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.clinicZipApi = (action$) =>
  action$.pipe(
    ofType(CLINIC_ZIP_CALL),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_zipCodeApi}?zipCode=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => clinicZipCallRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.saveUserApi = (action$) =>
  action$.pipe(
    ofType(SAVE_USER),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveUserApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveUserRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.updateUserApi = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateUserStausApi}/${action.payload.userId}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateUserRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.userNameValidApi = (action$) =>
  action$.pipe(
    ofType(USERNAME_VALIDATION),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_userNameValidateApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => userNameValidationRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getAllProvidersApi = (action$) =>
  action$.pipe(
    ofType(GET_ALL_PROVIDER),
    switchMap((action) =>
      utlAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Admin_providerListApi}`,
          action.payload,
        ),
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAllProviderRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getProviderByIdApi = (action$) =>
  action$.pipe(
    ofType(GET_PROVIDER_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_providerByIdApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProviderByIdRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateProviderStausApi = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROVIDER_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_providerStatusUpdateApi}${action.payload.userId}?status=${action.payload.status}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => updateProviderStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveProviderApi = (action$) =>
  action$.pipe(
    ofType(SAVE_PROVIDER),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveProviderApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveProviderRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.providerUserNameValidateApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_USERNAME_VALIDATION),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_userNameValidateApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => providerUserNameValidationRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getAllLocation = (action$) =>
  action$.pipe(
    ofType(GET_ALL_LOCATION),
    switchMap((action) =>
      utlAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Admin_locationListApi}`,
          action.payload,
        ),
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAllLocationRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveLocation = (action$) =>
  action$.pipe(
    ofType(SAVE_LOCATION),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveLocationApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveLocationRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateLocationStatus = (action$) =>
  action$.pipe(
    ofType(UPDATE_LOCATION_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateLocationStatusApi}/${action.payload.locationId}?locationId=${action.payload.locationId}&status=${action.payload.status}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => updateLocationStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.zipCodeForLocation = (action$) =>
  action$.pipe(
    ofType(LOCATION_ZIP_CALL),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_zipCodeApi}?zipCode=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => locationZipCallRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
adminEpic.saveAppointmentType = (action$) =>
  action$.pipe(
    ofType(SAVE_APT_TYPE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveAppointmenttypeApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => SaveApointmentTypeRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.GetAppointmentType = (action$) =>
  action$.pipe(
    ofType(GET_APT_TYPE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_AppointmenttypeListApi}?start=${action.payload.start}&limit=${action.payload.limit}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => GetAppointmentTypeRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateAppointmentStaus = (action$) =>
  action$.pipe(
    ofType(UPDATE_APPOINTKMENT_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateAppointmenttypeStausApi}/${action.payload.userId}?appointmentTypeId=${action.payload.userId}&status=${action.payload.status}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => updateAppointmentStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

// Messaging

adminEpic.getMessageConfigsByClinicId = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_CONFIGS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageConfigsByClinicId}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageConfigsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageConfigById = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_CONFIG_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageConfigById}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageConfigByIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageConfigDefaultsByType = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_CONFIG_DEFAULTS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageConfigDefaultsByType}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageConfigDefaultsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveMessageConfig = (action$) =>
  action$.pipe(
    ofType(SAVE_MESSAGE_CONFIG),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveMessageConfig}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMessageConfigResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateMessageConfig = (action$) =>
  action$.pipe(
    ofType(UPDATE_MESSAGE_CONFIG),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateMessageConfig}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateMessageConfigResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageResponse = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_RESPONSE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageResponse}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageRespResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageResponseById = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_RESPONSE_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageResponseById}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageRespByIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageResponseDefaults = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_RESPONSE_DEFAULTS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageResponseDefaults}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageResponseDefaultsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveMessageResponse = (action$) =>
  action$.pipe(
    ofType(SAVE_MESSAGE_RESPONSE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveMessageResponse}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMessageRespResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateMessageResponse = (action$) =>
  action$.pipe(
    ofType(UPDATE_MESSAGE_RESPONSE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateMessageResponse}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateMessageRespResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageSchedule = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_SCHEDULE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageSchedule}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageScheduleResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageScheduleDefaults = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_SCHEDULE_DEFAULTS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageScheduleDefaults}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageScheduleDefaultsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveMessageSchedule = (action$) =>
  action$.pipe(
    ofType(SAVE_MESSAGE_SCHEDULE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveMessageSchedule}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMessageScheduleResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateMessageSchedule = (action$) =>
  action$.pipe(
    ofType(UPDATE_MESSAGE_SCHEDULE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateMessageSchedule}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateMessageScheduleResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageScheduleByClinicId = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_SCHEDULE_BY_CLINIC_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageScheduleByClinicId}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageScheduleByClinicIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateMessageConfigStatus = (action$) =>
  action$.pipe(
    ofType(MESSAGE_CONFIG_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateMessageConfigStatus}/${action.payload.id}?status=${action.payload.status}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => updateMessageConfigStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateMessageConfigChannelEnabled = (action$) =>
  action$.pipe(
    ofType(MESSAGE_CONFIG_CHANNEL_ENABLED),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_updateMessageConfigChannelEnabled}/${action.payload.id}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => updateMessageConfigChannelRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getMessageTypes = (action$) =>
  action$.pipe(
    ofType(GET_MESSAGE_TYPES),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getMessageTypes}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getMessageTypesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

// Provider Location

adminEpic.getRescheduleSettings = (action$) =>
  action$.pipe(
    ofType(GET_RESCHEDULE_SETTINGS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getRescheduleSettings}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getRescheduleSettingsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveRescheduleSettings = (action$) =>
  action$.pipe(
    ofType(SAVE_RESCHEDULE_SETTINGS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveRescheduleSettings}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveRescheduleSettingsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getProviderLocation = (action$) =>
  action$.pipe(
    ofType(GET_PROVIDER_LOCATION_LIST),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getProviderLocation}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProviderLocationResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveProviderLocation = (action$) =>
  action$.pipe(
    ofType(SAVE_PROVIDER_LOCATION),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_saveProviderLocation}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveProviderLocationResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getProviderLocationById = (action$) =>
  action$.pipe(
    ofType(GET_PROVIDER_LOCATION_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Admin_getProviderLocationById}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProviderLocationByIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getSpecialityApi = (action$) =>
  action$.pipe(
    ofType(SPECIALITY),
    switchMap(() =>
    utlAjax({
        url: `${baseUrl}/${Apis.Admin_getSpecialityApi}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => specialityResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getPlansDetails = (action$) =>
  action$.pipe(
    ofType(GET_PLANS_DETAILS),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/plan/details`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPlansDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getBillingDetails = (action$) =>
  action$.pipe(
    ofType(GET_BILLING_DETAILS),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/plan/`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getBillingDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.makePayment = (action$) =>
  action$.pipe(
    ofType(CREATE_PAYMENT),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/plan/charge`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => makePaymentResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getPlaceId = (action$) =>
  action$.pipe(
    ofType(GET_PLACE_ID),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/review/widget/placeId/`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPlaceIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.savePlaceId = (action$) =>
  action$.pipe(
    ofType(SAVE_PLACE_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/review/widget/placeId/?placeId=${action.payload}`,
        method: 'POST',
      }).pipe(
        map(
          (response) => savePlaceIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.updateCard = (action$) =>
  action$.pipe(
    ofType(UPDATE_CARD),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/plan/update/paymentinfo`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateCardResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getCheckinConfig = (action$) =>
  action$.pipe(
    ofType(GET_CHECKIN_CONFIG),
    switchMap(() =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/checkInConfig/clinicsetting`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCheckinConfigResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.saveCheckinConfig = (action$) =>
  action$.pipe(
    ofType(SAVE_CHECKIN_CONFIG),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/checkInConfig/checkin/1?enable=${action.payload.enable}&type=${action.payload.type}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveCheckinConfigResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

adminEpic.getPayorsList = (action$) =>
  action$.pipe(
    ofType(GET_PAYORS_LIST),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/EftTab/?clinicId=${action.payload.clinicId}&offset=${action.payload.offset}&order=${action.payload.order}&sortId=${action.payload.sortId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getPayorsListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

adminEpic.eftUpdatedStatus = (action$) =>
  action$.pipe(
    ofType(EFT_UPDATE_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/EftTab/${action.payload.clinicId}?bussinessId=${action.payload.bussinessId}&eftStatus=${action.payload.eftStatus}&eraStatus=${action.payload.eraStatus}&networkStatus=${action.payload.networkStatus}`,
        method: "PUT",
      }).pipe(
        map(
          (response) => eftUpdatedStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

adminEpic.getEligibilityConfig = (action$) =>
  action$.pipe(
    ofType(GET_ELIGIBILITY_CONFIG),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-internal-service/v1/eligibilityPortal/configuration/${action.payload.clinicId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getEligibilityConfigResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

adminEpic.saveEligibilityConfig = (action$) =>
  action$.pipe(
    ofType(SAVE_ELIGILIBITY_CONFIG),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-internal-service/v1/eligibilityPortal/enable/${action.payload.clinicId}?isEnbale=${action.payload.enable}&email=${action.payload.email}&dayBefore=${action.payload.days}`,
        method: "PUT",
      }).pipe(
        map(
          (response) => saveEligibilityConfigResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default adminEpic
