import React, { useState } from 'react'
import { createContext } from 'react'

export const dataContext = createContext()

const IcdContextProvider = ({ children }) => {
  const [icdInfo, setIcdinfo] = useState({})
  const [dx1, setDx1] = useState({ title: '' })
  const [dx2, setDx2] = useState({ title: '' })
  const [dx3, setDx3] = useState({ title: '' })
  const [dx4, setDx4] = useState({ title: '' })
  const [dx5, setDx5] = useState({ title: '' })
  const [dx6, setDx6] = useState({ title: '' })
  const [dx7, setDx7] = useState({ title: '' })
  const [dx8, setDx8] = useState({ title: '' })

  return (
    <dataContext.Provider
      value={{
        icdInfo,
        setIcdinfo,
        dx1,
        setDx1,
        dx2,
        setDx2,
        dx3,
        setDx3,
        dx4,
        setDx4,
        dx5,
        setDx5,
        dx6,
        setDx6,
        dx7,
        setDx7,
        dx8,
        setDx8,
      }}
    >
      {children}
    </dataContext.Provider>
  )
}

export default IcdContextProvider
