import { ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as utlAjax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import { objectToUrl } from 'Utils/ajax-utils'
import {
  FETCH_INBOX_HISTORY,
  FETCH_CHAT_MESSAGES,
  SEND_FILE,
  DOWNLOAD_FILE,
} from './chat.types'
import {
  fetchInboxHistoryRes,
  fetchChatMessagesRes,
  sendFileResponse,
  downloadFileRes,
} from './chat.actions'
const { baseUrl } = environment
const chatEpic = {}

chatEpic.fetchInboxHistoryApi = (action$) =>
  action$.pipe(
    ofType(FETCH_INBOX_HISTORY),
    switchMap((action) =>
      utlAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Chat_fetchInboxHistoryApi}`,
          action.payload,
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => fetchInboxHistoryRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

chatEpic.fetchMessagesApi = (action$) =>
  action$.pipe(
    ofType(FETCH_CHAT_MESSAGES),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Chat_fetchMessagesApi}?conversationSessionId=${action.payload.conversationSessionId}&toUserClinicId=${action.payload.toUserClinicId}&toUserId=${action.payload.toUserId}&toUserType=${action.payload.toUserType}&start=${action.payload.start}&limit=${action.payload.limit}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => fetchChatMessagesRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

chatEpic.sendFileInchat = (action$) =>
  action$.pipe(
    ofType(SEND_FILE),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          // 'Content-Type':'multipart/form-data;',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        url: `${baseUrl}/${Apis.Chat_sendFileInchat}`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => sendFileResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
chatEpic.downloadFileInchat = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD_FILE),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          // 'Content-Type':'multipart/form-data;',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        url: `${baseUrl}/${Apis.Chat_downloadFileInchat}?filePath=${action.payload}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => downloadFileRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default chatEpic
