export const FETCH_PATIENTS = 'FETCH_PATIENTS'
export const FETCH_PATIENTS_RES = 'FETCH_PATIENTS_RES'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const PAGE = 'PAGE'
export const LIMIT = 'LIMIT'
export const SORT_FIELD = 'SORT_FIELD'
export const ORDER = 'ORDER'
export const SEARCH_PARAMS = 'SEARCH_PARAMS'
export const ACTIVE_STATUS = 'ACTIVE_STATUS'
export const STATUS_RESPONSE = 'STATUS_RESPONSE'
export const NOTES = 'NOTES'
export const NOTES_RESPONSE = 'NOTES_RESPONSE'
export const ACCOUNTS_EXPORT = 'ACCOUNTS_EXPORT'
export const ACCOUNTS_EXPORT_RES = 'ACCOUNTS_EXPORT_RES'