import {
  GET_CLINIC_DATA_RES,
  SAVE_CLINIC_DATA_RES,
  SAVE_ADMIN_DATA_RES,
  GET_ALL_USER_RES,
  UPDATE_USER_STATUS_RES,
  CLINIC_ZIP_CALL_RES,
  SAVE_USER_RES,
  UPDATE_USER_RES,
  USERNAME_VALIDATION_RES,
  GET_ALL_PROVIDER_RES,
  UPDATE_PROVIDER_STATUS_RES,
  SAVE_PROVIDER_RES,
  PROVIDER_USERNAME_VALIDATION_RES,
  GET_PROVIDER_BY_ID_RES,
  CANCEL_STATUS_SAVE_NEW_PROVIDER,
  GET_ALL_LOCATION_RES,
  SAVE_LOCATION_RES,
  UPDATE_LOCATION_STATUS_RES,
  LOCATION_ZIP_CALL_RES,
  CANCEL_STATUS_SAVE_NEW_LOCATION,
  SAVE_APT_TYPE_RES,
  GET_APT_TYPE_RES,
  UPDATE_APPOINTKMENT_STATUS_RES,
  CANCEL_STATUS_SAVE_NEW_APT_TYPE,
  GET_MESSAGE_CONFIGS_RESPONSE,
  GET_MESSAGE_CONFIG_BY_ID_RESPONSE,
  GET_MESSAGE_CONFIG_DEFAULTS_RESPONSE,
  SAVE_MESSAGE_CONFIG_RESPONSE,
  UPDATE_MESSAGE_CONFIG_RESPONSE,
  GET_MESSAGE_RESP_RESPONSE,
  GET_MESSAGE_RESP_BY_ID_RESPONSE,
  GET_MESSAGE_RESPONSE_DEFAULTS_RESPONSE,
  SAVE_MESSAGE_RESP_RESPONSE,
  UPDATE_MESSAGE_RESPONSE_RESPONSE,
  GET_MESSAGE_SCHEDULE_RESPONSE,
  GET_MESSAGE_SCHEDULE_DEFAULTS_RESPONSE,
  SAVE_MESSAGE_SCHEDULE_RESPONSE,
  UPDATE_MESSAGE_SCHEDULE_RESPONSE,
  GET_MESSAGE_SCHEDULE__BY_CLINIC_ID_RESPONSE,
  MESSAGE_CONFIG_STATUS_RESPONSE,
  MESSAGE_CONFIG_CHANNEL_ENABLED_RESPONSE,
  GET_MESSAGE_TYPES_RESPONSE,
  GET_RESCHEDULE_SETTINGS_RESPONSE,
  SAVE_RESCHEDULE_SETTINGS_RESPONSE,
  GET_PROVIDER_LOCATION_LIST_RESPONSE,
  SAVE_PROVIDER_LOCATION_RESPONSE,
  GET_PROVIDER_LOCATION_BY_ID_RESPONSE,
  SPECIALITY_RES,
  CREATE_PAYMENT_RESPONSE,
  GET_PLANS_DETAILS_RESPONSE,
  GET_BILLING_DETAILS_RESPONSE,
  UPDATE_CARD_RESPONSE,
  GET_PLACE_ID_RESPONSE,
  SAVE_PLACE_ID_RESPONSE,
  GET_CHECKIN_CONFIG_RESPONSE,
  SAVE_CHECKIN_CONFIG_RESPONSE,
  GET_PAYORS_LIST_RES,
  EFT_UPDATE_STATUS_RES,
  GET_ELIGIBILITY_CONFIG_RESPONSE,
  SAVE_ELIGILIBITY_CONFIG_RESPONSE
} from './admin.types'

const INITIAL_STATE = {
  getClinicSettingsDetails: {},
  saveClinicSettingsDetails: {},
  saveAdminDetails: {},
  getAllUsersData: {},
  updateUserStatus: {},
  clinicSettingsZip: {},
  saveUserData: null,
  usernameStatus: {},
  providerList: {},
  providerStatusUpdateRes: {},
  saveProviderRes: {},
  providerUserNameValidRes: {},
  getProviderByIdRes: {},
  cancelStatusForNewPro: false,
  getAllLocationRes: {},
  saveLocationRes: {},
  updateLocationStatusRes: {},
  locationZipRes: {},
  cancelStatusForNewLoc: false,
  saveAppointmentRes: {},
  appointmentTypesList: {},
  appointmentStatusRes: {},
  messageConfigs: [],
  messageConfigById: {},
  messageConfigDefaults: {},
  saveMessageConfigRes: {},
  updateMessageConfigRes: {},
  messageResponse: [],
  messageResponseById: {},
  messageResponseDefaults: [],
  saveMessageResponse: {},
  updateMessageResponse: {},
  messageSchedule: {},
  messageScheduleDefaults: [],
  messageScheduleClinics: [],
  saveMessageSchedule: {},
  updateMessageSchedule: {},
  messageConfigStatusRes: {},
  messageConfigChannelRes: {},
  updateUserData: null,
  messageTypes: [],
  rescheduleSettings: {},
  saveRescheduleSettings: {},
  providerLocationList: [],
  saveProviderLocationRes: {},
  getProviderLocationByIdResponse: [],
  specialityList: null,
  paymentResponse: {},
  plansDetails: [],
  billingDetails: {},
  cardUpdateResponse: {},
  placeIdResponse: {},
  savePlaceIdResponse: {},
  checkinConfig: {},
  saveCheckinConfigRes: {},
  payorsListRes: [],
  eftUpdatedStatusRes: {},
  eligibilityConfig: {},
  saveEligibilityConfigRes: {},
}

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CLINIC_DATA_RES: {
      return { ...state, ...{ getClinicSettingsDetails: action.payload } }
    }
    case SAVE_CLINIC_DATA_RES: {
      return { ...state, ...{ saveClinicSettingsDetails: action.payload } }
    }
    case SAVE_ADMIN_DATA_RES: {
      return { ...state, ...{ saveAdminDetails: action.payload } }
    }
    case GET_ALL_USER_RES: {
      return { ...state, ...{ getAllUsersData: action.payload } }
    }
    case UPDATE_USER_STATUS_RES: {
      return { ...state, ...{ updateUserStatus: action.payload } }
    }
    case CLINIC_ZIP_CALL_RES: {
      return { ...state, ...{ clinicSettingsZip: action.payload } }
    }
    case SAVE_USER_RES: {
      return { ...state, ...{ saveUserData: action.payload } }
    }
    case UPDATE_USER_RES: {
      return { ...state, ...{ updateUserData: action.payload } }
    }
    case USERNAME_VALIDATION_RES: {
      return { ...state, ...{ usernameStatus: action.payload } }
    }
    case GET_ALL_PROVIDER_RES: {
      return { ...state, ...{ providerList: action.payload } }
    }
    case UPDATE_PROVIDER_STATUS_RES: {
      return { ...state, ...{ providerStatusUpdateRes: action.payload } }
    }
    case SAVE_PROVIDER_RES: {
      return { ...state, ...{ saveProviderRes: action.payload } }
    }
    case PROVIDER_USERNAME_VALIDATION_RES: {
      return { ...state, ...{ providerUserNameValidRes: action.payload } }
    }
    case GET_PROVIDER_BY_ID_RES: {
      return { ...state, ...{ getProviderByIdRes: action.payload } }
    }
    case CANCEL_STATUS_SAVE_NEW_PROVIDER: {
      return { ...state, ...{ cancelStatusForNewPro: action.payload } }
    }
    case GET_ALL_LOCATION_RES: {
      return { ...state, ...{ getAllLocationRes: action.payload } }
    }
    case SAVE_LOCATION_RES: {
      return { ...state, ...{ saveLocationRes: action.payload } }
    }
    case UPDATE_LOCATION_STATUS_RES: {
      return { ...state, ...{ updateLocationStatusRes: action.payload } }
    }
    case LOCATION_ZIP_CALL_RES: {
      return { ...state, ...{ locationZipRes: action.payload } }
    }
    case CANCEL_STATUS_SAVE_NEW_LOCATION: {
      return { ...state, ...{ cancelStatusForNewLoc: action.payload } }
    }
    case SAVE_APT_TYPE_RES: {
      return { ...state, ...{ saveAppointmentRes: action.payload } }
    }
    case GET_APT_TYPE_RES: {
      return { ...state, ...{ appointmentTypesList: action.payload } }
    }
    case UPDATE_APPOINTKMENT_STATUS_RES: {
      return { ...state, ...{ appointmentStatusRes: action.payload } }
    }
    case CANCEL_STATUS_SAVE_NEW_APT_TYPE: {
      return { ...state, ...{ cancelStatusForNewAppType: action.payload } }
    }
    case GET_MESSAGE_CONFIGS_RESPONSE: {
      return { ...state, ...{ messageConfigs: action.payload } }
    }
    case GET_MESSAGE_CONFIG_BY_ID_RESPONSE: {
      return { ...state, ...{ messageConfigById: action.payload } }
    }
    case GET_MESSAGE_CONFIG_DEFAULTS_RESPONSE: {
      return { ...state, ...{ messageConfigDefaults: action.payload } }
    }
    case SAVE_MESSAGE_CONFIG_RESPONSE: {
      return { ...state, ...{ saveMessageConfigRes: action.payload } }
    }
    case UPDATE_MESSAGE_CONFIG_RESPONSE: {
      return { ...state, ...{ updateMessageConfigRes: action.payload } }
    }
    case GET_MESSAGE_RESP_RESPONSE: {
      return { ...state, ...{ messageResponse: action.payload } }
    }
    case GET_MESSAGE_RESP_BY_ID_RESPONSE: {
      return { ...state, ...{ messageResponseById: action.payload } }
    }
    case GET_MESSAGE_RESPONSE_DEFAULTS_RESPONSE: {
      return { ...state, ...{ messageResponseDefaults: action.payload } }
    }
    case SAVE_MESSAGE_RESP_RESPONSE: {
      return { ...state, ...{ saveMessageResponse: action.payload } }
    }
    case UPDATE_MESSAGE_RESPONSE_RESPONSE: {
      return { ...state, ...{ updateMessageResponse: action.payload } }
    }
    case GET_MESSAGE_SCHEDULE_RESPONSE: {
      return { ...state, ...{ messageSchedule: action.payload } }
    }
    case GET_MESSAGE_SCHEDULE_DEFAULTS_RESPONSE: {
      return { ...state, ...{ messageScheduleDefaults: action.payload } }
    }
    case SAVE_MESSAGE_SCHEDULE_RESPONSE: {
      return { ...state, ...{ saveMessageSchedule: action.payload } }
    }
    case UPDATE_MESSAGE_SCHEDULE_RESPONSE: {
      return { ...state, ...{ updateMessageSchedule: action.payload } }
    }
    case GET_MESSAGE_SCHEDULE__BY_CLINIC_ID_RESPONSE: {
      return { ...state, ...{ messageScheduleClinics: action.payload } }
    }
    case MESSAGE_CONFIG_STATUS_RESPONSE: {
      return { ...state, ...{ messageConfigStatusRes: action.payload } }
    }
    case MESSAGE_CONFIG_CHANNEL_ENABLED_RESPONSE: {
      return { ...state, ...{ messageConfigChannelRes: action.payload } }
    }
    case GET_MESSAGE_TYPES_RESPONSE: {
      return { ...state, ...{ messageTypes: action.payload } }
    }
    case GET_RESCHEDULE_SETTINGS_RESPONSE: {
      return { ...state, ...{ rescheduleSettings: action.payload } }
    }
    case SAVE_RESCHEDULE_SETTINGS_RESPONSE: {
      return { ...state, ...{ saveRescheduleSettings: action.payload } }
    }
    case GET_PROVIDER_LOCATION_LIST_RESPONSE: {
      return { ...state, ...{ providerLocationList: action.payload } }
    }
    case SAVE_PROVIDER_LOCATION_RESPONSE: {
      return { ...state, ...{ saveProviderLocationRes: action.payload } }
    }
    case GET_PROVIDER_LOCATION_BY_ID_RESPONSE: {
      return {
        ...state,
        ...{ getProviderLocationByIdResponse: action.payload },
      }
    }
    case SPECIALITY_RES: {
      return { ...state, ...{ specialityList: action.payload } }
    }
    case CREATE_PAYMENT_RESPONSE: {
      return { ...state, ...{ paymentResponse: action.payload } }
    }
    case GET_PLANS_DETAILS_RESPONSE: {
      return { ...state, ...{ plansDetails: action.payload } }
    }
    case GET_BILLING_DETAILS_RESPONSE: {
      return { ...state, ...{ billingDetails: action.payload } }
    }
    case GET_PLACE_ID_RESPONSE: {
      return { ...state, ...{ placeIdResponse: action.payload } }
    }
    case SAVE_PLACE_ID_RESPONSE: {
      return { ...state, ...{ savePlaceIdResponse: action.payload } }
    }
    case UPDATE_CARD_RESPONSE: {
      return { ...state, ...{ cardUpdateResponse: action.payload } }
    }
    case GET_CHECKIN_CONFIG_RESPONSE: {
      return { ...state, ...{ checkinConfig: action.payload } }
    }
    case SAVE_CHECKIN_CONFIG_RESPONSE: {
      return { ...state, ...{ saveCheckinConfigRes: action.payload } }
    }
    case GET_PAYORS_LIST_RES: {
      return { ...state, ...{ payorsListRes: action.payload } }
    }
    case EFT_UPDATE_STATUS_RES: {
      return { ...state, ...{ eftUpdatedStatusRes: action.payload } }
    }
    case GET_ELIGIBILITY_CONFIG_RESPONSE: {
      return { ...state, ...{ eligibilityConfig: action.payload } }
    }
    case SAVE_ELIGILIBITY_CONFIG_RESPONSE: {
      return { ...state, ...{ saveEligibilityConfigRes: action.payload } }
    }
    default:
      return state
  }
}

export default adminReducer
