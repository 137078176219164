/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Merina Joy <merina@tensaw.email>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const COL_GROUP_BY_PROVIDER = "COL_GROUP_BY_PROVIDER";
export const COL_GROUP_BY_PROVIDER_RES = "COL_GROUP_BY_PROVIDER_RES";
export const COL_GROUP_BY_SERVICE = "COL_GROUP_BY_SERVICE";
export const COL_GROUP_BY_SERVICE_RES = "COL_GROUP_BY_SERVICE_RES";
export const COL_GROUP_BY_FACILITY = "COL_GROUP_BY_FACILITY";
export const COL_GROUP_BY_FACILITY_RES = "COL_GROUP_BY_FACILITY_RES";
export const COLLECTION_DETAILED = "COLLECTION_DETAILED";
export const COLLECTION_DETAILED_RES = "COLLECTION_DETAILED_RES";
