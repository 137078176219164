import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import {
  loginChatUserSuccess,
  loginChatUserFailure,
} from './chat.actions'
import { CHAT_USER_LOGIN_REQUEST } from './chat.types'
import environment from 'environments/environment'
import { chatService } from './chat.utils'

const { newChatServer } = environment
const baseUrl = `${newChatServer}/api/v1/chat-api/`
const loggedInUser = JSON.parse(localStorage.getItem('user_data'))
const loggedInUserId = loggedInUser?.userId
const NewchatEpic = {}

NewchatEpic.login = (action$) =>
  action$.pipe(
    ofType(CHAT_USER_LOGIN_REQUEST),
    mergeMap(() =>
      from(chatService.get(`${baseUrl}user/${loggedInUserId}`)).pipe(
        mergeMap((response) => {
          return of(loginChatUserSuccess(response.data))
        }),
        catchError((error) => of(loginChatUserFailure(error.message))),
      ),
    ),
  )

export default NewchatEpic
