import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as utlAjax } from 'Utils'
import environment from 'environments/environment'
import {
  GET_ALL_APMTS,
  GET_APMT_INS,
  UPDATE_APMT,
  UPLOAD_APMT,
  GET_STATUS,
  REQUEST_CANCEL,
  EXPORT_APMT,
  BALANCE_DETAILS,
} from './appointments.types'
import {
  getAppointmentsResponse,
  getAppointmentInsResponse,
  updateAppointmentResponse,
  uploadAppointmentResponse,
  getUploadStatusResponse,
  exportAppointmentResponse,
  getBalanceDetailsResponse,
} from './appointments.actions'
import { Apis } from 'Redux/APIs'
const { baseUrl } = environment
const AppointmentsEpic = {}

AppointmentsEpic.getAppointments = (action$) =>
  action$.pipe(
    ofType(GET_ALL_APMTS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Appointments_getAppointments}/${
          action.payload.page
        }?status=${action.payload.status}&providerId=${
          action.payload.providerId
        }&startDate=${action.payload.startDate}&endDate=${
          action.payload.endDate
        }&pagesize=${action.payload.pagesize}&order=${
          action.payload.order
        }&result=${action.payload.result}&appointmentType=${
          action.payload.appointmentType ? action.payload.appointmentType : ''
        }&facilityId=${
          action.payload.facilityId ? action.payload.facilityId : ''
        }`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getAppointmentsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentsEpic.getAppointmentIns = (action$) =>
  action$.pipe(
    ofType(GET_APMT_INS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/reminder/appointment?appointmentId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getAppointmentInsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentsEpic.updateAppointment = (action$) =>
  action$.pipe(
    ofType(UPDATE_APMT),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Appointments_updateAppointment}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateAppointmentResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentsEpic.uploadAppointment = (action$) =>
  action$.pipe(
    ofType(UPLOAD_APMT),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          // 'Content-Type':'multipart/form-data;',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        url: `${baseUrl}/${Apis.Appointments_uploadAppointment}?clinicId=${action.payload.clinicId}&emrType=${action.payload.emrType}`,
        method: 'POST',
        body: action.payload.file,
      }).pipe(
        map(
          (response) => uploadAppointmentResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentsEpic.getUploadStatus = (action$) =>
  action$.pipe(
    ofType(GET_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Appointments_getStatus}?clinicId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getUploadStatusResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentsEpic.exportAppointment = (action$) =>
  action$.pipe(
    ofType(EXPORT_APMT),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Appointments_export}?status=${
          action.payload.status
        }&providerId=${action.payload.providerId}&startDate=${
          action.payload.startDate
        }&endDate=${action.payload.endDate}&order=${
          action.payload.order
        }&result=${action.payload.result}&appointmentType=${
          action.payload.appointmentType ? action.payload.appointmentType : ''
        }&facilityId=${
          action.payload.facilityId ? action.payload.facilityId : ''
        }`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => exportAppointmentResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
// GET BALANCE DETAILS
AppointmentsEpic.getBalanceDetailsApi = (action$) =>
  action$.pipe(
    ofType(BALANCE_DETAILS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Appointment_Balance_Details}/${action.payload}/balance`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getBalanceDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default AppointmentsEpic
