import {
  FETCH_INBOX_HISTORY_RES,
  FETCH_CHAT_MESSAGES_RES,
  MESSAGE_COUNT,
  DOWNLOAD_FILE_RES,
  DOWNLOAD_FILE,
} from './chat.types'

const INITIAL_STATE = {
  inboxHistory: null,
  messageCount: null,
  messages: null,
  file: null,
  fileType: null,
}

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INBOX_HISTORY_RES: {
      return { ...state, ...{ inboxHistory: action.payload.response.data } }
    }
    case FETCH_CHAT_MESSAGES_RES: {
      return { ...state, ...{ messages: action.payload } }
    }
    case MESSAGE_COUNT: {
      return { ...state, ...{ messageCount: action.payload } }
    }
    case DOWNLOAD_FILE: {
      return { ...state, ...{ fileType: action.payload } }
    }
    case DOWNLOAD_FILE_RES: {
      return { ...state, ...{ file: action.payload } }
    }
    default:
      return state
  }
}
export default chatReducer
