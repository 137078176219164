import {
  FETCH_INBOX_HISTORY,
  FETCH_INBOX_HISTORY_RES,
  FETCH_CHAT_MESSAGES,
  FETCH_CHAT_MESSAGES_RES,
  MESSAGE_COUNT,
  SEND_FILE,
  SEND_FILE_RESPONSE,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_RES,
} from './chat.types'

export const fetchInboxHistory = (params) => ({
  type: FETCH_INBOX_HISTORY,
  payload: params,
})
export const fetchInboxHistoryRes = (res) => ({
  type: FETCH_INBOX_HISTORY_RES,
  payload: res,
})
export const fetchChatMessages = (params) => ({
  type: FETCH_CHAT_MESSAGES,
  payload: params,
})

export const fetchChatMessagesRes = (res) => ({
  type: FETCH_CHAT_MESSAGES_RES,
  payload: res,
})

export const messageCount = (data) => ({
  type: MESSAGE_COUNT,
  payload: data,
})
export const sendFile = (data) => ({
  type: SEND_FILE,
  payload: data,
})
export const sendFileResponse = (data) => ({
  type: SEND_FILE_RESPONSE,
  payload: data,
})
export const downloadFile = (data) => ({
  type: DOWNLOAD_FILE,
  payload: data,
})
export const downloadFileRes = (data) => ({
  type: DOWNLOAD_FILE_RES,
  payload: data,
})
