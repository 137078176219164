import {
  SAVE_PAYER_POLICY,
  SAVE_PAYER_POLICY_RES,
  CREATE_INSURANCE_ADDRESS,
  CREATE_INSURANCE_ADDRESS_RES,
  EDIT_PATIENT,
  EDIT_PATIENT_RES,
  VISIBLE,
  PATIENT_UPDATE_STATUS,
  SAVE_PAYER_DETAILS,
  SAVE_PAYER_DETAILS_RESPONSE,
  SAVE_POLICY_HOLDER_DETAILS,
  SAVE_POLICY_HOLDER_DETAILS_RESPONSE,
  SEARCH_PAYER,
  SEARCH_PAYER_RES,
  SEARCH_PAYER_ADDRESS,
  SEARCH_PAYER_ADDRESS_RES,
  CHECKIN,
  CHECKIN_RES,
  GET_INSURANCE_BY_VISITTYPE,
  GET_INSURANCE_BY_VISITTYPE_RES,
  GET_INSURANCE_ACTIVE,
  GET_INSURANCE_ACTIVE_RES,
  CANCEL_CLICK,
  GET_POLICY_HOLDER_DETAILS,
  GET_POLICY_HOLDER_DETAILS_RESPONSE,
  SAVE_HOLDER_STATUS,
  POLICY_OBJECT,
  COLLAPSE_INDEX,
  GET_POLICIES_FOR_CHECKIN,
  GET_APPOINTMENT_LIST,
  GET_APPOINTMENT_LIST_RES,
  CANCEL_APPOINTMENT_LIST,
  CANCEL_APPOINTMENT_LIST_RES,
} from './insurance.types'
/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from calender.type.js,
  payload: object - contains params,
})
 */

/**
 * @description Action for calling the search payer in the payer form
 * @param payervalue - contains the searched payer details
 * @return {{payload: 'payervalue' , type: string}}
 */
export const searchPayer = (payervalue) => ({
  type: SEARCH_PAYER,
  payload: payervalue,
})

/**
 * @description The response action for the search payer api response
 * @param response - contains the response from the search payer api
 * @return {{payload: 'response', type: string}}
 */
export const searchPayerResponse = (response) => ({
  type: SEARCH_PAYER_RES,
  payload: response,
})

/**
 * @description Action for saving the payer and policy detail entered
 * @param insurance - contains the details of payer and policy entered
 * @return {{payload: 'insurance', type: string}}
 */
export const savePayerPolicy = (insurance) => ({
  type: SAVE_PAYER_POLICY,
  payload: insurance,
})

/**
 * @description Action for the response of save payer and policy APi
 * @param insuranceRes - contains the response data of payer and policy entered
 * @return {{payload: 'insuranceRes', type: string}}
 */
export const savePayerPolicyRes = (insuranceRes) => ({
  type: SAVE_PAYER_POLICY_RES,
  payload: insuranceRes,
})

/**
 * @description Action for creating a new insurance address
 * @param address - contains the  data of payer and policy entered
 * @return {{payload: 'address', type: string}}
 */
export const createInsuranceAddress = (address) => ({
  type: CREATE_INSURANCE_ADDRESS,
  payload: address,
})

/**
 * @description Action for getting the response of the create insurance api
 * @param addressRes- contains the response data of the create insurance
 * @return {{payload:'addressRes', type: string}}
 */
export const createInsuranceAddressRes = (addressRes) => ({
  type: CREATE_INSURANCE_ADDRESS_RES,
  payload: addressRes,
})

/**
 * @description Action for editing the patient details
 * @param company -  contains the  data of the patient details
 * @return {{payload:'company', type: string}}
 */
export const editCheckinPatient = (company) => ({
  type: EDIT_PATIENT,
  payload: company,
})
/**
 * @description Action for getting response form editing the patient details
 * @param patientRes -  contains the  response of the edited patient details
 * @return {{payload: 'patientRes', type: string}}
 */
export const editCheckinPatientResponse = (patientRes) => ({
  type: EDIT_PATIENT_RES,
  payload: patientRes,
})
/**
 *@description Action for hiding and showing the insurance table
 * @param visiblevalue
 * @return {{payload: 'visible value', type: string}}
 */
export const visible = (visiblevalue) => ({
  type: VISIBLE,
  payload: visiblevalue,
})

/**
 *@description Action for getting the status if the patient is updated
 * @param isPatientUpdate
 * @return {{payload: 'isPatientUpdate', type: string}}
 */
export const patientUpdateStatus = (isPatientUpdate) => ({
  type: PATIENT_UPDATE_STATUS,
  payload: isPatientUpdate,
})

/**
 *@description Action for saving the payer details
 * @param payerData
 * @return {{payload: 'payerData', type: string}}
 */
export const savePayerDetails = (payerData) => ({
  type: SAVE_PAYER_DETAILS,
  payload: payerData,
})

/**
 * @description Action for getting response form saving payer details API
 * @param savePayerResponse
 * @return {{payload: *, type: string}}
 */
export const savePayerDetailsResponse = (savePayerResponse) => ({
  type: SAVE_PAYER_DETAILS_RESPONSE,
  payload: savePayerResponse,
})

/**
 *@description Action for saving policy holder
 * @param policyHolderData
 * @return {{payload: 'policyHolderData', type: string}}
 */
export const savePolicyHolderDetails = (policyHolderData) => ({
  type: SAVE_POLICY_HOLDER_DETAILS,
  payload: policyHolderData,
})

/**
 *@description Action for getting response from save policy holder API
 *@param policyHolderResponse
 * @return {{payload: *, type: string}}
 */
export const savePolicyHolderDetailsResponse = (policyHolderResponse) => ({
  type: SAVE_POLICY_HOLDER_DETAILS_RESPONSE,
  payload: policyHolderResponse,
})

/**
 *@description Action for calling search payer address APi
 * @param payerAddressData
 * @return {{payload: *, type: string}}
 */
export const searchPayerAddress = (payerAddressData) => ({
  type: SEARCH_PAYER_ADDRESS,
  payload: payerAddressData,
})
/**
 *@description Action for getting response from search payer address APi
 * @param PayerAddressResponse
 * @return {{payload: *, type: string}}
 */
export const searchPayerAddressResponse = (PayerAddressResponse) => ({
  type: SEARCH_PAYER_ADDRESS_RES,
  payload: PayerAddressResponse,
})

/**
 *@description Action for calling Checkin APi
 * @param checkinData
 * @return {{payload: *, type: string}}
 */
export const checkin = (checkinData) => ({
  type: CHECKIN,
  payload: checkinData,
})

/**
 *@description Action for getting response of checkin API
 * @param checkinDataResponse
 * @return {{payload: *, type: string}}
 */
export const checkinResponse = (checkinDataResponse) => ({
  type: CHECKIN_RES,
  payload: checkinDataResponse,
})

/**
 *@description Action for calling  get insurance by visit type APi
 * @param Data
 * @return {{payload: *, type: string}}
 */
export const getInsuranceByVisitType = (Data) => ({
  type: GET_INSURANCE_BY_VISITTYPE,
  payload: Data,
})
/**
 *@description Action for getting response from get insurance by visit type APi
 * @param Response
 * @return {{payload: 'Response', type: string}}
 */
export const getInsuranceByVisitTypeResponse = (Response) => ({
  type: GET_INSURANCE_BY_VISITTYPE_RES,
  payload: Response,
})
/**
 *
 * @param Response
 * @return {{payload: *, type: string}}
 */
export const getInsuranceActive = (Response) => ({
  type: GET_INSURANCE_ACTIVE,
  payload: Response,
})
/**
 *@description Action for creating a new insurance address
 * @param Response
 * @return {{payload: *, type: string}}
 */
export const getInsuranceActiveResponse = (Response) => ({
  type: GET_INSURANCE_ACTIVE_RES,
  payload: Response,
})
/**
 *@description Action for getting status if cancel button clicked
 * @param value
 * @return {{payload: 'value', type: string}}
 */
export const cancelClick = (value) => ({
  type: CANCEL_CLICK,
  payload: value,
})
/**
 *@description Action for saving policy holder status
 * @param value
 * @return {{payload: 'values', type: string}}
 */
export const saveHolderStatus = (value) => ({
  type: SAVE_HOLDER_STATUS,
  payload: value,
})
/**
 *@description Action for getting policy holder details
 * @param policyHolderData
 * @return {{payload: 'policyHolderData', type: string}}
 */
export const getPolicyHolderDetails = (policyHolderData) => ({
  type: GET_POLICY_HOLDER_DETAILS,
  payload: policyHolderData,
})
/**
 *@description Action for getting response from get policy holder details API
 * @param policyHolderResponse
 * @return {{payload: 'policyHolderResponse', type: string}}
 */
export const getPolicyHolderDetailsResponse = (policyHolderResponse) => ({
  type: GET_POLICY_HOLDER_DETAILS_RESPONSE,
  payload: policyHolderResponse,
})

/**
 *@description Action for passing fr passing policy form object to policy table
 * @param policyHolderResponse
 * @return {{payload: 'policyHolderResponse', type: string}}
 */
export const updatePolicyArray = (policyObject) => ({
  type: POLICY_OBJECT,
  payload: policyObject,
})

export const passIndex = (value) => ({
  type: COLLAPSE_INDEX,
  payload: value,
})
export const savePoliciesForCheckin = (latestPolicies) => ({
  type: GET_POLICIES_FOR_CHECKIN,
  payload: latestPolicies,
})

export const getAppointmentList = (data) => ({
  type: GET_APPOINTMENT_LIST,
  payload: data,
})

export const getAppointmentListRes = (data) => ({
  type: GET_APPOINTMENT_LIST_RES,
  payload: data,
})

export const cancelAppointmentList = (data) => ({
  type: CANCEL_APPOINTMENT_LIST,
  payload: data,
})

export const cancelAppointmentListRes = (data) => ({
  type: CANCEL_APPOINTMENT_LIST_RES,
  payload: data,
})
