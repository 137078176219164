import {
  ZIPCODE_FOR_DEMOGRAPHICS_RES,
  GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS_RES,
  EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS_RES,
  SEARCH_PAYER_INSURANCE_TAB_RES,
  SEARCH_PAYER_ADDRESS_INSURANCE_TAB_RES,
  CLEAR_HEADER,
  GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS_RES,
  GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB_RES,
  EDIT_SAVE_INSURANCE_TAB_RES,
  STATUS_CHANGE_OF_INSURNACE_RES,
  CURRENT_TAB_VALUE,
  GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES,
  SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES,
  GET_NOTES_FOR_APPOINTMENT_RES,
  SAVE_NOTES_FOR_APPOINTMENT_RES,
  GET_APPOINTMENT_LIST_RES_ACCOUNTS,
  CANCEL_APPOINTMENT_LIST_RES_ACCOUNTS,
  GET_ACCOUNTS_RES,
  CLAIM_LIST_RES,
  CLAIM_DOWNLOAD_RES,
  SAVE_PAYMENTS_RES,
  WRITEOFF_RES,
  PAYMENT_LIST_RES,
  BALANCE_LIST_RES,
  STATEMENT_LIST_RES,
  AUTHORIZATIONS_RES,
  PAYMENT_DELETE_RES,
  STRIPE_PAYMENT_RES,
  STATEMENT_DETAILS_RES,
  GET_PIN_RES,
  GET_CLAIM_NOTES_RES,
} from './accounts.types'

const INITIAL_STATE = {
  demographicsZipData: {},
  PatientList: [],
  paymentDetailsAccounts: {},
  getpaymentByIdDataAccounts: {},
  deletePaymentDetailsAccounts: {},
  paymentDetail: {},
  editPaymentDetails: {},
  getpaymentByApptIdData: {},
  PatientDetilsDemographics: null,
  updatedPatientDetailsDemographics: null,
  clearHeader: false,
  patientPolicyDetails: {},
  policyHolderListInsurnaceTab: {},
  editSavedInsuranceRes: {},
  statusUpdatedResponse: null,
  currentTabValue: {},
  patientNotesListInAccounts: {},
  savePatientNotesResInAccounts: {},
  appointmentNotesList: {},
  saveAppointmentNotesRes: {},
  appointmentList: {},
  appointmentCancelStatus: {},
  insuranceTabSearchPayerAddressRes: null,
  insuranceTabSearchPayerRes: null,
  accountsInfo: null,
  claimList: null,
  claimFile: null,
  paymentList: null,
  paymentRes: null,
  writeoffres: null,
  balanceList: null,
  statementList: null,
  authorizationList: null,
  deletePayment: null,
  stripeResponse: null,
  statementDetailRes: null,
  patientPinRes: null,
  claimsNotesList: {},
}

const accountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZIPCODE_FOR_DEMOGRAPHICS_RES: {
      return {
        ...state,
        ...{ demographicsZipData: action.payload },
      }
    }
    case GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS_RES: {
      return { ...state, ...{ PatientDetilsDemographics: action.payload } }
    }
    case EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS_RES: {
      return {
        ...state,
        ...{ updatedPatientDetailsDemographics: action.payload },
      }
    }
    case GET_ACCOUNTS_RES: {
      return {
        ...state,
        ...{ accountsInfo: action.payload },
      }
    }
    case CLAIM_LIST_RES: {
      return {
        ...state,
        ...{ claimList: action.payload },
      }
    }
    case CLAIM_DOWNLOAD_RES: {
      return {
        ...state,
        ...{ claimFile: action.payload },
      }
    }
    case WRITEOFF_RES: {
      return {
        ...state,
        ...{ writeoffres: action.payload },
      }
    }
    case PAYMENT_LIST_RES: {
      return {
        ...state,
        ...{ paymentList: action.payload },
      }
    }
    case SAVE_PAYMENTS_RES: {
      return {
        ...state,
        ...{ paymentRes: action.payload },
      }
    }
    case BALANCE_LIST_RES: {
      return {
        ...state,
        ...{ balanceList: action.payload },
      }
    }
    case STATEMENT_LIST_RES: {
      return {
        ...state,
        ...{ statementList: action.payload },
      }
    }
    case STATEMENT_DETAILS_RES: {
      return {
        ...state,
        ...{ statementDetailRes: action.payload },
      }
    }
    case AUTHORIZATIONS_RES: {
      return {
        ...state,
        ...{ authorizationList: action.payload },
      }
    }
    case PAYMENT_DELETE_RES: {
      return {
        ...state,
        ...{ deletePayment: action.payload },
      }
    }
    case SEARCH_PAYER_INSURANCE_TAB_RES: {
      return {
        ...state,
        ...{ insuranceTabSearchPayerRes: action.payload },
      }
    }
    case SEARCH_PAYER_ADDRESS_INSURANCE_TAB_RES: {
      return {
        ...state,
        ...{ insuranceTabSearchPayerAddressRes: action.payload },
      }
    }
    case CLEAR_HEADER: {
      return {
        ...state,
        ...{ clearHeader: action.payload },
      }
    }
    case GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS_RES: {
      return {
        ...state,
        ...{ patientPolicyDetails: action.payload },
      }
    }
    case GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB_RES: {
      return {
        ...state,
        ...{ policyHolderListInsurnaceTab: action.payload },
      }
    }

    case EDIT_SAVE_INSURANCE_TAB_RES: {
      return {
        ...state,
        ...{ editSavedInsuranceRes: action.payload },
      }
    }
    case STATUS_CHANGE_OF_INSURNACE_RES: {
      return {
        ...state,
        ...{ statusUpdatedResponse: action.payload },
      }
    }

    case CURRENT_TAB_VALUE: {
      return {
        ...state,
        ...{ currentTabValue: action.payload },
      }
    }
    case GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES: {
      return {
        ...state,
        ...{ patientNotesListInAccounts: action.payload },
      }
    }
    case SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES: {
      return {
        ...state,
        ...{ savePatientNotesResInAccounts: action.payload },
      }
    }

    case GET_NOTES_FOR_APPOINTMENT_RES: {
      return {
        ...state,
        ...{ appointmentNotesList: action.payload },
      }
    }

    case SAVE_NOTES_FOR_APPOINTMENT_RES: {
      return {
        ...state,
        ...{ saveAppointmentNotesRes: action.payload },
      }
    }

    case GET_APPOINTMENT_LIST_RES_ACCOUNTS: {
      return { ...state, ...{ appointmentList: action.payload } }
    }
    case CANCEL_APPOINTMENT_LIST_RES_ACCOUNTS: {
      return { ...state, ...{ appointmentCancelStatus: action.payload } }
    }
    case STRIPE_PAYMENT_RES: {
      return { ...state, ...{ stripeResponse: action.payload } }
    }
    case GET_PIN_RES: {
      return { ...state, ...{ patientPinRes: action.payload } }
    }
    case GET_CLAIM_NOTES_RES: {
      return { ...state, ...{ claimsNotesList: action.payload } }
    }
    default:
      return state
  }
}
export default accountsReducer
