import {
  GET_ALL_REVIEWS,
  GET_ALL_REVIEWS_RES,
  GET_REVIEW_BY_ID,
  GET_REVIEW_BY_ID_RES,
} from './reviews.types'

export const getReviews = (params) => ({
  type: GET_ALL_REVIEWS,
  payload: params,
})

export const getReviewsResponse = (response) => ({
  type: GET_ALL_REVIEWS_RES,
  payload: response,
})

export const getReviewById = (params) => ({
  type: GET_REVIEW_BY_ID,
  payload: params,
})

export const getReviewByIdResponse = (response) => ({
  type: GET_REVIEW_BY_ID_RES,
  payload: response,
})
