import {
  COL_GROUP_BY_FACILITY_RES,
  COL_GROUP_BY_PROVIDER_RES,
  COL_GROUP_BY_SERVICE_RES,
  COLLECTION_DETAILED_RES,
} from "./Collection.types";

/**
 * @fileOverview Manages the response of action
 * @author  Merina Joy <merina@tensaw.email>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  ColGroupByProvider: [],
  ColGroupByService: [],
  ColGroupByFacility: [],
  colDetailed: {},
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const CollectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COL_GROUP_BY_PROVIDER_RES: {
      return { ...state, ...{ ColGroupByProvider: action.payload } };
    }
    case COL_GROUP_BY_SERVICE_RES: {
      return { ...state, ...{ ColGroupByService: action.payload } };
    }
    case COL_GROUP_BY_FACILITY_RES: {
      return { ...state, ...{ ColGroupByFacility: action.payload } };
    }
    case COLLECTION_DETAILED_RES: {
      return { ...state, ...{ colDetailed: action.payload } };
    }
    default:
      return state;
  }
};

export default CollectionReducer;
