import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import { objectToUrl } from "Utils/ajax-utils";
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import {
  FETCH_PATIENTS,
  REQUEST_CANCEL,
  ACTIVE_STATUS,
  NOTES,
  ACCOUNTS_EXPORT
} from './accountsTable.types'
import {
  fetchPatientsResponse,
  getNotesResponse,
  statusResponse,
  accountsExportRes
} from './accountsTable.actions'
const { baseUrl } = environment
const AccountsTableEpic = {}

AccountsTableEpic.getPatienTablelist = (action$) =>
  action$.pipe(
    ofType(FETCH_PATIENTS),
    switchMap((action) =>
      ajax({
        url: objectToUrl(
          `${baseUrl}/${Apis.AccountsTable_getPatienTablelist}`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => fetchPatientsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AccountsTableEpic.changestatus = (action$) =>
  action$.pipe(
    ofType(ACTIVE_STATUS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.AccountsTable_changestatus}/${action.payload.id}?status=${action.payload.status}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => statusResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsTableEpic.getNotesApi = (action$) =>
  action$.pipe(
    ofType(NOTES),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.AccountsTable_getNotesApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getNotesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsTableEpic.accountsExport = (action$) =>
  action$.pipe(
    ofType(ACCOUNTS_EXPORT),
    switchMap((action) =>
      ajax({
        url: objectToUrl(
          `${baseUrl}/trillium-clinic-service/v1/account/export`, 
           action.payload
          ),
           method: 'GET',
           responseType: "blob",
      }).pipe(
        map(
          (response) => accountsExportRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default AccountsTableEpic
