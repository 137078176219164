import {
  SAVE_PAYMENT_RES,
  EDIT_PAYMENT_RES,
  GET_PAYMENT_APPTID_RES,
  GET_PAYMENT_ID_RES,
  DELETE_PAYMENT_RES,
  PAYMENT_EDIT_ID,
} from './payment.types'

/**
   * @fileOverview Manages the response of action
   * @author Cinu James <cinu@trillium-technologies.com>
   * @example switch (action.type) {
      case TYPE_NAME: {
        return {
          ...state,
          ...{INITIAL_STATE: action.payload },
        }
      }
      }
   */
const INITIAL_STATE = {
  paymentDetails: {},
  editPaymentDetails: {},
  getpaymentByApptIdData: {},
  getpaymentByIdData: {},
  deletePaymentDetails: {},
  paymentEditId: 0,
}

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PAYMENT_RES: {
      return { ...state, ...{ paymentDetails: action.payload } }
    }
    case EDIT_PAYMENT_RES: {
      return { ...state, ...{ editPaymentDetails: action.payload } }
    }
    case GET_PAYMENT_APPTID_RES: {
      return { ...state, ...{ getpaymentByApptIdData: action.payload } }
    }
    case GET_PAYMENT_ID_RES: {
      return { ...state, ...{ getpaymentByIdData: action.payload } }
    }
    case DELETE_PAYMENT_RES: {
      return { ...state, ...{ deletePaymentDetails: action.payload } }
    }
    case PAYMENT_EDIT_ID: {
      return { ...state, ...{ paymentEditId: action.payload } }
    }
    default:
      return state
  }
}
export default paymentReducer
