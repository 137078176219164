import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax as UtilsAjax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import {
  REQUEST_CANCEL,
  GET_PATIENT_LIST,
  VIDEO_TOKEN,
  DELETE_QUEUE,
  VIDEO_MAIL,
  PATIENT_TILE,
} from './video.types'
import {
  getPatientListResponse,
  getVideoTokenRes,
  deleteQueueRes,
  videomailRes,
  getPatientTileRes,
} from './video.actions'
const { baseUrl } = environment
const VideoEpic = {}
VideoEpic.patientlistApi = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_LIST),
    switchMap(() =>
      UtilsAjax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Video_patientlistApi}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPatientListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
VideoEpic.patientTileApi = (action$) =>
  action$.pipe(
    ofType(PATIENT_TILE),
    switchMap((action) =>
      UtilsAjax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Video_patientTileApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPatientTileRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

VideoEpic.getClinicVideoTokenApi = (action$) =>
  action$.pipe(
    ofType(VIDEO_TOKEN),
    switchMap((action) =>
      UtilsAjax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Video_getClinicVideoTokenApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getVideoTokenRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
VideoEpic.deletePatientQueue = (action$) =>
  action$.pipe(
    ofType(DELETE_QUEUE),
    switchMap((action) =>
      UtilsAjax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Video_deletePatientQueue}/${action.payload}`,
        method: 'DELETE',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => deleteQueueRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
VideoEpic.videoMailApi = (action$) =>
  action$.pipe(
    ofType(VIDEO_MAIL),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Video_videoMailApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => videomailRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default VideoEpic
