export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_RES = "GET_PAYMENTS_RES";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_RES = "UPDATE_STATUS_RES";
export const UPDATE_POSTED_DATE = "UPDATE_POSTED_DATE";
export const UPDATE_POSTED_DATE_RES = "UPDATE_POSTED_DATE_RES";
export const EXPORT_PAYMENTS = "EXPORT_PAYMENTS";
export const EXPORT_PAYMENTS_RES = "EXPORT_PAYMENTS_RES";
export const DOWNLOAD_PAYMENTS = "DOWNLOAD_PAYMENTS";
export const DOWNLOAD_PAYMENTS_RES = "DOWNLOAD_PAYMENTS_RES";
export const GET_EXPORT_STATUS = "GET_EXPORT_STATUS";
export const GET_EXPORT_STATUS_RES = "GET_EXPORT_STATUS_RES";
export const UPDATE_DEPOSIT_DATE = "UPDATE_DEPOSIT_DATE";
export const UPDATE_DEPOSIT_DATE_RES = "UPDATE_DEPOSIT_DATE_RES"
export const UPDATE_BATCH_NO = "UPDATE_BATCH_NO";
export const UPDATE_BATCH_NO_RES = "UPDATE_BATCH_NO_RES";