import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './Redux/store'
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import Context from 'context/AppContextProvider'
import IcdContextProvider from 'context/ContextProvider'
/**
 * @file Index.jsx
 * @fileoverview React starting main file, it call App.jsx for manage all other react function and also call Provider for manage redux storage
 * @author Anuragh KP <kpanuragh@gmail.com>
 *
 */

const theme = createTheme({
  palette: {
    primary: {
      main: '#139696', // Teal color which matches the companies theme
    },
    secondary: {
      main: '#A3ACB9', // Grey colour matches sail-gray 300
    },
    red: {
      main: '#d50d0d', // Grey colour matches sail-gray 300
    },
  },
  typography: {
    fontFamily: `Inter', sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
})

const contexts = [IcdContextProvider]

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Context components={contexts}>
          <App />
        </Context>
      </Provider>
    </ThemeProvider>
  </>,
  document.getElementById('root'),
)

// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <Provider store={store}>
//         <Context components={contexts}>
//           <App />
//         </Context>
//       </Provider>
//     </ThemeProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
