import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax as utlAjax } from 'Utils'
import environment from 'environments/environment'
import {
  GET_ALL_REVIEWS,
  GET_REVIEW_BY_ID,
  REQUEST_CANCEL,
} from './reviews.types'
import { getReviewsResponse, getReviewByIdResponse } from './reviews.actions'
import { Apis } from 'Redux/APIs'
const { baseUrl } = environment

const ReviewsEpic = {}

ReviewsEpic.getAllReviews = (action$) =>
  action$.pipe(
    ofType(GET_ALL_REVIEWS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Reviews_getAllReviews}/${action.payload.page}?pagesize=${action.payload.count}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&providerId=${action.payload.providerId}&rating=${action.payload.rating}&ratingTwo=${action.payload.ratingTwo}&ratingThree=${action.payload.ratingThree}&ratingFour=${action.payload.ratingFour}&ratingFive=${action.payload.ratingFive}&source=${action.payload.source}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getReviewsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ReviewsEpic.getReviewById = (action$) =>
  action$.pipe(
    ofType(GET_REVIEW_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/${Apis.Reviews_getReviewById}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getReviewByIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default ReviewsEpic
