import {
  ZIPCODE_FOR_DEMOGRAPHICS,
  ZIPCODE_FOR_DEMOGRAPHICS_RES,
  GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS,
  GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS_RES,
  EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS,
  EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS_RES,
  SEARCH_PAYER_INSURANCE_TAB,
  SEARCH_PAYER_ADDRESS_INSURANCE_TAB,
  SEARCH_PAYER_INSURANCE_TAB_RES,
  SEARCH_PAYER_ADDRESS_INSURANCE_TAB_RES,
  CLEAR_HEADER,
  GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS,
  GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS_RES,
  GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB,
  GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB_RES,
  EDIT_SAVE_INSURANCE_TAB,
  EDIT_SAVE_INSURANCE_TAB_RES,
  STATUS_CHANGE_OF_INSURNACE,
  STATUS_CHANGE_OF_INSURNACE_RES,
  CURRENT_TAB_VALUE,
  GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID,
  GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES,
  SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID,
  SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES,
  GET_NOTES_FOR_APPOINTMENT,
  GET_NOTES_FOR_APPOINTMENT_RES,
  SAVE_NOTES_FOR_APPOINTMENT,
  SAVE_NOTES_FOR_APPOINTMENT_RES,
  GET_APPOINTMENT_LIST_ACCOUNTS,
  GET_APPOINTMENT_LIST_RES_ACCOUNTS,
  CANCEL_APPOINTMENT_LIST_ACCOUNTS,
  CANCEL_APPOINTMENT_LIST_RES_ACCOUNTS,
  GET_ACCOUNTS,
  GET_ACCOUNTS_RES,
  CLAIM_LIST,
  CLAIM_LIST_RES,
  CLAIM_DOWNLOAD,
  CLAIM_DOWNLOAD_RES,
  SAVE_PAYMENTS,
  SAVE_PAYMENTS_RES,
  PAYMENT_LIST,
  PAYMENT_LIST_RES,
  WRITEOFF,
  WRITEOFF_RES,
  BALANCE_LIST,
  BALANCE_LIST_RES,
  STATEMENT_LIST,
  STATEMENT_LIST_RES,
  AUTHORIZATIONS,
  AUTHORIZATIONS_RES,
  PAYMENT_DELETE,
  PAYMENT_DELETE_RES,
  STRIPE_PAYMENT,
  STRIPE_PAYMENT_RES,
  STATEMENT_DETAILS,
  STATEMENT_DETAILS_RES,
  GET_PIN,
  GET_PIN_RES,
  GET_CLAIM_NOTES,
  GET_CLAIM_NOTES_RES,
} from './accounts.types'

export const searchZipcodeInDemographics = (zipData) => ({
  type: ZIPCODE_FOR_DEMOGRAPHICS,
  payload: zipData,
})
export const searchZipcodeInDemographicsRes = (zipData) => ({
  type: ZIPCODE_FOR_DEMOGRAPHICS_RES,
  payload: zipData,
})
export const getPatientForDemographicsTab = (value) => ({
  type: GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS,
  payload: value,
})

export const getPatientForDemographicsTabRes = (value) => ({
  type: GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS_RES,
  payload: value,
})
export const editAndSavePatientDemographics = (value) => ({
  type: EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS,
  payload: value,
})
export const editAndSavePatientDemographicsRes = (value) => ({
  type: EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS_RES,
  payload: value,
})

export const getAccounts = (value) => ({
  type: GET_ACCOUNTS,
  payload: value,
})
export const getAccountsRes = (res) => ({
  type: GET_ACCOUNTS_RES,
  payload: res,
})
export const claimList = (value) => ({
  type: CLAIM_LIST,
  payload: value,
})
export const claimListRes = (res) => ({
  type: CLAIM_LIST_RES,
  payload: res,
})
export const claimDownload = (value) => ({
  type: CLAIM_DOWNLOAD,
  payload: value,
})
export const claimDownloadRes = (value) => ({
  type: CLAIM_DOWNLOAD_RES,
  payload: value,
})
export const savePayment = (value) => ({
  type: SAVE_PAYMENTS,
  payload: value,
})
export const savePaymentRes = (res) => ({
  type: SAVE_PAYMENTS_RES,
  payload: res,
})
export const paymentList = (value) => ({
  type: PAYMENT_LIST,
  payload: value,
})
export const paymentListRes = (res) => ({
  type: PAYMENT_LIST_RES,
  payload: res,
})
export const writeOff = (value) => ({
  type: WRITEOFF,
  payload: value,
})
export const writeOffRes = (res) => ({
  type: WRITEOFF_RES,
  payload: res,
})
export const balanceList = (value) => ({
  type: BALANCE_LIST,
  payload: value,
})
export const balanceListRes = (res) => ({
  type: BALANCE_LIST_RES,
  payload: res,
})
export const statementList = (value) => ({
  type: STATEMENT_LIST,
  payload: value,
})
export const statementListRes = (value) => ({
  type: STATEMENT_LIST_RES,
  payload: value,
})
export const statementDetails = (value) => ({
  type: STATEMENT_DETAILS,
  payload: value,
})
export const statementDetailsRes = (value) => ({
  type: STATEMENT_DETAILS_RES,
  payload: value,
})
export const authorizationList = (value) => ({
  type: AUTHORIZATIONS,
  payload: value,
})
export const authorizationListRes = (res) => ({
  type: AUTHORIZATIONS_RES,
  payload: res,
})
export const paymentDelete = (value) => ({
  type: PAYMENT_DELETE,
  payload: value,
})
export const paymentDeleteRes = (res) => ({
  type: PAYMENT_DELETE_RES,
  payload: res,
})
export const searchPayerInInsurnaceTab = (payervalue) => ({
  type: SEARCH_PAYER_INSURANCE_TAB,
  payload: payervalue,
})
export const searchPayerInInsurnaceTabRes = (payervalue) => ({
  type: SEARCH_PAYER_INSURANCE_TAB_RES,
  payload: payervalue,
})
export const searchPayerAddressInInsuranceTab = (payerAddressData) => ({
  type: SEARCH_PAYER_ADDRESS_INSURANCE_TAB,
  payload: payerAddressData,
})
export const searchPayerAddressInInsuranceTabRes = (payerAddressData) => ({
  type: SEARCH_PAYER_ADDRESS_INSURANCE_TAB_RES,
  payload: payerAddressData,
})
export const clearHeader = (data) => ({
  type: CLEAR_HEADER,
  payload: data,
})
export const getPoliciesByPatientIdDemographics = (data) => ({
  type: GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS,
  payload: data,
})
export const getPoliciesByPatientIdDemographicsRes = (data) => ({
  type: GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS_RES,
  payload: data,
})
export const getPolicyHolderByPatientIdInsuranceTab = (data) => ({
  type: GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB,
  payload: data,
})
export const getPolicyHolderByPatientIdInsuranceTabRes = (data) => ({
  type: GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB_RES,
  payload: data,
})

export const editAndSaveinsurnaceTab = (value) => ({
  type: EDIT_SAVE_INSURANCE_TAB,
  payload: value,
})
export const editAndSaveinsurnaceTabRes = (value) => ({
  type: EDIT_SAVE_INSURANCE_TAB_RES,
  payload: value,
})

export const changeStatusOfInsurnace = (value) => ({
  type: STATUS_CHANGE_OF_INSURNACE,
  payload: value,
})
export const changeStatusOfInsurnaceRes = (value) => ({
  type: STATUS_CHANGE_OF_INSURNACE_RES,
  payload: value,
})

export const setCurrentTabValue = (value) => ({
  type: CURRENT_TAB_VALUE,
  payload: value,
})
export const getNotesByPatientIdAccounts = (value) => ({
  type: GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID,
  payload: value,
})

export const getNotesByPatientIdAccountsRes = (value) => ({
  type: GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES,
  payload: value,
})

export const saveNotesByPatientIdAccounts = (value) => ({
  type: SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID,
  payload: value,
})

export const saveNotesByPatientIdAccountsRes = (value) => ({
  type: SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES,
  payload: value,
})

export const getNotesAppointment = (value) => ({
  type: GET_NOTES_FOR_APPOINTMENT,
  payload: value,
})

export const getNotesAppointmentRes = (value) => ({
  type: GET_NOTES_FOR_APPOINTMENT_RES,
  payload: value,
})

export const saveNotesAppointment = (value) => ({
  type: SAVE_NOTES_FOR_APPOINTMENT,
  payload: value,
})

export const saveNotesByAppointmentRes = (value) => ({
  type: SAVE_NOTES_FOR_APPOINTMENT_RES,
  payload: value,
})
export const getAppointmentList = (data) => ({
  type: GET_APPOINTMENT_LIST_ACCOUNTS,
  payload: data,
})

export const getAppointmentListRes = (data) => ({
  type: GET_APPOINTMENT_LIST_RES_ACCOUNTS,
  payload: data,
})

export const cancelAppointmentList = (data) => ({
  type: CANCEL_APPOINTMENT_LIST_ACCOUNTS,
  payload: data,
})

export const cancelAppointmentListRes = (data) => ({
  type: CANCEL_APPOINTMENT_LIST_RES_ACCOUNTS,
  payload: data,
})
export const stripePayment = (data) => ({
  type: STRIPE_PAYMENT,
  payload: data,
})

export const stripePaymentRes = (res) => ({
  type: STRIPE_PAYMENT_RES,
  payload: res,
})
export const getPatientPin = (data) => ({
  type: GET_PIN,
  payload: data,
})

export const getPatientPinRes = (res) => ({
  type: GET_PIN_RES,
  payload: res,
})

export const getClaimsNotes = (data) => ({
  type: GET_CLAIM_NOTES,
  payload: data,
})

export const getClaimsNotesResponse = (res) => ({
  type: GET_CLAIM_NOTES_RES,
  payload: res,
})
