import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
// import { Apis } from "Redux/APIs";
import environment from "../../../environments/environment";

import {
  REQUEST_CANCEL,
  GET_PAYMENTS,
  UPDATE_POSTED_DATE,
  UPDATE_STATUS,
  EXPORT_PAYMENTS,
  DOWNLOAD_PAYMENTS,
  GET_EXPORT_STATUS,
  UPDATE_DEPOSIT_DATE,
} from "./BankRecon.types";

import {
  getPaymentsRes,
  updatePostedDateRes,
  updateStatusRes,
  exportPaymentsRes,
  downloadPaymentsRes,
  getExportStatusRes,
  updateDepositDateRes,
} from "./BankRecon.actions";

const baseUrl = environment.baseUrl
const BankReconEpic = {};

BankReconEpic.getPayments = (action$) =>
  action$.pipe(
    ofType(GET_PAYMENTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/payment/${action.payload.clinicId}?offset=${action.payload.offset}&fromDate=${action.payload.postedFrom}&toDate=${action.payload.postedTo}&fromAmount=${action.payload.minAmount}&toAmount=${action.payload.maxAmount}&reconciled=${action.payload.reconStatus}&sortId=${action.payload.sortId}&order=${action.payload.order}&payerType=${action.payload.payor}&zeroFilter=${action.payload.zeroFilter}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getPaymentsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

BankReconEpic.updateStatus = (action$) =>
  action$.pipe(
    ofType(UPDATE_STATUS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/payment/${action.payload.clinicId}?depositStatus=${action.payload.depositStatus}&paymentId=${action.payload.paymentId}`,
        method: "PUT",
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

BankReconEpic.updatePostedDate = (action$) =>
  action$.pipe(
    ofType(UPDATE_POSTED_DATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/payment/postedDate/${action.payload.clinicId}?paymentId=${action.payload.paymentId}&postedDate=${action.payload.postedDate}`,
        method: "PUT",
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updatePostedDateRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

BankReconEpic.exportPayments = (action$) =>
  action$.pipe(
    ofType(EXPORT_PAYMENTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/payment/export?fromDate=${action.payload.postedFrom}&toDate=${action.payload.postedTo}&fromAmount=${action.payload.minAmount}&toAmount=${action.payload.maxAmount}&reconciled=${action.payload.reconStatus}&payerType=${action.payload.payor}`,
        method: "GET",
      }).pipe(
        map(
          (response) => exportPaymentsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

BankReconEpic.downloadPayments = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD_PAYMENTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/download?filePath=${action.payload}`,
        method: "GET",
        responseType: "blob",
      }).pipe(
        map(
          (response) => downloadPaymentsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

BankReconEpic.getExportStatus = (action$) =>
  action$.pipe(
    ofType(GET_EXPORT_STATUS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/status/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getExportStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

BankReconEpic.updateDepositDate = (action$) =>
  action$.pipe(
    ofType(UPDATE_DEPOSIT_DATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/dashboard/payment/${action.payload.clinicId}?batchNo=${action.payload.bacthNo}&depositDate=${action.payload.depositDate}&depositStatus=${action.payload.depositStatus}&paymentId=${action.payload.paymentId}&paymentLock=${action.payload.paymentLock}`,
        method: "PUT",
      }).pipe(
        map(
          (response) => updateDepositDateRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default BankReconEpic;
