export const GET_ALL_APMTS = 'GET_ALL_APMTS'
export const GET_ALL_APMTS_RES = 'GET_ALL_APMTS_RES'
export const GET_APMT_INS = 'GET_APMT_INS'
export const GET_APMT_INS_RES = 'GET_APMT_INS_RES'
export const UPDATE_APMT = 'UPDATE_APMT'
export const UPDATE_APMT_RES = 'UPDATE_APMT_RES'
export const UPLOAD_APMT = 'UPLOAD_APMT'
export const UPLOAD_APMT_RES = 'UPLOAD_APMT_RES'
export const GET_STATUS = 'GET_STATUS'
export const GET_STATUS_RES = 'GET_STATUS_RES'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const EXPORT_APMT = 'EXPORT_APMT'
export const EXPORT_APMT_RES = 'EXPORT_APMT_RES'
export const BALANCE_DETAILS = 'BALANCE_DETAILS'
export const BALANCE_DETAILS_RES = 'BALANCE_DETAILS_RES'
