import {
  GET_CLINIC_DATA,
  GET_CLINIC_DATA_RES,
  SAVE_CLINIC_DATA,
  SAVE_CLINIC_DATA_RES,
  SAVE_ADMIN_DATA,
  SAVE_ADMIN_DATA_RES,
  GET_ALL_USER,
  GET_ALL_USER_RES,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_RES,
  CLINIC_ZIP_CALL,
  CLINIC_ZIP_CALL_RES,
  SAVE_USER,
  SAVE_USER_RES,
  UPDATE_USER,
  UPDATE_USER_RES,
  USERNAME_VALIDATION,
  USERNAME_VALIDATION_RES,
  GET_ALL_PROVIDER,
  GET_ALL_PROVIDER_RES,
  UPDATE_PROVIDER_STATUS,
  UPDATE_PROVIDER_STATUS_RES,
  SAVE_PROVIDER,
  SAVE_PROVIDER_RES,
  PROVIDER_USERNAME_VALIDATION,
  PROVIDER_USERNAME_VALIDATION_RES,
  GET_PROVIDER_BY_ID,
  GET_PROVIDER_BY_ID_RES,
  CANCEL_STATUS_SAVE_NEW_PROVIDER,
  GET_ALL_LOCATION,
  GET_ALL_LOCATION_RES,
  SAVE_LOCATION,
  SAVE_LOCATION_RES,
  UPDATE_LOCATION_STATUS,
  UPDATE_LOCATION_STATUS_RES,
  LOCATION_ZIP_CALL,
  LOCATION_ZIP_CALL_RES,
  CANCEL_STATUS_SAVE_NEW_LOCATION,
  SAVE_APT_TYPE,
  SAVE_APT_TYPE_RES,
  GET_APT_TYPE,
  GET_APT_TYPE_RES,
  UPDATE_APPOINTKMENT_STATUS,
  UPDATE_APPOINTKMENT_STATUS_RES,
  CANCEL_STATUS_SAVE_NEW_APT_TYPE,
  GET_MESSAGE_CONFIGS,
  GET_MESSAGE_CONFIGS_RESPONSE,
  GET_MESSAGE_CONFIG_BY_ID,
  GET_MESSAGE_CONFIG_BY_ID_RESPONSE,
  GET_MESSAGE_CONFIG_DEFAULTS,
  GET_MESSAGE_CONFIG_DEFAULTS_RESPONSE,
  SAVE_MESSAGE_CONFIG,
  SAVE_MESSAGE_CONFIG_RESPONSE,
  UPDATE_MESSAGE_CONFIG,
  UPDATE_MESSAGE_CONFIG_RESPONSE,
  GET_MESSAGE_RESPONSE,
  GET_MESSAGE_RESP_RESPONSE,
  GET_MESSAGE_RESPONSE_BY_ID,
  GET_MESSAGE_RESP_BY_ID_RESPONSE,
  GET_MESSAGE_RESPONSE_DEFAULTS,
  GET_MESSAGE_RESPONSE_DEFAULTS_RESPONSE,
  SAVE_MESSAGE_RESPONSE,
  SAVE_MESSAGE_RESP_RESPONSE,
  UPDATE_MESSAGE_RESPONSE,
  UPDATE_MESSAGE_RESPONSE_RESPONSE,
  GET_MESSAGE_SCHEDULE,
  GET_MESSAGE_SCHEDULE_RESPONSE,
  GET_MESSAGE_SCHEDULE_DEFAULTS,
  GET_MESSAGE_SCHEDULE_DEFAULTS_RESPONSE,
  SAVE_MESSAGE_SCHEDULE,
  SAVE_MESSAGE_SCHEDULE_RESPONSE,
  UPDATE_MESSAGE_SCHEDULE,
  UPDATE_MESSAGE_SCHEDULE_RESPONSE,
  GET_MESSAGE_SCHEDULE_BY_CLINIC_ID,
  GET_MESSAGE_SCHEDULE__BY_CLINIC_ID_RESPONSE,
  MESSAGE_CONFIG_STATUS,
  MESSAGE_CONFIG_STATUS_RESPONSE,
  MESSAGE_CONFIG_CHANNEL_ENABLED,
  MESSAGE_CONFIG_CHANNEL_ENABLED_RESPONSE,
  GET_MESSAGE_TYPES,
  GET_MESSAGE_TYPES_RESPONSE,
  GET_RESCHEDULE_SETTINGS,
  GET_RESCHEDULE_SETTINGS_RESPONSE,
  SAVE_RESCHEDULE_SETTINGS,
  SAVE_RESCHEDULE_SETTINGS_RESPONSE,
  GET_PROVIDER_LOCATION_LIST,
  GET_PROVIDER_LOCATION_LIST_RESPONSE,
  SAVE_PROVIDER_LOCATION,
  SAVE_PROVIDER_LOCATION_RESPONSE,
  GET_PROVIDER_LOCATION_BY_ID,
  GET_PROVIDER_LOCATION_BY_ID_RESPONSE,
  SPECIALITY,
  SPECIALITY_RES,
  GET_PLANS_DETAILS,
  GET_PLANS_DETAILS_RESPONSE,
  GET_BILLING_DETAILS,
  GET_BILLING_DETAILS_RESPONSE,
  CREATE_PAYMENT,
  CREATE_PAYMENT_RESPONSE,
  UPDATE_CARD,
  UPDATE_CARD_RESPONSE,
  GET_PLACE_ID,
  GET_PLACE_ID_RESPONSE,
  SAVE_PLACE_ID,
  SAVE_PLACE_ID_RESPONSE,
  GET_CHECKIN_CONFIG,
  GET_CHECKIN_CONFIG_RESPONSE,
  SAVE_CHECKIN_CONFIG,
  SAVE_CHECKIN_CONFIG_RESPONSE,
  GET_PAYORS_LIST,
  GET_PAYORS_LIST_RES,
  EFT_UPDATE_STATUS,
  EFT_UPDATE_STATUS_RES,
  GET_ELIGIBILITY_CONFIG,
  GET_ELIGIBILITY_CONFIG_RESPONSE,
  SAVE_ELIGILIBITY_CONFIG,
  SAVE_ELIGILIBITY_CONFIG_RESPONSE
} from './admin.types'

export const getClinicData = (params) => ({
  type: GET_CLINIC_DATA,
  payload: params,
})

export const getClinicDataResponse = (response) => ({
  type: GET_CLINIC_DATA_RES,
  payload: response,
})

export const saveClinicData = (params) => ({
  type: SAVE_CLINIC_DATA,
  payload: params,
})

export const saveClinicDataResponse = (response) => ({
  type: SAVE_CLINIC_DATA_RES,
  payload: response,
})
export const saveAdminData = (params) => ({
  type: SAVE_ADMIN_DATA,
  payload: params,
})

export const saveAdminDataResponse = (response) => ({
  type: SAVE_ADMIN_DATA_RES,
  payload: response,
})
export const getAllUser = (params) => ({
  type: GET_ALL_USER,
  payload: params,
})

export const getAllUserRes = (response) => ({
  type: GET_ALL_USER_RES,
  payload: response,
})

export const updateUserStatus = (params) => ({
  type: UPDATE_USER_STATUS,
  payload: params,
})

export const updateUserStatusRes = (response) => ({
  type: UPDATE_USER_STATUS_RES,
  payload: response,
})

export const clinicZipCall = (params) => ({
  type: CLINIC_ZIP_CALL,
  payload: params,
})

export const clinicZipCallRes = (response) => ({
  type: CLINIC_ZIP_CALL_RES,
  payload: response,
})
export const saveUser = (params) => ({
  type: SAVE_USER,
  payload: params,
})

export const saveUserRes = (response) => ({
  type: SAVE_USER_RES,
  payload: response,
})
export const updateUser = (params) => ({
  type: UPDATE_USER,
  payload: params,
})
export const updateUserRes = (params) => ({
  type: UPDATE_USER_RES,
  payload: params,
})
export const userNameValidation = (params) => ({
  type: USERNAME_VALIDATION,
  payload: params,
})

export const userNameValidationRes = (response) => ({
  type: USERNAME_VALIDATION_RES,
  payload: response,
})

export const getAllProvider = (params) => ({
  type: GET_ALL_PROVIDER,
  payload: params,
})

export const getAllProviderRes = (response) => ({
  type: GET_ALL_PROVIDER_RES,
  payload: response,
})

export const getProviderById = (params) => ({
  type: GET_PROVIDER_BY_ID,
  payload: params,
})

export const getProviderByIdRes = (response) => ({
  type: GET_PROVIDER_BY_ID_RES,
  payload: response,
})

export const updateProviderStatus = (params) => ({
  type: UPDATE_PROVIDER_STATUS,
  payload: params,
})

export const updateProviderStatusRes = (response) => ({
  type: UPDATE_PROVIDER_STATUS_RES,
  payload: response,
})

export const saveProvider = (params) => ({
  type: SAVE_PROVIDER,
  payload: params,
})

export const saveProviderRes = (response) => ({
  type: SAVE_PROVIDER_RES,
  payload: response,
})
export const providerUserNameValidation = (params) => ({
  type: PROVIDER_USERNAME_VALIDATION,
  payload: params,
})

export const providerUserNameValidationRes = (response) => ({
  type: PROVIDER_USERNAME_VALIDATION_RES,
  payload: response,
})
export const cancelStatuForNewPro = (params) => ({
  type: CANCEL_STATUS_SAVE_NEW_PROVIDER,
  payload: params,
})

// Location//
export const getAllLocation = (response) => ({
  type: GET_ALL_LOCATION,
  payload: response,
})
export const getAllLocationRes = (params) => ({
  type: GET_ALL_LOCATION_RES,
  payload: params,
})
export const saveLocation = (params) => ({
  type: SAVE_LOCATION,
  payload: params,
})
export const saveLocationRes = (params) => ({
  type: SAVE_LOCATION_RES,
  payload: params,
})
export const updateLocationStatus = (params) => ({
  type: UPDATE_LOCATION_STATUS,
  payload: params,
})
export const updateLocationStatusRes = (params) => ({
  type: UPDATE_LOCATION_STATUS_RES,
  payload: params,
})
export const locationZipCall = (params) => ({
  type: LOCATION_ZIP_CALL,
  payload: params,
})
export const locationZipCallRes = (params) => ({
  type: LOCATION_ZIP_CALL_RES,
  payload: params,
})
export const cancelStatuForNewLoc = (params) => ({
  type: CANCEL_STATUS_SAVE_NEW_LOCATION,
  payload: params,
})

export const SaveApointmentType = (params) => ({
  type: SAVE_APT_TYPE,
  payload: params,
})
export const SaveApointmentTypeRes = (params) => ({
  type: SAVE_APT_TYPE_RES,
  payload: params,
})

export const GetAppointmentType = (params) => ({
  type: GET_APT_TYPE,
  payload: params,
})

export const GetAppointmentTypeRes = (params) => ({
  type: GET_APT_TYPE_RES,
  payload: params,
})

export const updateAppointmentStatus = (params) => ({
  type: UPDATE_APPOINTKMENT_STATUS,
  payload: params,
})

export const updateAppointmentStatusRes = (params) => ({
  type: UPDATE_APPOINTKMENT_STATUS_RES,
  payload: params,
})

export const cancelStatuForNewApptType = (params) => ({
  type: CANCEL_STATUS_SAVE_NEW_APT_TYPE,
  payload: params,
})

// Messaging

export const getMessageConfigs = (params) => ({
  type: GET_MESSAGE_CONFIGS,
  payload: params,
})

export const getMessageConfigsResponse = (response) => ({
  type: GET_MESSAGE_CONFIGS_RESPONSE,
  payload: response,
})

export const getMessageConfigById = (params) => ({
  type: GET_MESSAGE_CONFIG_BY_ID,
  payload: params,
})

export const getMessageConfigByIdResponse = (response) => ({
  type: GET_MESSAGE_CONFIG_BY_ID_RESPONSE,
  payload: response,
})

export const getMessageConfigDefaults = (params) => ({
  type: GET_MESSAGE_CONFIG_DEFAULTS,
  payload: params,
})

export const getMessageConfigDefaultsResponse = (response) => ({
  type: GET_MESSAGE_CONFIG_DEFAULTS_RESPONSE,
  payload: response,
})

export const saveMessageConfig = (params) => ({
  type: SAVE_MESSAGE_CONFIG,
  payload: params,
})

export const saveMessageConfigResponse = (response) => ({
  type: SAVE_MESSAGE_CONFIG_RESPONSE,
  payload: response,
})

export const updateMessageConfig = (params) => ({
  type: UPDATE_MESSAGE_CONFIG,
  payload: params,
})

export const updateMessageConfigResponse = (response) => ({
  type: UPDATE_MESSAGE_CONFIG_RESPONSE,
  payload: response,
})

export const getMessageResponse = (params) => ({
  type: GET_MESSAGE_RESPONSE,
  payload: params,
})

export const getMessageRespResponse = (response) => ({
  type: GET_MESSAGE_RESP_RESPONSE,
  payload: response,
})

export const getMessageResponseById = (params) => ({
  type: GET_MESSAGE_RESPONSE_BY_ID,
  payload: params,
})

export const getMessageRespByIdResponse = (response) => ({
  type: GET_MESSAGE_RESP_BY_ID_RESPONSE,
  payload: response,
})

export const getMessageResponseDefaults = (params) => ({
  type: GET_MESSAGE_RESPONSE_DEFAULTS,
  payload: params,
})

export const getMessageResponseDefaultsResponse = (response) => ({
  type: GET_MESSAGE_RESPONSE_DEFAULTS_RESPONSE,
  payload: response,
})

export const saveMessageResponse = (params) => ({
  type: SAVE_MESSAGE_RESPONSE,
  payload: params,
})

export const saveMessageRespResponse = (response) => ({
  type: SAVE_MESSAGE_RESP_RESPONSE,
  payload: response,
})

export const updateMessageResponse = (params) => ({
  type: UPDATE_MESSAGE_RESPONSE,
  payload: params,
})

export const updateMessageRespResponse = (response) => ({
  type: UPDATE_MESSAGE_RESPONSE_RESPONSE,
  payload: response,
})

export const getMessageSchedule = (params) => ({
  type: GET_MESSAGE_SCHEDULE,
  payload: params,
})

export const getMessageScheduleResponse = (response) => ({
  type: GET_MESSAGE_SCHEDULE_RESPONSE,
  payload: response,
})

export const getMessageScheduleDefaults = (params) => ({
  type: GET_MESSAGE_SCHEDULE_DEFAULTS,
  payload: params,
})

export const getMessageScheduleDefaultsResponse = (response) => ({
  type: GET_MESSAGE_SCHEDULE_DEFAULTS_RESPONSE,
  payload: response,
})

export const saveMessageSchedule = (params) => ({
  type: SAVE_MESSAGE_SCHEDULE,
  payload: params,
})

export const saveMessageScheduleResponse = (response) => ({
  type: SAVE_MESSAGE_SCHEDULE_RESPONSE,
  payload: response,
})

export const updateMessageSchedule = (params) => ({
  type: UPDATE_MESSAGE_SCHEDULE,
  payload: params,
})

export const updateMessageScheduleResponse = (response) => ({
  type: UPDATE_MESSAGE_SCHEDULE_RESPONSE,
  payload: response,
})

export const getMessageScheduleByClinicId = (params) => ({
  type: GET_MESSAGE_SCHEDULE_BY_CLINIC_ID,
  payload: params,
})

export const getMessageScheduleByClinicIdResponse = (response) => ({
  type: GET_MESSAGE_SCHEDULE__BY_CLINIC_ID_RESPONSE,
  payload: response,
})

export const updateMessageConfigStatus = (params) => ({
  type: MESSAGE_CONFIG_STATUS,
  payload: params,
})
export const updateMessageConfigStatusRes = (params) => ({
  type: MESSAGE_CONFIG_STATUS_RESPONSE,
  payload: params,
})

export const updateMessageConfigChannel = (params) => ({
  type: MESSAGE_CONFIG_CHANNEL_ENABLED,
  payload: params,
})
export const updateMessageConfigChannelRes = (params) => ({
  type: MESSAGE_CONFIG_CHANNEL_ENABLED_RESPONSE,
  payload: params,
})

export const getMessageTypes = (params) => ({
  type: GET_MESSAGE_TYPES,
  payload: params,
})

export const getMessageTypesResponse = (response) => ({
  type: GET_MESSAGE_TYPES_RESPONSE,
  payload: response,
})

export const getRescheduleSettings = (params) => ({
  type: GET_RESCHEDULE_SETTINGS,
  payload: params,
})

export const getRescheduleSettingsResponse = (response) => ({
  type: GET_RESCHEDULE_SETTINGS_RESPONSE,
  payload: response,
})

export const saveRescheduleSettings = (params) => ({
  type: SAVE_RESCHEDULE_SETTINGS,
  payload: params,
})

export const saveRescheduleSettingsResponse = (response) => ({
  type: SAVE_RESCHEDULE_SETTINGS_RESPONSE,
  payload: response,
})

export const getProviderLocation = (params) => ({
  type: GET_PROVIDER_LOCATION_LIST,
  payload: params,
})

export const getProviderLocationResponse = (response) => ({
  type: GET_PROVIDER_LOCATION_LIST_RESPONSE,
  payload: response,
})

export const saveProviderLocation = (params) => ({
  type: SAVE_PROVIDER_LOCATION,
  payload: params,
})

export const saveProviderLocationResponse = (response) => ({
  type: SAVE_PROVIDER_LOCATION_RESPONSE,
  payload: response,
})

export const getProviderLocationById = (params) => ({
  type: GET_PROVIDER_LOCATION_BY_ID,
  payload: params,
})

export const getProviderLocationByIdResponse = (response) => ({
  type: GET_PROVIDER_LOCATION_BY_ID_RESPONSE,
  payload: response,
})

export const getSpeciality = (params) => ({
  type: SPECIALITY,
  payload: params,
})

export const specialityResponse = (response) => ({
  type: SPECIALITY_RES,
  payload: response,
})

export const getPlansDetails = (data) => ({
  type: GET_PLANS_DETAILS,
  payload: data,
})

export const getPlansDetailsResponse = (response) => ({
  type: GET_PLANS_DETAILS_RESPONSE,
  payload: response,
})

export const getBillingDetails = (data) => ({
  type: GET_BILLING_DETAILS,
  payload: data,
})

export const getBillingDetailsResponse = (response) => ({
  type: GET_BILLING_DETAILS_RESPONSE,
  payload: response,
})

export const makePayment = (data) => ({
  type: CREATE_PAYMENT,
  payload: data,
})

export const makePaymentResponse = (response) => ({
  type: CREATE_PAYMENT_RESPONSE,
  payload: response,
})

export const getPlaceId = (data) => ({
  type: GET_PLACE_ID,
  payload: data,
})

export const getPlaceIdResponse = (response) => ({
  type: GET_PLACE_ID_RESPONSE,
  payload: response,
})

export const savePlaceId = (data) => ({
  type: SAVE_PLACE_ID,
  payload: data,
})

export const savePlaceIdResponse = (response) => ({
  type: SAVE_PLACE_ID_RESPONSE,
  payload: response,
})

export const updateCard = (params) => ({
  type: UPDATE_CARD,
  payload: params,
})
export const updateCardResponse = (response) => ({
  type: UPDATE_CARD_RESPONSE,
  payload: response,
})

//settings
export const getCheckinConfig = (data) => ({
  type: GET_CHECKIN_CONFIG,
  payload: data,
})

export const getCheckinConfigResponse = (response) => ({
  type: GET_CHECKIN_CONFIG_RESPONSE,
  payload: response,
})

export const saveCheckinConfig = (data) => ({
  type: SAVE_CHECKIN_CONFIG,
  payload: data,
})

export const saveCheckinConfigResponse = (response) => ({
  type: SAVE_CHECKIN_CONFIG_RESPONSE,
  payload: response,
})

export const getEligibilityConfig = (data) => ({
  type: GET_ELIGIBILITY_CONFIG,
  payload: data,
})

export const getEligibilityConfigResponse = (response) => ({
  type: GET_ELIGIBILITY_CONFIG_RESPONSE,
  payload: response,
})

export const saveEligibilityConfig = (data) => ({
  type: SAVE_ELIGILIBITY_CONFIG,
  payload: data,
})

export const saveEligibilityConfigResponse = (response) => ({
  type: SAVE_ELIGILIBITY_CONFIG_RESPONSE,
  payload: response,
})

//payors list
export const getPayorsList = (data) => ({
  type: GET_PAYORS_LIST,
  payload: data,
});

export const getPayorsListRes = (data) => ({
  type: GET_PAYORS_LIST_RES,
  payload: data,
});
export const eftUpdatedStatus = (data) => ({
  type: EFT_UPDATE_STATUS,
  payload: data,
});
export const eftUpdatedStatusRes = (data) => ({
  type: EFT_UPDATE_STATUS_RES,
  payload: data,
});