export const LOGIN = 'LOGIN'
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_RES = 'LOG_OUT_RES'
export const GET_PATIENT_LIST = 'GET_PATIENT_LIST'
export const GET_PATIENT_LIST_RES = 'GET_PATIENT_LIST_RES'
export const VIDEO_TOKEN = 'VIDEO_TOKEN'
export const VIDEO_TOKEN_RES = 'VIDEO_TOKEN_RES'
export const DELETE_QUEUE = 'DELETE_QUEUE'
export const DELETE_QUEUE_RES = 'DELETE_QUEUE_RES'
export const VIDEO_MAIL = 'VIDEO_MAIL'
export const VIDEO_MAIL_RES = 'VIDEO_MAIL_RES'
export const PATIENT_TILE = 'PATIENT_TILE'
export const PATIENT_TILE_RES = 'PATIENT_TILE_RES'
