import {
  LOGIN,
  LOGIN_RESPONSE,
  LOG_OUT,
  LOG_OUT_RES,
  GET_PATIENT_LIST,
  GET_PATIENT_LIST_RES,
  VIDEO_TOKEN,
  VIDEO_TOKEN_RES,
  DELETE_QUEUE,
  DELETE_QUEUE_RES,
  VIDEO_MAIL,
  VIDEO_MAIL_RES,
  PATIENT_TILE,
  PATIENT_TILE_RES,
} from './video.types'

export const getVideoToken = (params) => ({
  type: VIDEO_TOKEN,
  payload: params,
})
export const getVideoTokenRes = (params) => ({
  type: VIDEO_TOKEN_RES,
  payload: params,
})
export const loginApi = (params) => ({
  type: LOGIN,
  payload: params,
})

export const loginResponse = (token) => ({
  type: LOGIN_RESPONSE,
  payload: token,
})

export const getPatientList = (params) => ({
  type: GET_PATIENT_LIST,
  payload: params,
})

export const getPatientListResponse = (params) => ({
  type: GET_PATIENT_LIST_RES,
  payload: params,
})

export const logOutApi = () => ({
  type: LOG_OUT,
})

export const logOutResponse = (res) => ({
  type: LOG_OUT_RES,
  payload: res,
})
export const deleteQueue = (id) => ({
  type: DELETE_QUEUE,
  payload: id,
})
export const deleteQueueRes = (res) => ({
  type: DELETE_QUEUE_RES,
  payload: res,
})
export const videomail = (appid) => ({
  type: VIDEO_MAIL,
  payload: appid,
})
export const videomailRes = (res) => ({
  type: VIDEO_MAIL_RES,
  payload: res,
})

export const getPatientTile = (appId) => ({
  type: PATIENT_TILE,
  payload: appId,
})
export const getPatientTileRes = (res) => ({
  type: PATIENT_TILE_RES,
  payload: res,
})
