import toast from 'react-hot-toast'
import {
  CREATE_APPOINTMENT_RESPONSE,
  GET_CAL_LIST_RESPONSE,
  GET_PATIENT_BY_ID_RESPONSE,
  SEARCH_PATIENT_RESPONSE,
  API_STATUS,
  GET_PATIENT_BY_API_ID_RESPONSE,
  UPDATE_APT_DETAILS_RESPONSE,
  GET_SEARCH_PATIENT_BY_APT_ID_RESPONSE,
  IS_CHECKIN_STATUS,
  SHOW_ACCORDION,
  ZIP_SEARCH_RES,
  ZIP_SEARCH_PAYER_ADDRESS_RES,
  ZIP_SAVE_POLICY_HOLDER_RES,
  ZIP_SEARCH_ACCORDIAN_ADDRESS_RES,
  DRAG_APPOINTMENT_RESPONSE,
  APPOINTMENT_DATA_RESPONSE,
  DELETE_APPOINTMENT_RES,
  IS_ACCORDION_OPEN,
  SCREEN_WIDTH,
  PUSH_POLICYHOLDER,
  EDIT_VIEW_STATUS,
  GET_PATIENT_BY_ID_DIALOG_RESPONSE,
  CLEAR_APPOINTMENT,
  SAVE_CURRENT_PATIENT_ID,
  CANCEL_APPOINTMENT_RES,
  CANCEL_POPOVER_STATUS,
  APOINTMENT_INFO_INSURANCE_RES,
  TRIGGER_APPT_REMINDER_REVIEW_RES,
} from './calender.types'
/**
 * @fileOverview Manages the response of action
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  clicked: '',
  newAptData: {}, // data of create appointment api
  calanderList: {}, // appointment list from getCalenderlist api
  searchData: {}, // search patient data
  apistatus: false, // boolean status for reloading the getCalendar List API
  getPatientData: {}, // get the patient details a/c search patient id
  updateAptDetailsResponse: {}, // response of updated appointment in accordian
  getSearchPatientByApt: {}, // get patient details with  appointment id
  isCheckin: '', // boolean value to pass the open checkin section when click on fll calendar
  show: false, // boolean value to open accordian when click an event in full calendar
  getAddressFromZipCodeData: {}, // get the list of city, state and country a/c zip code in create appointment popup
  getAddressFromPayerAddressZipData: {}, // get the list of city, state and country a/c zip code in payer address
  getAddressFromAccordianZipData: {}, // get the list of city, state and country a/c zip code in accordian
  savePolicyHolderZip: {}, // get the list of city, state and country a/c zip code in policy holder
  dragAppointmentResponse: {}, // to get the appointment data while drag the event in full calendar
  appointment: {}, // response of api to get appointment data while drag the event in full calendar
  deleteResponse: {}, // to delete appointment response
  accordionStatus: null,
  getPatientDataByAptId: {},
  getApointmentdetialsInsurance: {},
  policyHolderList: [],
  screenWidthValue: '',
  editViewStatus: false,
  getPatientDataDialog: {},
  savedIdOfCurrentPatient: null,
  appointmentCancel: null,
  cancelPopover: false,
  triggerResponse: {},
  sequence:null,
}

/**
 *
 * @param state global state management
 * @param action - contains type and payload
 * @return {{apistatus: boolean, dragAppointmentResponse: {}, patientResponse: {}, show: boolean, getAddressFromZipCodeData: {}, getAddressFromPayerAddressZipData: {}, appointment: {}, newFullcalendarView: {}, updateAptDetailsResponse: {}, calanderList: {}, clicked: boolean, searchData: {}, getAddressFromAccordianZipData: {}, calenderList: *, isCheckin: boolean, cancelCheckinPatient: boolean, getPatientData: {}, savePolicyHolderZip: {}, clickedDate: {}, newAptData: {}, getSearchPatientByApt: {}, jumpedDate: null}}
 */
const calenderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_APPOINTMENT_RESPONSE: {
      return {
        ...state,
        ...{ clicked: true, newAptData: action.payload },
      }
    }
    case GET_CAL_LIST_RESPONSE: {
      return { ...state, ...{ clicked: true, calenderList: action.payload } }
    }

    case SEARCH_PATIENT_RESPONSE: {
      const { data, sequence } = action.payload
      return {
        ...state,
        clicked: true,
        searchData: data, 
        sequence: sequence, 
      }
    }
    case API_STATUS: {
      return { ...state, ...{ apistatus: action.payload } }
    }
    case GET_PATIENT_BY_ID_RESPONSE: {
      return { ...state, ...{ getPatientData: action.payload } }
    }
    case GET_PATIENT_BY_ID_DIALOG_RESPONSE: {
      return { ...state, ...{ getPatientDataDialog: action.payload } }
    }
    case GET_PATIENT_BY_API_ID_RESPONSE: {
      return {
        ...state,
        ...{
          getPatientDataByAptId: action.payload,
          getApointmentdetialsInsurance: action.payload,
          ...{
            policyHolderList:
              action.payload.response && action.payload.response.data
                ? action.payload.response.data.policyHolderDtoList
                : '',
          },
        },
      }
    }
    case APOINTMENT_INFO_INSURANCE_RES: {
      return {
        ...state,
        ...{
          getApointmentdetialsInsurance: action.payload,
          ...{
            policyHolderList:
              action.payload.response && action.payload.response.data
                ? action.payload.response.data.policyHolderDtoList
                : '',
          },
        },
      }
    }
    case UPDATE_APT_DETAILS_RESPONSE: {
      return { ...state, ...{ updateAptDetailsResponse: action.payload } }
    }
    case GET_SEARCH_PATIENT_BY_APT_ID_RESPONSE: {
      return { ...state, ...{ getSearchPatientByApt: action.payload } }
    }
    case IS_CHECKIN_STATUS: {
      return {
        ...state,
        ...{ isCheckin: action.payload },
      }
    }
    case SHOW_ACCORDION: {
      return {
        ...state,
        ...{ show: action.payload },
      }
    }
    case ZIP_SEARCH_RES: {
      return {
        ...state,
        ...{ getAddressFromZipCodeData: action.payload },
      }
    }
    case ZIP_SEARCH_PAYER_ADDRESS_RES: {
      return {
        ...state,
        ...{ getAddressFromPayerAddressZipData: action.payload },
      }
    }
    case ZIP_SEARCH_ACCORDIAN_ADDRESS_RES: {
      return {
        ...state,
        ...{ getAddressFromAccordianZipData: action.payload },
      }
    }

    case ZIP_SAVE_POLICY_HOLDER_RES: {
      return {
        ...state,
        ...{ savePolicyHolderZip: action.payload },
      }
    }
    case DRAG_APPOINTMENT_RESPONSE: {
      return {
        ...state,
        ...{ dragAppointmentResponse: action.payload },
      }
    }

    case APPOINTMENT_DATA_RESPONSE: {
      return {
        ...state,
        ...{ appointment: action.payload },
      }
    }
    case DELETE_APPOINTMENT_RES: {
      return { ...state, ...{ deleteResponse: action.payload } }
    }
    case IS_ACCORDION_OPEN: {
      return { ...state, ...{ accordionStatus: action.payload } }
    }
    case SCREEN_WIDTH: {
      return { ...state, ...{ screenWidthValue: action.payload } }
    }
    case PUSH_POLICYHOLDER: {
      return { ...state, ...{ policyHolderList: action.payload } }
    }
    case EDIT_VIEW_STATUS: {
      return { ...state, ...{ editViewStatus: action.payload } }
    }
    case CLEAR_APPOINTMENT: {
      return { ...state, ...{ getPatientDataByAptId: action.payload } }
    }
    case SAVE_CURRENT_PATIENT_ID: {
      return { ...state, ...{ savedIdOfCurrentPatient: action.payload } }
    }
    case CANCEL_APPOINTMENT_RES: {
      return { ...state, ...{ appointmentCancel: action.payload } }
    }
    case CANCEL_POPOVER_STATUS: {
      return { ...state, ...{ cancelPopover: action.payload } }
    }
    case TRIGGER_APPT_REMINDER_REVIEW_RES: {
      if (action.payload.response.data) {
        if (action.payload.response.data.messageConfigtype === 6) {
          return { ...state, ...{ triggerResponse: action.payload } }
        } else if (action.payload.response.data.messageConfigtype === 8) {
          return { ...state, ...{ triggerResponse: action.payload } }
        }
      } else if (action.payload.response.responseCode === 158) {
        return state // Just return state, handle the toast outside
      } else if (action.payload.response.responseCode === 161) {
        return state // Just return state, handle the toast outside
      }
      break
    }
    default:
      return state
  }
}

export default calenderReducer

