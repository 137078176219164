export const ZIPCODE_FOR_DEMOGRAPHICS = 'ZIPCODE_FOR_DEMOGRAPHICS'
export const REQUEST_CANCEL = 'REQUEST_CANCEL' // cancel the API request
export const ZIPCODE_FOR_DEMOGRAPHICS_RES = 'ZIPCODE_FOR_DEMOGRAPHICS_RES'
export const GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS =
  'GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS' // delete the payment by id RES
export const GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS_RES =
  'GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS_RES' // delete the payment by id RES
export const EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS =
  'EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS' // delete the payment by id RES
export const EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS_RES =
  'EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS_RES' // delete the payment by id RES
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNTS_RES = 'GET_ACCOUNTS_RES'
export const GET_PIN = 'GET_PIN'
export const GET_PIN_RES = 'GET_PIN_RES'
export const CLAIM_LIST = 'CLAIM_LIST'
export const CLAIM_LIST_RES = 'CLAIM_LIST_RES'
export const CLAIM_DOWNLOAD = 'CLAIM_DOWNLOAD'
export const SAVE_PAYMENTS = 'SAVE_PAYMENTS'
export const SAVE_PAYMENTS_RES = 'SAVE_PAYMENTS_RES'
export const PAYMENT_LIST = 'PAYMENT_LIST'
export const PAYMENT_LIST_RES = 'PAYMENT_LIST_RES'
export const CLAIM_DOWNLOAD_RES = 'CLAIM_DOWNLOAD_RES'
export const WRITEOFF = 'WRITEOFF'
export const WRITEOFF_RES = 'WRITEOFF_RES'
export const BALANCE_LIST = 'BALANCE_LIST'
export const BALANCE_LIST_RES = 'BALANCE_LIST_RES'
export const STATEMENT_LIST = 'STATEMENT_LIST'
export const STATEMENT_LIST_RES = 'STATEMENT_LIST_RES'
export const STATEMENT_DETAILS = 'STATEMENT_DETAILS'
export const STATEMENT_DETAILS_RES = 'STATEMENT_DETAILS_RES'
export const AUTHORIZATIONS = 'AUTHORIZATIONS'
export const AUTHORIZATIONS_RES = 'AUTHORIZATIONS_RES'
export const PAYMENT_DELETE = 'PAYMENT_DELETE'
export const PAYMENT_DELETE_RES = 'PAYMENT_DELETE_RES'
export const SEARCH_PAYER_INSURANCE_TAB = 'SEARCH_PAYER_INSURANCE_TAB' // delete the payment by id RES
export const SEARCH_PAYER_INSURANCE_TAB_RES = 'SEARCH_PAYER_INSURANCE_TAB_RES' // delete the payment by id RES
export const SEARCH_PAYER_ADDRESS_INSURANCE_TAB =
  'SEARCH_PAYER_ADDRESS_INSURANCE_TAB' // delete the payment by id RES
export const SEARCH_PAYER_ADDRESS_INSURANCE_TAB_RES =
  'SEARCH_PAYER_ADDRESS_INSURANCE_TAB_RES' // delete the payment by id RES
export const CLEAR_HEADER = 'CLEAR_HEADER' // delete the payment by id RES
export const GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS =
  'GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS' // delete the payment by id RES
export const GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS_RES =
  'GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS_RES' // delete the payment by id RES

export const GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB =
  'GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB'

export const GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB_RES =
  'GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB_RES'

export const EDIT_SAVE_INSURANCE_TAB = 'EDIT_SAVE_INSURANCE_TAB'

export const EDIT_SAVE_INSURANCE_TAB_RES = 'EDIT_SAVE_INSURANCE_TAB_RES'

export const STATUS_CHANGE_OF_INSURNACE = 'STATUS_CHANGE_OF_INSURNACE'

export const STATUS_CHANGE_OF_INSURNACE_RES = 'STATUS_CHANGE_OF_INSURNACE_RES'
export const CURRENT_TAB_VALUE = 'CURRENT_TAB_VALUE'
export const GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID =
  'GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID'
export const GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES =
  'GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES'
export const SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID =
  'SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID'
export const SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES =
  'SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID_RES'

export const GET_NOTES_FOR_APPOINTMENT = 'GET_NOTES_FOR_APPOINTMENT'
export const GET_NOTES_FOR_APPOINTMENT_RES = 'GET_NOTES_FOR_APPOINTMENT_RES'
export const SAVE_NOTES_FOR_APPOINTMENT = 'SAVE_NOTES_FOR_APPOINTMENT'
export const SAVE_NOTES_FOR_APPOINTMENT_RES = 'SAVE_NOTES_FOR_APPOINTMENT_RES'
export const GET_APPOINTMENT_LIST_ACCOUNTS = 'GET_APPOINTMENT_LIST_ACCOUNTS'
export const GET_APPOINTMENT_LIST_RES_ACCOUNTS =
  'GET_APPOINTMENT_LIST_RES_ACCOUNTS'
export const CANCEL_APPOINTMENT_LIST_ACCOUNTS =
  'CANCEL_APPOINTMENT_LIST_ACCOUNTS'
export const CANCEL_APPOINTMENT_LIST_RES_ACCOUNTS =
  'CANCEL_APPOINTMENT_LIST_RES_ACCOUNTS'
export const STRIPE_PAYMENT = 'STRIPE_PAYMENT'
export const STRIPE_PAYMENT_RES = 'STRIPE_PAYMENT_RES'

// claim notes
export const GET_CLAIM_NOTES = 'GET_CLAIM_NOTES'
export const GET_CLAIM_NOTES_RES = 'GET_CLAIM_NOTES_RES'