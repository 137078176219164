import environment from 'environments/environment'
import axios from 'axios'

const { newChatServer } = environment
  const accessToken = localStorage.getItem('access_token')
const baseUrl = `${newChatServer}/api/v1/chat-api/`
export const chatService = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'application-id': '2',
  },
})
