import { ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
// import { ajax } from "rxjs/ajax";
import { ajax } from 'Utils'
import { ajax as UtilsAjax } from 'Utils'
import { objectToUrl } from 'Utils/ajax-utils'
// import environment from "../../environments/environment";
import environment from '../../../environments/environment'
import {
  APT_SAVE,
  EXPORT_DATA,
  PAYER_SAVE,
  PROVIDER_LIST,
  PROVIDER_SAVE,
  PA_SEARCH_PATIENT,
} from './apt.types'
import {
  AppointmentSaveResponse,
  exportDataResponse,
  payerSaveResponse,
  providerListResponse,
  providerSaveResponse,
  priorAuthSearchPatientResponse,
} from './apt.actions'
import { Apis } from 'Redux/APIs'

const { baseUrl } = environment
const { baseUrl2 } = environment
const AppointmentEpic = {}

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
AppointmentEpic.priorAuthSearchPatientApi = (action$) =>
  action$.pipe(
    ofType(PA_SEARCH_PATIENT),
    switchMap((action) =>
      ajax({
        url: objectToUrl(
          `${baseUrl}/trillium-clinic-service/v1/patient/search`,
          action.payload,
        ),
        // url: `${baseUrl}/${Apis.Priorauth_searchPatientApi}?firstName=${action.payload.firstName}&lastName=${action.payload.lastName}&clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => priorAuthSearchPatientResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentEpic.AppointmentSaveApi = (action$) =>
  action$.pipe(
    ofType(APT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_AppointmentSaveApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => AppointmentSaveResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AppointmentEpic.payerSaveApi = (action$) =>
  action$.pipe(
    ofType(PAYER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_payerSaveApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => payerSaveResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AppointmentEpic.providerSaveApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 4,
        },
        url: `${baseUrl}/${Apis.Priorauth_providerSaveApi}/${action.payload.appointmentId}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => providerSaveResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AppointmentEpic.providerListApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Priorauth_providerListApi}`,
          action.payload,
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => providerListResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

AppointmentEpic.exportDownloadApi = (action$) =>
  action$.pipe(
    ofType(EXPORT_DATA),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Priorauth_exportDownloadApi}`,
          action.payload,
        ),
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => exportDataResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default AppointmentEpic
