/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const LOGIN = 'LOGIN'
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const API_STATUS = 'API_STATUS'
export const CHAT = 'CHAT'
export const CHAT_RESPONSE = 'CHAT_RESPONSE'
export const GET_PATIENT_TICKER = 'GET_PATIENT_TICKER'
export const PATIENT_TICKER_RESPONSE = 'PATIENT_TICKER_RESPONSE'
export const USER_DATA = 'USER_DATA'
export const USER_DATA_RES = 'USER_DATA_RES'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_RES = 'LOGOUT_RES'
