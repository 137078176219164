import {
  GET_PAYMENTS_RES,
  UPDATE_STATUS_RES,
  UPDATE_POSTED_DATE_RES,
  EXPORT_PAYMENTS_RES,
  DOWNLOAD_PAYMENTS_RES,
  GET_EXPORT_STATUS_RES,
  UPDATE_DEPOSIT_DATE_RES,
  UPDATE_BATCH_NO_RES,
} from "./BankRecon.types";

const INITIAL_STATE = {
  paymentsRes: [],
  updatedStatusRes: {},
  updatedDateRes: {},
  exportResponse: {},
  downloadResponse: {},
  exportStatus: {},
  updatedDepositDateRes: {},
  updatedBatchRes: {},
};

const BankReconReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS_RES: {
      return { ...state, ...{ paymentsRes: action.payload } };
    }
    case UPDATE_STATUS_RES: {
      return { ...state, ...{ updatedStatusRes: action.payload } };
    }
    case UPDATE_POSTED_DATE_RES: {
      return { ...state, ...{ updatedDateRes: action.payload } };
    }
    case EXPORT_PAYMENTS_RES: {
      return { ...state, ...{ exportResponse: action.payload } };
    }
    case DOWNLOAD_PAYMENTS_RES: {
      return { ...state, ...{ downloadResponse: action.payload } };
    }
    case GET_EXPORT_STATUS_RES: {
      return { ...state, ...{ exportStatus: action.payload } };
    }
    case UPDATE_DEPOSIT_DATE_RES: {
      return { ...state, ...{ updatedDepositDateRes: action.payload } };
    }
    case UPDATE_BATCH_NO_RES: {
      return { ...state, ...{ updatedBatchRes: action.payload } };
    }
    default:
      return state;
  }
};

export default BankReconReducer;
