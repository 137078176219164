import {
  APT_SAVE_RES,
  CACHE_RES,
  EXPORT_DATA_RES,
  PAYER_SAVE_RES,
  PROVIDER_LIST_RES,
  PROVIDER_SAVE_RES,
  PA_SEARCH_PATIENT_RES,
} from './apt.types'
/**
 * @fileOverview Manages the response of action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  priorAuthSearchPatients: null,
  cache: null,
  appointmentResponse: null,
  payerSaveResponse: null,
  providerSaveResponse: null,
  providerListResponse: null,
  exportFile: null,
}

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{searchPatients: {}, login: boolean, status: boolean}|{searchPatients: *, login: boolean, status: boolean}|{searchPatients: {}, login: boolean, status: *}}
 */

const aptReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PA_SEARCH_PATIENT_RES: {
      return { ...state, ...{ priorAuthSearchPatients: action.payload } }
    }
    case CACHE_RES: {
      return { ...state, ...{ cache: action.payload } }
    }
    case APT_SAVE_RES: {
      return { ...state, ...{ appointmentResponse: action.payload } }
    }
    case PAYER_SAVE_RES: {
      return { ...state, ...{ payerSaveResponse: action.payload } }
    }
    case PROVIDER_SAVE_RES: {
      return { ...state, ...{ providerSaveResponse: action.payload } }
    }
    case PROVIDER_LIST_RES: {
      return { ...state, ...{ providerListResponse: action.payload } }
    }
    case EXPORT_DATA_RES: {
      return { ...state, ...{ exportFile: action.payload } }
    }

    default:
      return state
  }
}
export default aptReducer
