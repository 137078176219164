import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import environment from 'environments/environment.js'
import { Apis } from 'Redux/APIs'

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 params.pipe(
 ofType(TYPE_NAME),
 switchMap((action) =>
 ajax({
          url: 'API url',
          method: 'POST/GET',
          body: passing payload from action.js,
        }).pipe(
 map(
 (response) => Response action(response),
 takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
 ),
 ),
 ),
 )
 })
 */
import {
  SAVE_PAYMENT,
  EDIT_PAYMENT,
  REQUEST_CANCEL,
  GET_PAYMENT_APPTID,
  GET_PAYMENT_ID,
  DELETE_PAYMENT,
} from './payment.types'

import {
  savePaymentRes,
  editPaymentRes,
  getPaymentBYApptIdRes,
  getPaymentBYIdRes,
  deletePaymentRes,
} from './payment.actions'
const { baseUrl } = environment
const paymentEpic = {}

/**
 * @description For calling the save payment

 */
paymentEpic.savePaymentApi = (action$) =>
  action$.pipe(
    ofType(SAVE_PAYMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Payment_savePaymentApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePaymentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * @description For calling the save payment

 */
paymentEpic.editPaymentApi = (action$) =>
  action$.pipe(
    ofType(EDIT_PAYMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Payment_editPaymentApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => editPaymentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
paymentEpic.getPaymentByApptIdApi = (action$) =>
  action$.pipe(
    ofType(GET_PAYMENT_APPTID),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Payment_getPaymentByApptIdApi}/${action.payload}`,
        method: 'GET',
        //  body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getPaymentBYApptIdRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
paymentEpic.getPaymentByIdApi = (action$) =>
  action$.pipe(
    ofType(GET_PAYMENT_ID),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Payment_getPaymentByIdApi}/${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getPaymentBYIdRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
paymentEpic.deletePaymentApi = (action$) =>
  action$.pipe(
    ofType(DELETE_PAYMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Payment_deletePaymentApi}/${action.payload}`,
        method: 'DELETE',
      }).pipe(
        map(
          (response) => deletePaymentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default paymentEpic
