module.exports = {
    "baseUrl": "https://api-v4.primrose.health",
    "baseUrl2": "https://api.primrose.health",
    "dashboardUrl": "https://claimupload.primrose.health",
    "systemName": "System Prod",
    "chatServer": "https://staging.trillium.health:8082/chatWebsocket",
    "videoServer": "https://production.trillium.health:9090/schedularWebsocket",
    "prodStatus": false,
    "newChatServer": "https://api-v4.chat.primrose.health",
    "patientChatUrl": "https://m.trillium.health"
}