export const FILTER_ENDOFDAY_REPORT = 'FILTER_ENDOFDAY_REPORT'
export const ENDOFDAY_REPORT_RES = 'ENDOFDAY_REPORT_RES'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const FILTER_APPOINTMENTLIST_REPORT = 'FILTER_APPOINTMENTLIST_REPORT'
export const FILTER_APPOINTMENTLIST_REPORT_RES =
  'FILTER_APPOINTMENTLIST_REPORT_RES'
export const FILTER_COLLECTION_REPORT = 'FILTER_COLLECTION_REPORT'
export const FILTER_COLLECTION_REPORT_RES = 'FILTER_COLLECTION_REPORT_RES'
export const ALL_USERS = 'ALL_USERS'
export const ALL_USERS_RES = 'ALL_USERS_RES'
export const PRIOR_REPORT = 'PRIOR_REPORT'
export const PRIOR_REPORT_RES = 'PRIOR_REPORT_RES'
