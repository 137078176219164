import {
  CHAT_USER_LOGIN_REQUEST,
  CHAT_USER_LOGIN_SUCCESS,
  CHAT_USER_LOGIN_FAILURE,
} from './chat.types'

export const loginChatUserRequest = () => ({
  type: CHAT_USER_LOGIN_REQUEST,
})

export const loginChatUserSuccess = (userData) => ({
  type: CHAT_USER_LOGIN_SUCCESS,
  payload: userData,
})

export const loginChatUserFailure = (error) => ({
  type: CHAT_USER_LOGIN_FAILURE,
  payload: error,
})
