import {
  PRODUCTION_DETAILED_RES,
  CLAIM_LEVEL_RES,
  PROD_NOTES_RES,
  EXPORT_DETAILS_RES,
  DOWNLOAD_DETAILS_RES,
  GET_EXPORT_STATUS_RES,
  UPDATE_APMT_TYPE_RES,
  PROD_EOB_RES,
  PROD_CLAIM_DOWNLOAD_RES,
  PROD_GROUP_BY_PROVIDER_RES,
  PROD_GROUP_BY_SERVICE_RES,
  PROD_GROUP_BY_FACILITY_RES,
  PAYORS_LIST_RES,
  DENIAL_CATEGORY_RES,
  SEARCH_ICD_RES,
} from './Production.types'

/**
 * @fileOverview Manages the response of action
 * @author  Merina Joy <merina@tensaw.email>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  prodDetailed: {},
  claimLevel: {},
  prod_notes: {},
  exportResponse: {},
  downloadResponse: {},
  exportStatus: {},
  updateApmtResponse: {},
  prodClaimFile: null,
  prod_eob: {},
  ProdGroupByProvider: [],
  ProdGroupByService: [],
  ProdGroupByFacility: [],
  payorsList: [],
  denialCategory: {},
  searchIcd: {},
}

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const ProductionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCTION_DETAILED_RES: {
      return { ...state, ...{ prodDetailed: action.payload } }
    }
    case CLAIM_LEVEL_RES: {
      return { ...state, ...{ claimLevel: action.payload } }
    }
    case PROD_NOTES_RES: {
      return { ...state, ...{ prod_notes: action.payload } }
    }
    case EXPORT_DETAILS_RES: {
      return { ...state, ...{ exportResponse: action.payload } }
    }
    case DOWNLOAD_DETAILS_RES: {
      return { ...state, ...{ downloadResponse: action.payload } }
    }
    case GET_EXPORT_STATUS_RES: {
      return { ...state, ...{ exportStatus: action.payload } }
    }
    case UPDATE_APMT_TYPE_RES: {
      return { ...state, ...{ updateApmtResponse: action.payload } }
    }
    case PROD_EOB_RES: {
      return { ...state, ...{ prod_eob: action.payload } }
    }
    case PROD_CLAIM_DOWNLOAD_RES: {
      return {
        ...state,
        ...{ prodClaimFile: action.payload },
      }
    }
    case PROD_GROUP_BY_PROVIDER_RES: {
      return { ...state, ...{ ProdGroupByProvider: action.payload } }
    }
    case PROD_GROUP_BY_SERVICE_RES: {
      return { ...state, ...{ ProdGroupByService: action.payload } }
    }
    case PROD_GROUP_BY_FACILITY_RES: {
      return { ...state, ...{ ProdGroupByFacility: action.payload } }
    }
    case PAYORS_LIST_RES: {
      return { ...state, ...{ payorsList: action.payload } }
    }
    case DENIAL_CATEGORY_RES: {
      return { ...state, ...{ denialCategory: action.payload } }
    }
    case SEARCH_ICD_RES: {
      return { ...state, ...{ searchIcd: action.payload } }
    }
    default:
      return state
  }
}

export default ProductionReducer
