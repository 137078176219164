// reducers.js
import { CHAT_USER_LOGIN_SUCCESS, CHAT_USER_LOGIN_FAILURE } from './chat.types'

const initialState = {
  user: null,
  error: null,
}

const NewchatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
      }
    case CHAT_USER_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default NewchatReducer
