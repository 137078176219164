/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT' // Type for creating appointment
export const GET_CAL_LIST = 'GET_CAL_LIST' // loading appointments in full calender
export const GET_CAL_LIST_RESPONSE = 'GET_CAL_LIST_RESPONSE' // response of loading appointments in full calender
export const CREATE_APPOINTMENT_RESPONSE = 'CREATE_APPOINTMENT_RESPONSE' // response of create appointments in full calender
export const REQUEST_CANCEL = 'REQUEST_CANCEL' // cancel the API request
export const SEARCH_PATIENT = 'SEARCH_PATIENT' // to call search patient api in create appointment popup
export const SEARCH_PATIENT_RESPONSE = 'SEARCH_PATIENT_RESPONSE' // response of search patient api
export const API_STATUS = 'API_STATUS' // to call a boolean status to reload the get calendar list in full calendar
export const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID' // call api for getting search patient details when click the table row of search patient
export const GET_PATIENT_BY_ID_RESPONSE = 'GET_PATIENT_BY_ID_RESPONSE' // response of search patient details
export const GET_PATIENT_BY_APT_ID = 'GET_PATIENT_BY_APT_ID' //  to call API for getting appointment details of a patient
export const GET_PATIENT_BY_API_ID_RESPONSE = 'GET_PATIENT_BY_API_ID_RESPONSE' // response of appointment details of a patient
export const UPDATE_APT_DETAILS = 'UPDATE_APT_DETAILS' // to call API for update appointment in accordian
export const UPDATE_APT_DETAILS_RESPONSE = 'UPDATE_APT_DETAILS_RESPONSE' // response of  updated appointment in accordian
export const GET_SEARCH_PATIENT_BY_APT_ID = 'GET_SEARCHPATIENT_BY_APT_ID' // to call the API to get the patient details from appointment id
export const GET_SEARCH_PATIENT_BY_APT_ID_RESPONSE =
  'GET_SEARCHPATIENT_BY_APT_ID_RESPONSE' // response of the patient details from appointment id
export const IS_CHECKIN_STATUS = 'IS_CHECKIN_STATUS' // to call the boolean status from full calender event click to open checkin section in accordian when the date is today
export const SHOW_ACCORDION = 'SHOW_ACCORDION' // to show accordian when we click an event in full calendar
export const ZIP_SEARCH = 'ZIP_SEARCH' // to call api to list city state and country a/c zip code in create appointment popup
export const ZIP_SEARCH_RES = 'ZIP_SEARCH_RES' // response of zip code api to list city state and country a/c zip code in create appointment popup
export const ZIP_SEARCH_PAYER_ADDRESS = 'ZIP_SEARCH_PAYER_ADDRESS' // to call api to list city state and country a/c zip code in payer address popup
export const ZIP_SEARCH_PAYER_ADDRESS_RES = 'ZIP_SEARCH_PAYER_ADDRESS_RES' // response of zip code api to list city state and country a/c zip code in payer address popup
export const ZIP_SEARCH_ACCORDIAN_ADDRESS = 'ZIP_SEARCH_ACCORDIAN_ADDRESS' // to call api to list city state and country a/c zip code in accordian section
export const ZIP_SEARCH_ACCORDIAN_ADDRESS_RES =
  'ZIP_SEARCH_ACCORDIAN_ADDRESS_RES' // response of zip code api to list city state and country a/c zip code in accordian section
export const ZIP_SAVE_POLICY_HOLDER = 'ZIP_SAVE_POLICY_HOLDER' // to call api to list city state and country a/c zip code in policy holder
export const ZIP_SAVE_POLICY_HOLDER_RES = 'ZIP_SAVE_POLICY_HOLDER_RES' // response of zip code api to list city state and country a/c zip code in policy holder
export const DRAG_APPOINTMENT = 'DRAG_APPOINTMENT' // to call api to drag and drop the events in full calendar
export const DRAG_APPOINTMENT_RESPONSE = 'DRAG_APPOINTMENT_RESPONSE' // response of drag and drop the events in full calendar
export const APPOINTMENT_DATA = 'APPOINTMENT_DATA' // to call the appointment data while drag the event in full calendar
export const APPOINTMENT_DATA_RESPONSE = 'APPOINTMENT_DATA_RESPONSE' // response of api to get appointment data while drag the event in full calendar
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT' // to delete appointment inthe calender
export const DELETE_APPOINTMENT_RES = 'DELETE_APPOINTMENT_RES' // to delete appointment inthe calender
export const IS_ACCORDION_OPEN = 'IS_ACCORDION_OPEN' // to get a status from menu to render the calender
export const SCREEN_WIDTH = 'SCREEN_WIDTH' // to get SCREEN_WIDTH for calender
export const PUSH_POLICYHOLDER = 'PUSH_POLICYHOLDER'
export const EDIT_VIEW_STATUS = 'EDIT_VIEW_STATUS' // to manage edit icon in accordian section
export const GET_PATIENT_BY_ID_DIALOG = 'GET_PATIENT_BY_ID_DIALOG'
export const GET_PATIENT_BY_ID_DIALOG_RESPONSE =
  'GET_PATIENT_BY_ID_DIALOG_RESPONSE'
export const CLEAR_APPOINTMENT = 'CLEAR_APPOINTMENT'
export const SAVE_CURRENT_PATIENT_ID = 'SAVE_CURRENT_PATIENT_ID'
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT'
export const CANCEL_APPOINTMENT_RES = 'CANCEL_APPOINTMENT_RES'
export const CANCEL_POPOVER_STATUS = 'CANCEL_POPOVER_STATUS'
export const APOINTMENT_INFO_INSURANCE = 'APOINTMENT_INFO_INSURANCE'
export const APOINTMENT_INFO_INSURANCE_RES = 'APOINTMENT_INFO_INSURANCE_RES'
export const TRIGGER_APPT_REMINDER_REVIEW = 'TRIGGER_APPT_REMINDER_REVIEW'
export const TRIGGER_APPT_REMINDER_REVIEW_RES =
  'TRIGGER_APPT_REMINDER_REVIEW_RES'
