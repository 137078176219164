import {
  GET_ALL_APMTS_RES,
  GET_APMT_INS_RES,
  UPDATE_APMT_RES,
  UPLOAD_APMT_RES,
  GET_STATUS_RES,
  EXPORT_APMT_RES,
  BALANCE_DETAILS_RES,
} from './appointments.types'

const INITIAL_STATE = {
  appointments: {},
  appointmentIns: {},
  updateApmtResponse: {},
  uploadApmtResponse: {},
  statusResponse: {},
  exportApmtResponse: {},
  balanceDetailsResponse: {},
}

const appointmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_APMTS_RES: {
      return { ...state, ...{ appointments: action.payload } }
    }
    case GET_APMT_INS_RES: {
      return { ...state, ...{ appointmentIns: action.payload } }
    }
    case UPDATE_APMT_RES: {
      return { ...state, ...{ updateApmtResponse: action.payload } }
    }
    case UPLOAD_APMT_RES: {
      return { ...state, ...{ uploadApmtResponse: action.payload } }
    }
    case GET_STATUS_RES: {
      return { ...state, ...{ statusResponse: action.payload } }
    }
    case EXPORT_APMT_RES: {
      return { ...state, ...{ exportApmtResponse: action.payload } }
    }
    case BALANCE_DETAILS_RES: {
      return { ...state, ...{ balanceDetailsResponse: action.payload } }
    }
    default:
      return state
  }
}
export default appointmentsReducer
