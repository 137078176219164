// import { DENIAL_CATEGORY_RES } from "Redux/DenialCategory/Denial.types";
import {
  PRODUCTION_DETAILED,
  PRODUCTION_DETAILED_RES,
  CLAIM_LEVEL,
  CLAIM_LEVEL_RES,
  PROD_NOTES,
  PROD_NOTES_RES,
  EXPORT_DETAILS,
  EXPORT_DETAILS_RES,
  DOWNLOAD_DETAILS,
  DOWNLOAD_DETAILS_RES,
  GET_EXPORT_STATUS,
  GET_EXPORT_STATUS_RES,
  UPDATE_APMT_TYPE,
  UPDATE_APMT_TYPE_RES,
  PROD_EOB,
  PROD_EOB_RES,
  PROD_CLAIM_DOWNLOAD,
  PROD_CLAIM_DOWNLOAD_RES,
  PROD_GROUP_BY_PROVIDER,
  PROD_GROUP_BY_PROVIDER_RES,
  PROD_GROUP_BY_SERVICE,
  PROD_GROUP_BY_SERVICE_RES,
  PROD_GROUP_BY_FACILITY,
  PROD_GROUP_BY_FACILITY_RES,
  PAYORS_LIST,
  PAYORS_LIST_RES,
  DENIAL_CATEGORY,
  DENIAL_CATEGORY_RES,
  SEARCH_ICD,
  SEARCH_ICD_RES,
} from './Production.types'

export const getProductionDetailed = (data) => ({
  type: PRODUCTION_DETAILED,
  payload: data,
})

export const getProductionDetailedRes = (data) => ({
  type: PRODUCTION_DETAILED_RES,
  payload: data,
})

export const getClaimLevel = (data) => ({
  type: CLAIM_LEVEL,
  payload: data,
})

export const getClaimLevelRes = (data) => ({
  type: CLAIM_LEVEL_RES,
  payload: data,
})

export const getProdNotes = (data) => ({
  type: PROD_NOTES,
  payload: data,
})

export const getProdNotesRes = (data) => ({
  type: PROD_NOTES_RES,
  payload: data,
})

export const exportDetails = (data) => ({
  type: EXPORT_DETAILS,
  payload: data,
})

export const exportDetailsRes = (data) => ({
  type: EXPORT_DETAILS_RES,
  payload: data,
})

export const downloadDetails = (data) => ({
  type: DOWNLOAD_DETAILS,
  payload: data,
})

export const downloadDetailsRes = (data) => ({
  type: DOWNLOAD_DETAILS_RES,
  payload: data,
})

export const getExportStatus = (data) => ({
  type: GET_EXPORT_STATUS,
  payload: data,
})

export const getExportStatusRes = (data) => ({
  type: GET_EXPORT_STATUS_RES,
  payload: data,
})

export const updateApmtType = (data) => ({
  type: UPDATE_APMT_TYPE,
  payload: data,
})

export const updateApmtTypeRes = (data) => ({
  type: UPDATE_APMT_TYPE_RES,
  payload: data,
})
export const getProdEob = (data) => ({
  type: PROD_EOB,
  payload: data,
})

export const getProdEobRes = (data) => ({
  type: PROD_EOB_RES,
  payload: data,
})
export const prodClaimDownloadApi = (value) => ({
  type: PROD_CLAIM_DOWNLOAD,
  payload: value,
})
export const prodClaimDownloadRes = (value) => ({
  type: PROD_CLAIM_DOWNLOAD_RES,
  payload: value,
})

export const getProdGroupByProvider = (data) => ({
  type: PROD_GROUP_BY_PROVIDER,
  payload: data,
})

export const getProdGroupByProviderRes = (data) => ({
  type: PROD_GROUP_BY_PROVIDER_RES,
  payload: data,
})

export const getProdGroupByService = (data) => ({
  type: PROD_GROUP_BY_SERVICE,
  payload: data,
})

export const getProdGroupByServiceRes = (data) => ({
  type: PROD_GROUP_BY_SERVICE_RES,
  payload: data,
})

export const getProdGroupByFacility = (data) => ({
  type: PROD_GROUP_BY_FACILITY,
  payload: data,
})

export const getProdGroupByFacilityRes = (data) => ({
  type: PROD_GROUP_BY_FACILITY_RES,
  payload: data,
})

export const payorsList = (value) => ({
  type: PAYORS_LIST,
  payload: value,
})

export const payorsListRes = (value) => ({
  type: PAYORS_LIST_RES,
  payload: value,
})

export const getDenialDetailed = (data) => ({
  type: DENIAL_CATEGORY,
  payload: data,
})

export const getDenialDetailedRes = (data) => ({
  type: DENIAL_CATEGORY_RES,
  payload: data,
})

export const searchIcd = (data) => ({
  type: SEARCH_ICD,
  payload: data,
})

export const searchIcdResponse = (res) => ({
  type: SEARCH_ICD_RES,
  payload: res,
})
