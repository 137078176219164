export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const GET_CLINIC_DATA = 'GET_CLINIC_DATA'
export const GET_CLINIC_DATA_RES = 'GET_CLINIC_DATA_RES'
export const SAVE_CLINIC_DATA = 'SAVE_CLINIC_DATA'
export const SAVE_CLINIC_DATA_RES = 'SAVE_CLINIC_DATA_RES'
export const SAVE_ADMIN_DATA = 'SAVE_ADMIN_DATA'
export const SAVE_ADMIN_DATA_RES = 'SAVE_ADMIN_DATA_RES'
export const GET_ALL_USER = 'GET_ALL_USER'
export const GET_ALL_USER_RES = 'GET_ALL_USER_RES'
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS'
export const UPDATE_USER_STATUS_RES = 'UPDATE_USER_STATUS_RES'
export const CLINIC_ZIP_CALL = 'CLINIC_ZIP_CALL'
export const CLINIC_ZIP_CALL_RES = 'CLINIC_ZIP_CALL_RES'
export const SAVE_USER = 'SAVE_USER'
export const SAVE_USER_RES = 'SAVE_USER_RES'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_RES = 'UPDATE_USER_RES'
export const USERNAME_VALIDATION = ' USERNAME_VALIDATION'
export const USERNAME_VALIDATION_RES = ' USERNAME_VALIDATION_RES'
export const GET_ALL_PROVIDER = 'GET_ALL_PROVIDER'
export const GET_PROVIDER_BY_ID = 'GET_PROVIDER_BY_ID'
export const GET_PROVIDER_BY_ID_RES = 'GET_PROVIDER_BY_ID_RES'
export const GET_ALL_PROVIDER_RES = 'GET_ALL_PROVIDER_RES'
export const UPDATE_PROVIDER_STATUS = 'UPDATE_PROVIDER_STATUS'
export const UPDATE_PROVIDER_STATUS_RES = 'UPDATE_PROVIDER_STATUS_RES'
export const SAVE_PROVIDER = 'SAVE_PROVIDER'
export const SAVE_PROVIDER_RES = 'SAVE_PROVIDER_RES'
export const PROVIDER_USERNAME_VALIDATION = 'PROVIDER_USERNAME_VALIDATION'
export const PROVIDER_USERNAME_VALIDATION_RES =
  'PROVIDER_USERNAME_VALIDATION_RES'
export const CANCEL_STATUS_SAVE_NEW_PROVIDER = 'CANCEL_STATUS_SAVE_NEW_PROVIDER'
export const GET_ALL_LOCATION = 'GET_ALL_LOCATION'
export const GET_ALL_LOCATION_RES = 'GET_ALL_LOCATION_RES'
export const SAVE_LOCATION = 'SAVE_LOCATION'
export const SAVE_LOCATION_RES = 'SAVE_LOCATION_RES'
export const UPDATE_LOCATION_STATUS = 'UPDATE_LOCATION_STATUS'
export const UPDATE_LOCATION_STATUS_RES = 'UPDATE_LOCATION_STATUS_RES'
export const LOCATION_ZIP_CALL = 'LOCATION_ZIP_CALL'
export const LOCATION_ZIP_CALL_RES = 'LOCATION_ZIP_CALL_RES'
export const CANCEL_STATUS_SAVE_NEW_LOCATION = 'CANCEL_STATUS_SAVE_NEW_LOCATION'
export const SAVE_APT_TYPE = 'SAVE_APT_TYPE'
export const SAVE_APT_TYPE_RES = 'SAVE_APT_TYPE_RES'
export const GET_APT_TYPE = 'GET_APT_TYPE'
export const GET_APT_TYPE_RES = 'GET_APT_TYPE_RES'
export const UPDATE_APPOINTKMENT_STATUS = 'UPDATE_APPOINTKMENT_STATUS'
export const UPDATE_APPOINTKMENT_STATUS_RES = 'UPDATE_APPOINTKMENT_STATUS_RES'
export const CANCEL_STATUS_SAVE_NEW_APT_TYPE = 'CANCEL_STATUS_SAVE_NEW_APT_TYPE'
export const SPECIALITY = 'SPECIALITY'
export const SPECIALITY_RES = 'SPECIALITY_RES'

// Messaging

export const GET_MESSAGE_CONFIGS = 'GET_MESSAGE_CONFIGS'
export const GET_MESSAGE_CONFIGS_RESPONSE = 'GET_MESSAGE_CONFIGS_RESPONSE'
export const GET_MESSAGE_CONFIG_BY_ID = 'GET_MESSAGE_CONFIG_BY_ID'
export const GET_MESSAGE_CONFIG_BY_ID_RESPONSE =
  'GET_MESSAGE_CONFIG_BY_ID_RESPONSE'
export const GET_MESSAGE_CONFIG_DEFAULTS = 'GET_MESSAGE_CONFIG_DEFAULTS'
export const GET_MESSAGE_CONFIG_DEFAULTS_RESPONSE =
  'GET_MESSAGE_CONFIG_DEFAULTS_RESPONSE'
export const SAVE_MESSAGE_CONFIG = 'SAVE_MESSAGE_CONFIG'
export const SAVE_MESSAGE_CONFIG_RESPONSE = 'SAVE_MESSAGE_CONFIG_RESPONSE'
export const UPDATE_MESSAGE_CONFIG = 'UPDATE_MESSAGE_CONFIG'
export const UPDATE_MESSAGE_CONFIG_RESPONSE = 'UPDATE_MESSAGE_CONFIG_RESPONSE'
export const GET_MESSAGE_RESPONSE = 'GET_MESSAGE_RESPONSE'
export const GET_MESSAGE_RESP_RESPONSE = 'GET_MESSAGE_RESP_RESPONSE'
export const GET_MESSAGE_RESPONSE_BY_ID = 'GET_MESSAGE_RESPONSE_BY_ID'
export const GET_MESSAGE_RESP_BY_ID_RESPONSE = 'GET_MESSAGE_RESP_BY_ID_RESPONSE'
export const GET_MESSAGE_RESPONSE_DEFAULTS = 'GET_MESSAGE_RESPONSE_DEFAULTS'
export const GET_MESSAGE_RESPONSE_DEFAULTS_RESPONSE =
  'GET_MESSAGE_RESPONSE_DEFAULTS_RESPONSE'
export const SAVE_MESSAGE_RESPONSE = 'SAVE_MESSAGE_RESPONSE'
export const SAVE_MESSAGE_RESP_RESPONSE = 'SAVE_MESSAGE_RESP_RESPONSE'
export const UPDATE_MESSAGE_RESPONSE = 'UPDATE_MESSAGE_RESPONSE'
export const UPDATE_MESSAGE_RESPONSE_RESPONSE =
  'UPDATE_MESSAGE_RESPONSE_RESPONSE'
export const GET_MESSAGE_SCHEDULE = 'GET_MESSAGE_SCHEDULE'
export const GET_MESSAGE_SCHEDULE_RESPONSE = 'GET_MESSAGE_SCHEDULE_RESPONSE'
export const GET_MESSAGE_SCHEDULE_DEFAULTS = 'GET_MESSAGE_SCHEDULE_DEFAULTS'
export const GET_MESSAGE_SCHEDULE_DEFAULTS_RESPONSE =
  'GET_MESSAGE_SCHEDULE_DEFAULTS_RESPONSE'
export const SAVE_MESSAGE_SCHEDULE = 'SAVE_MESSAGE_SCHEDULE'
export const SAVE_MESSAGE_SCHEDULE_RESPONSE = 'SAVE_MESSAGE_SCHEDULE_RESPONSE'
export const UPDATE_MESSAGE_SCHEDULE = 'UPDATE_MESSAGE_SCHEDULE'
export const UPDATE_MESSAGE_SCHEDULE_RESPONSE =
  'UPDATE_MESSAGE_SCHEDULE_RESPONSE'
export const GET_MESSAGE_SCHEDULE_BY_CLINIC_ID =
  'GET_MESSAGE_SCHEDULE_BY_CLINIC_ID'
export const GET_MESSAGE_SCHEDULE__BY_CLINIC_ID_RESPONSE =
  'GET_MESSAGE_SCHEDULE__BY_CLINIC_ID_RESPONSE'
export const MESSAGE_CONFIG_STATUS = 'MESSAGE_CONFIG_STATUS'
export const MESSAGE_CONFIG_STATUS_RESPONSE = 'MESSAGE_CONFIG_STATUS_RESPONSE'
export const MESSAGE_CONFIG_CHANNEL_ENABLED = 'MESSAGE_CONFIG_CHANNEL_ENABLED'
export const MESSAGE_CONFIG_CHANNEL_ENABLED_RESPONSE =
  'MESSAGE_CONFIG_CHANNEL_ENABLED_RESPONSE'
export const GET_MESSAGE_TYPES = 'GET_MESSAGE_TYPES'
export const GET_MESSAGE_TYPES_RESPONSE = 'GET_MESSAGE_TYPES_RESPONSE'
export const GET_RESCHEDULE_SETTINGS = 'GET_RESCHEDULE_SETTINGS'
export const GET_RESCHEDULE_SETTINGS_RESPONSE =
  'GET_RESCHEDULE_SETTINGS_RESPONSE'
export const SAVE_RESCHEDULE_SETTINGS = 'SAVE_RESCHEDULE_SETTINGS'
export const SAVE_RESCHEDULE_SETTINGS_RESPONSE =
  'SAVE_RESCHEDULE_SETTINGS_RESPONSE'

// Provider Location

export const GET_PROVIDER_LOCATION_LIST = 'GET_PROVIDER_LOCATION_LIST'
export const GET_PROVIDER_LOCATION_LIST_RESPONSE =
  'GET_PROVIDER_LOCATION_LIST_RESPONSE'
export const SAVE_PROVIDER_LOCATION = 'SAVE_PROVIDER_LOCATION'
export const SAVE_PROVIDER_LOCATION_RESPONSE = 'SAVE_PROVIDER_LOCATION_RESPONSE'
export const GET_PROVIDER_LOCATION_BY_ID = 'GET_PROVIDER_LOCATION_BY_ID'
export const GET_PROVIDER_LOCATION_BY_ID_RESPONSE =
  'GET_PROVIDER_LOCATION_BY_ID_RESPONSE'

// Billing

export const GET_PLANS_DETAILS = 'GET_PLANS_DETAILS'
export const GET_PLANS_DETAILS_RESPONSE = 'GET_PLANS_DETAILS_RESPONSE'
export const GET_BILLING_DETAILS = 'GET_BILLING_DETAILS'
export const GET_BILLING_DETAILS_RESPONSE = 'GET_BILLING_DETAILS_RESPONSE'
export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const CREATE_PAYMENT_RESPONSE = 'CREATE_PAYMENT_RESPONSE'
export const UPDATE_CARD = 'UPDATE_CARD'
export const UPDATE_CARD_RESPONSE = 'UPDATE_CARD_RESPONSE'

// Widgets
export const GET_PLACE_ID = 'GET_PLACE_ID'
export const GET_PLACE_ID_RESPONSE = 'GET_PLACE_ID_RESPONSE'
export const SAVE_PLACE_ID = 'SAVE_PLACE_ID'
export const SAVE_PLACE_ID_RESPONSE = 'SAVE_PLACE_ID_RESPONSE'

//Settings
export const GET_CHECKIN_CONFIG = 'GET_CHECKIN_CONFIG'
export const GET_CHECKIN_CONFIG_RESPONSE = 'GET_CHECKIN_CONFIG_RESPONSE'
export const SAVE_CHECKIN_CONFIG = 'SAVE_CHECKIN_CONFIG'
export const SAVE_CHECKIN_CONFIG_RESPONSE = 'SAVE_CHECKIN_CONFIG_RESPONSE'
export const GET_ELIGIBILITY_CONFIG = 'GET_ELIGIBILITY_CONFIG'
export const GET_ELIGIBILITY_CONFIG_RESPONSE = 'GET_ELIGIBILITY_CONFIG_RESPONSE'
export const SAVE_ELIGILIBITY_CONFIG = 'SAVE_ELIGILIBITY_CONFIG'
export const SAVE_ELIGILIBITY_CONFIG_RESPONSE = 'SAVE_ELIGILIBITY_CONFIG_RESPONSE'

//Payors list
export const GET_PAYORS_LIST = "GET_PAYORS_LIST"
export const GET_PAYORS_LIST_RES = "GET_PAYORS_LIST_RES"
export const EFT_UPDATE_STATUS = "EFT_UPDATE_STATUS";
export const EFT_UPDATE_STATUS_RES = "EFT_UPDATE_STATUS_RES";