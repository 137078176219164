export const REQUEST_CANCEL = 'REQUEST_CANCEL' // cancelling the api request
export const SAVE_PAYMENT = 'SAVE_PAYMENT' // save the payment
export const SAVE_PAYMENT_RES = 'SAVE_PAYMENT_RES' // save the payment RES
export const EDIT_PAYMENT = 'EDIT_PAYMENT' // edit the payment
export const EDIT_PAYMENT_RES = 'EDIT_PAYMENT_RES' // edit the payment RES
export const GET_PAYMENT_APPTID = 'GET_PAYMENT_APPTID' // get the payment by apptid
export const GET_PAYMENT_APPTID_RES = 'GET_PAYMENT_APPTID _RES' // get the payment RES by apptid
export const GET_PAYMENT_ID = 'GET_PAYMENT_ID' // get the payment by id
export const GET_PAYMENT_ID_RES = 'GET_PAYMENT_ID_RES' // get the payment by id RES
export const DELETE_PAYMENT = 'DELETE_PAYMENT' // Delete the payment by id
export const DELETE_PAYMENT_RES = 'DELETE_PAYMENT_RES' // delete the payment by id RES
export const PAYMENT_EDIT_ID = 'PAYMENT_EDIT_ID' // delete the payment by id RES
