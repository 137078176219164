import { GET_SETTINGS_DATA_RES, SAVE_SETTINGS_DATA_RES } from './settings.types'
const INITIAL_STATE = {
  getSettingsDetails: {},
  saveSettingsDetails: {},
}

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS_DATA_RES: {
      return { ...state, ...{ getSettingsDetails: action.payload } }
    }
    case SAVE_SETTINGS_DATA_RES: {
      return { ...state, ...{ saveSettingsDetails: action.payload } }
    }

    default:
      return state
  }
}
export default settingsReducer
