import {
  ADD_ICD_RESPONSE,
  SEARCH_CPT_CODE_RESPONSE,
  SAVE_CPT_CODE_RESPONSE,
  ICD_SEARCH_BY_CODE_RESPONSE,
  CHECKOUT_API_RESPONSE,
  GET_CPT_DETAILS_RESPONSE,
  ICD_SEARCH_BY_KEYWORD_RESPONSE,
  ICD_SEARCH_DATA,
  ICD_SEARCH_CLEAR,
  GET_CHECKOUT_RES,
} from './checkout.types'

/**
 * @fileOverview Manages the response of action
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  icdDetails: null,
  searchIcdByCodeData: null,
  cptDetails: {},
  cptSearchResponse: null,
  SavedCptDetails: {},
  checkoutData: null,
  getCptDetails: {},
  icdSearchByKeywordData: null,
  icdSearchData: [],
  icdSearchClear: false,
  currentCptDetails: {},
  getCheckoutResponse: null,
}

/**
 *
 * @param state global state management
 * @param action - contains type and payload
 * @returns
 */
const checkoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ICD_RESPONSE: {
      return {
        ...state,
        ...{ icdDetails: action.payload },
      }
    }
    case ICD_SEARCH_BY_CODE_RESPONSE: {
      return {
        ...state,
        ...{ searchIcdByCodeData: action.payload },
      }
    }
    case SEARCH_CPT_CODE_RESPONSE: {
      return {
        ...state,
        ...{ cptSearchResponse: action.payload },
      }
    }
    case SAVE_CPT_CODE_RESPONSE: {
      return {
        ...state,
        ...{ SavedCptResponse: action.payload },
      }
    }
    case CHECKOUT_API_RESPONSE: {
      return {
        ...state,
        ...{ checkoutData: action.payload },
      }
    }
    case GET_CPT_DETAILS_RESPONSE: {
      return {
        ...state,
        ...{ getCptDetails: action.payload },
      }
    }
    case ICD_SEARCH_BY_KEYWORD_RESPONSE: {
      return {
        ...state,
        ...{ icdSearchByKeywordData: action.payload },
      }
    }
    case ICD_SEARCH_DATA: {
      return {
        ...state,
        ...{ icdSearchData: action.payload },
      }
    }
    case ICD_SEARCH_CLEAR: {
      return {
        ...state,
        ...{ icdSearchClear: action.payload },
      }
    }
    case GET_CHECKOUT_RES: {
      return {
        ...state,
        ...{ getCheckoutResponse: action.payload },
      }
    }

    default:
      return state
  }
}
export default checkoutReducer
