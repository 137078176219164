import {
  ALL_USERS_RES,
  ENDOFDAY_REPORT_RES,
  FILTER_APPOINTMENTLIST_REPORT_RES,
  FILTER_COLLECTION_REPORT_RES,
  PRIOR_REPORT_RES
} from './reports.types'
const INITIAL_STATE = {
  endOfDayReport: null,
  appointmentListReport: null,
  collectionReport: null,
  allUsersData: null,
  priorReport: null,
}

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ENDOFDAY_REPORT_RES: {
      return { ...state, ...{ endOfDayReport: action.payload } }
    }
    case FILTER_APPOINTMENTLIST_REPORT_RES: {
      return { ...state, ...{ appointmentListReport: action.payload } }
    }
    case FILTER_COLLECTION_REPORT_RES: {
      return { ...state, ...{ collectionReport: action.payload } }
    }
    case ALL_USERS_RES: {
      return { ...state, ...{ allUsersData: action.payload } }
    }
    case PRIOR_REPORT_RES: {
      return { ...state, ...{ priorReport: action.payload } }
    }
    default:
      return state
  }
}
export default reportReducer
