import {
  FILTER_ENDOFDAY_REPORT,
  ENDOFDAY_REPORT_RES,
  FILTER_APPOINTMENTLIST_REPORT,
  FILTER_APPOINTMENTLIST_REPORT_RES,
  FILTER_COLLECTION_REPORT,
  FILTER_COLLECTION_REPORT_RES,
  ALL_USERS,
  ALL_USERS_RES,
  PRIOR_REPORT,
  PRIOR_REPORT_RES
} from './reports.types'

export const filteRendOfDayReport = (params) => ({
  type: FILTER_ENDOFDAY_REPORT,
  payload: params,
})

export const endOfDayReportResponse = (response) => ({
  type: ENDOFDAY_REPORT_RES,
  payload: response,
})

export const filterAppointmentList = (params) => ({
  type: FILTER_APPOINTMENTLIST_REPORT,
  payload: params,
})

export const filterAppointmentListRes = (response) => ({
  type: FILTER_APPOINTMENTLIST_REPORT_RES,
  payload: response,
})
export const filterCollection = (params) => ({
  type: FILTER_COLLECTION_REPORT,
  payload: params,
})

export const filterCollectionRes = (response) => ({
  type: FILTER_COLLECTION_REPORT_RES,
  payload: response,
})

export const allUsers = (params) => ({
  type: ALL_USERS,
  payload: params,
})

export const allUsersRes = (response) => ({
  type: ALL_USERS_RES,
  payload: response,
})

export const priorReport = (response) => ({
  type: PRIOR_REPORT,
  payload: response
})

export const priorReportRes = (response) => ({
  type: PRIOR_REPORT_RES,
  payload: response
})
