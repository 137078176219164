import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import { objectToUrl } from "Utils/ajax-utils";
import environment from "../../../environments/environment";
import {
  BULK_UPDATE,
  LOG_INFO_SAVE,
  CPT_SAVE,
  CPT_SEARCH,
  DOWNLOAD,
  FILE_UPLOAD,
  GET_PRIORAUTH,
  ICD_SAVE,
  ICD_SEARCH,
  LOGIN_INFO,
  LOGLIST,
  PRIORAUTH_LIST,
  PRIORAUTH_SAVE,
  REQUEST_CANCEL,
  ROW_UPDATE,
  ICD_KEYWORD_SEARCH,
  CPT_DELETE,
  ICD_CHILD,
  PA_HISTORY,
  SEARCH_PAYOR,
  PATIENT_SAVE,
  POLICYHOLDER_SAVE,
  POLICY_SAVE,
  MAPPING_LIST,
  SAVE_MAPPING,
  GET_PATIENT,
  FILE_LIST,
  GET_NOTES,
  PROVIDER_INFO,
  GET_COMMON_LIST,
} from "./prior.types";
import {
  bulkUpdateResponse,
  logInfoSaveResponse,
  cptSaveResponse,
  cptSearchResponse,
  fileDownloadResponse,
  fileUploadResponse,
  getPriorAuthResponse,
  icdSaveResponse,
  icdSearchResponse,
  loginInfoResponse,
  loglistResponse,
  priorAuthListResponse,
  priorAuthSaveRes,
  icdkeywordResponse,
  cptDeleteResponse,
  icdChildResponse,
  paHistoryRes,
  searchPayoRes,
  patientSaveRes,
  policyHolderSaveRes,
  policySaveRes,
  mappingListRes,
  saveMappingRes,
  getPatientResponse,
  fileListResponse,
  getPriorAuthNotesResponse,
  providerInfoResponse,
  getCommonListResponse,
} from "./prior.actions";
import { Apis } from 'Redux/APIs'
const { baseUrl } = environment;
// const { baseUrl2 } = environment;
const PriorAuthEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */

PriorAuthEpic.priorAuthListApi = (action$) =>
  action$.pipe(
    ofType(PRIORAUTH_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Priorauth_priorAuthListApi}`,
          action.payload
        ),
        method: "GET",
      }).pipe(
        map(
          (response) => priorAuthListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.getPriorAuthApi = (action$) =>
  action$.pipe(
    ofType(GET_PRIORAUTH),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_getPriorAuthApi}/${action.payload.apptId}?priorAuthId=${action.payload.priorAuthId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getPriorAuthResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.icdSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.priorauth_icdSearchApi}/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: "GET",
      }).pipe(
        map(
          (response) => icdSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.icdSaveApi = (action$) =>
  action$.pipe(
    ofType(ICD_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_icdSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => icdSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.cptSearchApi = (action$) =>
  action$.pipe(
    ofType(CPT_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_cptSearchApi}/${action.payload.cptCode}?clinicId=${action.payload.clinicId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => cptSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.logInfoSaveApi = (action$) =>
  action$.pipe(
    ofType(LOG_INFO_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_logInfoSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => logInfoSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.cptSaveApi = (action$) =>
  action$.pipe(
    ofType(CPT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_cptSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.priorAuthSaveApi = (action$) =>
  action$.pipe(
    ofType(PRIORAUTH_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_priorAuthSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => priorAuthSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.loginInfoApi = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_loginInfoApi}`,
        method: "GET",
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.fileUploadApi = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: { // Corrected key to "headers"
          "application-id": 4,
          enctype: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        url: `${baseUrl}/${Apis.Priorauth_fileUploadApi}/`,
        method: "POST",
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.fileUploadApi = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 4,
          enctype: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        url: `${baseUrl}/${Apis.Priorauth_fileUploadApi}/`,
        method: "POST",
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.downloadFileApi = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        url: `${baseUrl}/${Apis.Priorauth_downloadFileApi}?fileId=${action.payload.id}&fileName=${action.payload.name}`,
        method: "GET",
        responseType: "blob",
      }).pipe(
        map(
          (response) => fileDownloadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.logListApi = (action$) =>
  action$.pipe(
    ofType(LOGLIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_logListApi}/${action.payload.appointmentId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => loglistResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.bulkUpdateApi = (action$) =>
  action$.pipe(
    ofType(BULK_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_bulkUpdateApi}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.rowUpdateApi = (action$) =>
  action$.pipe(
    ofType(ROW_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_bulkUpdateApi}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.icdKeywordSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_KEYWORD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_icdKeywordSearchApi}?searchField=%20&searchParam=${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => icdkeywordResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.cptDeleteApi = (action$) =>
  action$.pipe(
    ofType(CPT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_cptDeleteApi}/${action.payload}`,
        method: "DELETE",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.icdChildApi = (action$) =>
  action$.pipe(
    ofType(ICD_CHILD),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_icdChildApi}?parentId=${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => icdChildResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.paHistoryApi = (action$) =>
  action$.pipe(
    ofType(PA_HISTORY),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_paHistoryApi}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => paHistoryRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.searchPayorApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYOR),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_searchPayorApi}?name=${action.payload.str}&clinicId=${action.payload.id}`,
        method: "GET",
      }).pipe(
        map(
          (response) => searchPayoRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.patientSaveApi = (action$) =>
  action$.pipe(
    ofType(PATIENT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_patientSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => patientSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.policyHolderSaveApi = (action$) =>
  action$.pipe(
    ofType(POLICYHOLDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_policyHolderSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policyHolderSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.policySaveApi = (action$) =>
  action$.pipe(
    ofType(POLICY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_policySaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policySaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.mappingListApi = (action$) =>
  action$.pipe(
    ofType(MAPPING_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_mappingListApi}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => mappingListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.saveMappingApi = (action$) =>
  action$.pipe(
    ofType(SAVE_MAPPING),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_saveMappingApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMappingRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.getPatientApi = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 2,
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/trillium-internal-service/v1/common/patient/info/${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

PriorAuthEpic.FilesListApi = (action$) =>
  action$.pipe(
    ofType(FILE_LIST),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 4,
          'Content-Type': 'application/json',
        },
        url: objectToUrl(
          `${baseUrl}/trillium-internal-service/v1/common/file/list`,
          action.payload.data
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => fileListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.getNotesListApi = (action$) =>
  action$.pipe(
    ofType(GET_NOTES),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 2,
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/trillium-internal-service/v1/common/appointment/note/list/${action.payload.appointmentId}?patientId=${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPriorAuthNotesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

PriorAuthEpic.providerInfoApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_INFO),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_ProviderInfo}/${action.payload.providerId}?facilityId=${action.payload.facilityId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => providerInfoResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

PriorAuthEpic.getCommonListApi = (action$) =>
  action$.pipe(
    ofType(GET_COMMON_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Priorauth_getCommonListApi}/${action.payload.clinicId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getCommonListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
export default PriorAuthEpic;
