import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import {
  REQUEST_CANCEL,
  FILTER_ENDOFDAY_REPORT,
  FILTER_APPOINTMENTLIST_REPORT,
  FILTER_COLLECTION_REPORT,
  ALL_USERS,
  PRIOR_REPORT,
} from './reports.types'
import {
  allUsersRes,
  endOfDayReportResponse,
  filterAppointmentListRes,
  filterCollectionRes,
  priorReportRes
} from './reports.actions'
import { objectToUrl } from "Utils/ajax-utils";
const { baseUrl } = environment
const ReportsEpic = {}

ReportsEpic.getEndOfDayReport = (action$) =>
  action$.pipe(
    ofType(FILTER_ENDOFDAY_REPORT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Reports_getEndOfDayReport}?fromDate=${action.payload.fromDate}&toDate=${action.payload.toDate}&locationIds=${action.payload.locationIds}&paymentTypes=${action.payload.paymentTypes}&providerIds=${action.payload.providerIds}&sortBy=${action.payload.sortBy}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => endOfDayReportResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
ReportsEpic.getAppointmentListReport = (action$) =>
  action$.pipe(
    ofType(FILTER_APPOINTMENTLIST_REPORT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Reports_getAppointmentListReport}?fromDate=${action.payload.fromDate}&toDate=${action.payload.toDate}&locationIds=${action.payload.locationIds}&providerIds=${action.payload.providerIds}&sortBy=${action.payload.sortBy}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => filterAppointmentListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
ReportsEpic.getCollectionReport = (action$) =>
  action$.pipe(
    ofType(FILTER_COLLECTION_REPORT),
    switchMap((action) =>
      ajax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Reports_getCollectionReport}/${action.payload.page}`,
          action.payload.params
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => filterCollectionRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
ReportsEpic.getAllUsers = (action$) =>
  action$.pipe(
    ofType(ALL_USERS),
    switchMap(() =>
      ajax({
        url: `${baseUrl}/${Apis.Reports_getAllUsers}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => allUsersRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
ReportsEpic.priorReport = (action$) =>
  action$.pipe(
    ofType(PRIOR_REPORT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Reports_priorReport}?providerId=${action.payload.provider}&facilityId=${action.payload.facility}`,
        method: "GET"
      }).pipe(
        map(
          (response) => priorReportRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      ))
  )

export default ReportsEpic
