import {
  SAVE_PAYMENT,
  SAVE_PAYMENT_RES,
  EDIT_PAYMENT,
  EDIT_PAYMENT_RES,
  GET_PAYMENT_APPTID,
  GET_PAYMENT_APPTID_RES,
  GET_PAYMENT_ID,
  GET_PAYMENT_ID_RES,
  DELETE_PAYMENT,
  DELETE_PAYMENT_RES,
  PAYMENT_EDIT_ID,
} from './payment.types'
/**
   * @fileOverview Manages the action w.r.t types in the redux
   * @author Cinu James <cinu@trillium-technologies.com>
   * return(
   type and payload
   * )
   * @example export const actionName = (params) => ({
    type: Type of the action from payment.type.js,
    payload: object - contains params,
  })
   */

/**
 * @description Action for save payment
 */
export const savePayment = (value) => ({
  type: SAVE_PAYMENT,
  payload: value,
})
export const savePaymentRes = (value) => ({
  type: SAVE_PAYMENT_RES,
  payload: value,
})
/**
 * @description Action for edit payment
 */
export const editPayment = (value) => ({
  type: EDIT_PAYMENT,
  payload: value,
})
export const editPaymentRes = (value) => ({
  type: EDIT_PAYMENT_RES,
  payload: value,
})
/**
 * @description Action for get payment by apptid
 */
export const getPaymentBYApptId = (value) => ({
  type: GET_PAYMENT_APPTID,
  payload: value,
})
export const getPaymentBYApptIdRes = (value) => ({
  type: GET_PAYMENT_APPTID_RES,
  payload: value,
})
/**
 * @description Action for get payment by id
 */
export const getPaymentBYId = (value) => ({
  type: GET_PAYMENT_ID,
  payload: value,
})
export const getPaymentBYIdRes = (value) => ({
  type: GET_PAYMENT_ID_RES,
  payload: value,
})
/**
 * @description Action for delete payment
 */
export const deletePayment = (value) => ({
  type: DELETE_PAYMENT,
  payload: value,
})
export const deletePaymentRes = (value) => ({
  type: DELETE_PAYMENT_RES,
  payload: value,
})
export const PaymentIdRes = (value) => ({
  type: PAYMENT_EDIT_ID,
  payload: value,
})
