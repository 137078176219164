export const FETCH_INBOX_HISTORY = 'FETCH_INBOX_HISTORY'
export const FETCH_INBOX_HISTORY_RES = 'FETCH_INBOX_HISTORY_RES'
export const FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES'
export const FETCH_CHAT_MESSAGES_RES = 'FETCH_CHAT_MESSAGES_RES'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const MESSAGE_COUNT = 'MESSAGE_COUNT'
export const SEND_FILE = 'SEND_FILE'
export const SEND_FILE_RESPONSE = 'SEND_FILE_RESPONSE'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const DOWNLOAD_FILE_RES = 'DOWNLOAD_FILE_RES'
