import {
  GET_ALL_APMTS,
  GET_ALL_APMTS_RES,
  GET_APMT_INS,
  GET_APMT_INS_RES,
  UPDATE_APMT,
  UPDATE_APMT_RES,
  UPLOAD_APMT,
  UPLOAD_APMT_RES,
  GET_STATUS,
  GET_STATUS_RES,
  EXPORT_APMT,
  EXPORT_APMT_RES,
  BALANCE_DETAILS,
  BALANCE_DETAILS_RES,
} from './appointments.types'

export const getAppointments = (params) => ({
  type: GET_ALL_APMTS,
  payload: params,
})

export const getAppointmentsResponse = (response) => ({
  type: GET_ALL_APMTS_RES,
  payload: response,
})

export const getAppointmentIns = (params) => ({
  type: GET_APMT_INS,
  payload: params,
})

export const getAppointmentInsResponse = (response) => ({
  type: GET_APMT_INS_RES,
  payload: response,
})

export const updateAppointment = (params) => ({
  type: UPDATE_APMT,
  payload: params,
})

export const updateAppointmentResponse = (response) => ({
  type: UPDATE_APMT_RES,
  payload: response,
})

export const uploadAppointment = (params) => ({
  type: UPLOAD_APMT,
  payload: params,
})

export const uploadAppointmentResponse = (response) => ({
  type: UPLOAD_APMT_RES,
  payload: response,
})

export const getUploadStatus = (params) => ({
  type: GET_STATUS,
  payload: params,
})

export const getUploadStatusResponse = (response) => ({
  type: GET_STATUS_RES,
  payload: response,
})

export const exportAppointment = (params) => ({
  type: EXPORT_APMT,
  payload: params,
})

export const exportAppointmentResponse = (response) => ({
  type: EXPORT_APMT_RES,
  payload: response,
})

//appoinment actions
export const getBalanceDetails = (response) => ({
  type: BALANCE_DETAILS,
  payload: response,
})

export const getBalanceDetailsResponse = (response) => ({
  type: BALANCE_DETAILS_RES, // Or your success action type
  payload: response,
})
