import {
  GET_PATIENT_LIST_RES,
  VIDEO_TOKEN_RES,
  DELETE_QUEUE_RES,
  VIDEO_MAIL_RES,
  PATIENT_TILE_RES,
} from './video.types'

const INITIAL_STATE = {
  patientList: null,
  clinicVideoToken: null,
  deletePatientque: null,
  mailresponse: null,
  patientTile: null,
}

const videoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PATIENT_LIST_RES: {
      return { ...state, ...{ patientList: action.payload } }
    }
    case VIDEO_TOKEN_RES: {
      return { ...state, ...{ clinicVideoToken: action.payload } }
    }
    case DELETE_QUEUE_RES: {
      return { ...state, ...{ deletePatientque: action.payload } }
    }
    case VIDEO_MAIL_RES: {
      return { ...state, ...{ mailresponse: action.payload } }
    }
    case PATIENT_TILE_RES: {
      return { ...state, ...{ patientTile: action.payload } }
    }
    default:
      return state
  }
}
export default videoReducer
