import {
  GET_SETTINGS_DATA,
  GET_SETTINGS_DATA_RES,
  SAVE_SETTINGS_DATA,
  SAVE_SETTINGS_DATA_RES,
} from './settings.types'

export const getSettingsData = (params) => ({
  type: GET_SETTINGS_DATA,
  payload: params,
})

export const getSettingsDataResponse = (response) => ({
  type: GET_SETTINGS_DATA_RES,
  payload: response,
})
export const saveSettingsData = (params) => ({
  type: SAVE_SETTINGS_DATA,
  payload: params,
})

export const saveSettingsDataResponse = (response) => ({
  type: SAVE_SETTINGS_DATA_RES,
  payload: response,
})
