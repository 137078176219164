import { ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import { ajax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import { GET_SETTINGS_DATA, SAVE_SETTINGS_DATA } from './settings.types'
import {
  getSettingsDataResponse,
  saveSettingsDataResponse,
} from './settings.actions'
const { baseUrl } = environment
const SettingsEpic = {}
SettingsEpic.getSettingsApi = (action$) =>
  action$.pipe(
    ofType(GET_SETTINGS_DATA),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Settings_getSettingsApi}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getSettingsDataResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
SettingsEpic.saveSettingsApi = (action$) =>
  action$.pipe(
    ofType(SAVE_SETTINGS_DATA),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Settings_saveSettingsApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveSettingsDataResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default SettingsEpic
